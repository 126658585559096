import {Link, useLocation, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import * as React from "react";
import {useEffect, useState} from "react";
import {Icons} from "../icons";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import jwtDecode from "jwt-decode";
import {UserProfileApi} from "../../APIConfig/CtfConfig";
import {useJwt} from "react-jwt";
import {useDispatch, useSelector} from "react-redux";
import {toggleValue} from "../../RTK/features/CtfPopUp";
import {toggleValue1} from "../../RTK/features/ScenarioPopup";
import {toggleValue2} from "../../RTK/features/Left";

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState('')
  const [users, setUser] = useState('')
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [anchorElUser, setAnchorElUser] = useState(null);
  const token = localStorage.getItem("access_token");
  const {isExpired} = useJwt(token);
  const value = useSelector((state) => state.ctfBoolean.value);
  const value1 = useSelector((state) => state.scenarioBoolean.value);
  const value2 = useSelector((state) => state.LeftBoolean.value)
  const [role, setRole] = useState(true)
  const [dashboard, setDashboard] = useState('nav')
  const dispatch = useDispatch();
  let user = null;
  useEffect(() => {
    apiFunction()
  }, [])

  const handleToggle = () => {
    dispatch(toggleValue());
  };

  const handleToggle1 = () => {
    dispatch(toggleValue1());
  };
  const handleToggle2 = () => {
    dispatch(toggleValue2())
  }
  const location = useLocation();
  const currentPath = location?.pathname.split("/", 3).join("/");
  const highlighter = (path) => (currentPath === path ? "active" : 'inactive');


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const apiFunction = async () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      user = jwtDecode(token)
      const userDetails = await UserProfileApi(user?.user_id)
      setUser(userDetails?.data)
      setAdmin(user?.is_admin)
    }

  }

  const navBarLink = [

    {
      name: "Categories",
      linkName: "categories",
      to: "/",
      classHighLighter: "/categories",
      startIcon: <Icons.categories style={{fontSize: '24px'}}/>,
      status: "categories",
      options: [
        {
          name: "Solo Categories",
          linkName: "/admin/ctf-categories",
          to: "/admin/ctf-categories",
          classHighLighter: "/admin/ctf-categories",
          startIcon: <SportsEsportsOutlinedIcon/>,
        },
        {
          name: "Squad Categories",
          linkName: "/admin/scenario-categories",
          to: "/admin/scenario-categories",
          classHighLighter: "/admin/scenario-categories",
          startIcon: <SportsEsportsOutlinedIcon/>,
        },

      ],
    },

    {
      name: "Challenges",
      linkName: '/admin/challenges',
      to: '/admin/challenges',
      classHighLighter: '/admin/challenges',
      startIcon: <Icons.BiHive style={{fontSize: '24px'}}/>,
      status: 'normal'
    },

    {
      name: "Approval",
      linkName: "Approval",
      to: "/",
      classHighLighter: "/Approval",
      startIcon: <Icons.mapping style={{fontSize: '24px'}}/>,
      status: "Approval",
      options: [
        {
          name: "Solo Mapping",
          linkName: "/admin/ctf-requests",
          to: "/admin/ctf-requests",
          classHighLighter: "/admin/ctf-requests",
          startIcon: <SportsEsportsOutlinedIcon/>,
        },
        {
          name: "Squad Approved",
          linkName: "/admin/scenario-requests",
          to: "/admin/scenario-requests",
          classHighLighter: "/admin/scenario-requests",
          startIcon: <SportsEsportsOutlinedIcon/>,
        },
        {
          name: "Corporate Approved",
          linkName: "/admin/corporate-requests",
          to: "/admin/corporate-requests",
          classHighLighter: "/admin/corporate-requests",
          startIcon: <SportsEsportsOutlinedIcon/>,
        },
      ],
    },
    {
      name: "Update",
      linkName: "Upadate",
      to: "/",
      classHighLighter: "/Update",
      startIcon: <Icons.update style={{fontSize: '24px'}}/>,
      status: "Update",
      options: [

        {
          name: 'Update Squad',
          linkName: '/admin/scenario_category_update',
          to: '/admin/scenario_category_update',
          classHighLighter: '/admin/scenario_category_update',
          startIcon: <SportsEsportsOutlinedIcon/>,
        },
        {
          name: 'Update Solo',
          linkName: '/admin/ctf_category_edit',
          to: '/admin/ctf_category_edit',
          classHighLighter: '/admin/ctf_category_edit',
          startIcon: <SportsEsportsOutlinedIcon/>,
        },


      ],
    },

    {
      name: "Users",
      linkName: '/admin/users',
      to: '/admin/users',
      classHighLighter: '/admin/users',
      startIcon: <Icons.users style={{fontSize: '24px'}}/>,
      status: 'normal'
    },
  ];
  return (
    navBarLink?.map((data, index) => {
      if (data.status === "normal") {
        return (
          <Link
            key={index}
            to={data.to}
            name={data.linkName}
            className={highlighter(data.classHighLighter)}
          >
            <Box sx={{display: "flex", direction: "row", gap: 1}}>
              {data.startIcon}
              {data.name}
            </Box>
          </Link>
        );
      }

      // navigator for the multiple scenario cases
      if (data.status === "Approval") {
        return (
          <Stack key={index}>
            <Stack
              className="inactive"
              style={{cursor: "pointer", justifyContent: 'space-between'}}
              onClick={handleToggle1}
              direction="row"
            >
              <Box sx={{display: "flex", direction: "row", gap: 1}}>
                {data.startIcon}
                {data.name}
              </Box>
              {value1 ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
            </Stack>
            <Stack style={{display: value1 ? "flex" : "none", gap: 4}}>
              {data.options.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.to}
                    name={item.linkName}
                    className={highlighter(item.classHighLighter)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        direction: "row",
                        justifyContent: "space-between",
                        gap: 1,
                        padding: '0 16px',
                      }}
                    >
                      {item.name}
                      <KeyboardArrowRightIcon sx={{fontSize: '24px'}}/>
                    </Box>
                  </Link>
                );
              })}
            </Stack>
          </Stack>
        );
      }
      // multiple uses for the ctf
      if (data.status === "categories") {
        return (
          <Stack key={index}>
            <Stack
              className="inactive"
              style={{cursor: "pointer", justifyContent: 'space-between'}}
              onClick={handleToggle}
              direction="row"
            >
              <Box sx={{display: "flex", direction: "row", gap: 1}}>
                {data.startIcon}
                {data.name}
              </Box>
              {value ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
            </Stack>
            <Stack style={{display: value ? "flex" : "none", gap: 4}}>
              {data.options.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.to}
                    name={item.linkName}
                    className={highlighter(item.classHighLighter)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        direction: "row",
                        justifyContent: "space-between",
                        gap: 1,
                        padding: '0 16px',
                      }}
                    >
                      {item.name}
                      <KeyboardArrowRightIcon sx={{fontSize: '24px'}}/>
                    </Box>
                  </Link>
                );
              })}
            </Stack>
          </Stack>
        );
      }
      // navigator for the admin multiple task
      if (data.status === "Update") {
        return (
          <Stack key={index}>
            <Stack
              className="inactive"
              style={{cursor: "pointer", justifyContent: 'space-between'}}
              onClick={() => setShow3(!show3)}
              direction="row"
            >
              <Box sx={{display: "flex", direction: "row", gap: 1}}>
                {data.startIcon}
                {data.name}
              </Box>
              {show ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
            </Stack>
            <Stack style={{display: show3 ? "flex" : "none", gap: 4}}>
              {data.options.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.to}
                    name={item.linkName}
                    className={highlighter(item.classHighLighter)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        direction: "row",
                        justifyContent: "space-between",
                        gap: 1,
                        padding: '0 16px',
                      }}
                    >
                      {item.name}
                      <KeyboardArrowRightIcon sx={{fontSize: '24px'}}/>
                    </Box>
                  </Link>
                );
              })}
            </Stack>
          </Stack>
        );
      }


    })
  )

}

export default AdminNavbar;