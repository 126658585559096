import {
  Backdrop,
  Box,
  Button,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
//   import Popup from "./popup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  endGame,
  getScenarioIpAddress,
  submitFlag,
} from "../../../APIConfig/scenarioConfig";
import "./index.css";
import { Icons } from "../../../components/icons";
import CustomModal from "../../../components/ui/CustomModal";
import Milestone from "./Milestone";
import Flags from "./Flags";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import jwtDecode from "jwt-decode";
import {
  endGameV2,
  getConsoleVersion2,
} from "../../../APIConfig/version2Scenario";
import LoaderImg from "../../../components/ui/loader";
import ErrorHandler from "../../../ErrorHandler";

const MachineProfileSenario = () => {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const [reload, setReload] = useState(true);
  const [message, setMessage] = useState([]);
  const [socket, setSocket] = useState(null);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [full, setFull] = useState(true);
  const [Data, setScore] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [consoleData, setConsoleUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const gameId = location?.state?.data?.active_scenario_id;
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});
  const iframeRef = useRef(null);
  const [ip, setIp] = useState([]);
  const [load, setLoad] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { id } = useParams();



  useEffect(() => {
    const getData = async () => {
      setLoad(true);
      try {
        const value = await getConsoleVersion2(id);
        setData(value?.data);
        console.log("flag data",value?.data)
        setConsoleUrl(value?.data?.console_url);
        setLoad(false);
      } catch (error) {
        if (error?.response?.data?.errors === "No Active Game Console Found.") {
          toast.error(error?.response?.data?.errors);
          navigate("/ActiveGameSenario/corporate");
          setLoad(false);
        }
      }
    };
    getData();
  }, [reload]);

  useEffect(() => {
    // Replace 'ws://your-websocket-server-url' with your WebSocket server URL
    //  //console.log(process.env.REACT_APP_WEB_SOCKET_URL)
    const serverUrl = `${process.env.REACT_APP_WEB_SOCKET_URL}/corporate/notification/${id}/`;
   
    // Create a new WebSocket instance
    const ws = new WebSocket(serverUrl);

    // WebSocket event listeners
    ws.onopen = () => {
      // Send the token as part of the initial message
      
      const authMessage = JSON.stringify({ token: token });
      ws.send(authMessage);
    };

    ws.onmessage = (event) => {
     
      const receivedMessage = event.data;
      const message = JSON.parse(receivedMessage)
      
      if (JSON.parse(receivedMessage)) {
        if(message.notifications == "reload"){
          
          setReload(!reload)
        }
      }
    };

    ws.onclose = () => {};

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(ws);

    // Clean up the WebSocket when the component unmounts
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [token]);

  const endGameConsole = async () => {
    setIsActive(true);

    try {
      await endGameV2(id);
      navigate("/");
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setIsActive(false);
    }
  };


  useEffect(() => {
    console.table(data);
    const token = localStorage.getItem("access_token");
    const user = jwtDecode(token);
    const IdUser = user?.user_id;
  }, []);

  // // refresh the consoleUrl
  // const refreshConsole = async () => {
  //   window.location.reload();
  //   // setIsActive(true);
  //   // const res = await getConsoleDetailsSenario(data?.active_scenario_id);
  //   // setConsoleUrl(res.data?.scenario_console_url);
  //   // setIsActive(false);
  // };

  const handleRefresh = () => {
    setReload(!reload);
  };

  // set console for white team
  const handleApi = (url) => {
    setConsoleUrl(url);
  };

  // submit flag
  const submitResponse = async () => {
    setIsActive(true);
    const response = await submitFlag(gameId, inputValue);
    if (response?.data?.scenario_archive_game_id) {
      setIsActive(false);
      toast.success("All tasks have been completed!");
      setScore(response);
      setInputValue("");

      setOpen(true);
    } else if (response?.data?.is_flag_correct) {
      setShowArrowIcon(false);
      setShowTickIcon(true);
      setInputValue("");
      setIsActive(false);
      toast.success(response?.data?.message);
    } else {
      setIsActive(false);
      toast.error(response?.data?.message);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getActiveIp = async () => {
    setIsActive(true);
    try {
      const value = await getScenarioIpAddress(data?.scenario_game_id);
      setIp(value?.data?.scenario_instance_ip_list);
      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const obj = error.response.data.errors;

      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
            i.slice(1).replace(/_/g, " ") +
            " - " +
            obj[i]
        );
      }
    }
  };

  const showAllClickHandler = () => {
    setOpenModal(true);
  };
  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const [isFocused, setIsFocused] = useState(false);
  const [showArrowIcon, setShowArrowIcon] = useState(true);
  const [showTickIcon, setShowTickIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    const target = event.currentTarget;
    if (ip.length > 0) {
      setAnchorEl(target);
    } else {
      getActiveIp()
        .then(() => {
          setAnchorEl(target);
        })
        .catch((error) => {
          console.error("Error in getActiveIp:", error);
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      submitResponse();

      setTimeout(() => {
        setShowArrowIcon(true);
        setShowTickIcon(false);
      }, 1000);
    }
  };

  return (
    <Stack margin={3} width="100%">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
     
      {/* container */}
      <Stack direction="row" gap={1}>
        {/* details section */}
        <Stack
          sx={{
            width: full ? "28%" : "0%",
            transition: full ? "width 0.3s ease-out" : "width 0.3s ease-in",
          }}
          height="95vh"
          style={{ overflowY: "scroll", scrollbarWidth: "none" }}
          className="example"
        >
          <Stack gap={3} sx={{ position: "sticky", top: 0 }}>
            {/* top section */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                onClick={endGameConsole}
                // disabled={data?.scenario_type === "MILESTONE" ? true : false}
                sx={{
                  display: data?.scenario_type === "MILESTONE" && "none",
                }}
              >
                End
              </Button>
              <Stack direction="row" gap={1} alignItems="center">
                <Tooltip title="Refresh">
                  {isRefreshing ? (
                    <CircularProgress color="inherit" size={24} /> // Render loading spinner while refreshing
                  ) : (
                    <Icons.refresh
                      onClick={handleRefresh}
                      style={{ fontSize: "24px", cursor: "pointer" }}
                    />
                  )}

                  {/* <Icons.refresh
                    onClick={refreshConsole}
                    style={{ fontSize: "24px", cursor: "pointer" }}
                  /> */}
                </Tooltip>
                <Icons.doubleLeftArrow
                  style={{ fontSize: "36px", cursor: "pointer" }}
                  onClick={() => setFull(!full)}
                />
              </Stack>
            </Stack>

            {/* <Stack direction="row" gap={2} position="relative" border={'1px solid white'}>
              <input
                placeholder="Enter Your Flag"
                style={{
                  width: "100%",
                  backgroundColor: "#1C1F28",
                  borderRadius: "8px",
                  height: "48px",
                  color: "#acacac",
                  border: "none",
                  padding: "12px 40px 12px 14px",
                }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value.trimStart())}
                onFocus={() => {
                  setIsFocused(true);
                  setShowArrowIcon(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                  setShowArrowIcon(false);
                }}
                onKeyDown={handleKeyDown}
              />
              {isFocused && showArrowIcon && (
                <Icons.rightArrowCircle
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    fontSize: "20px",
                    color: "#acacac",
                    cursor: "pointer",
                  }}
                />
              )}
              {showTickIcon && (
                <Icons.checkCircle
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    fontSize: "20px",
                    color: "#00FFFF",
                    cursor: "pointer",
                  }}
                />
              )}
            </Stack> */}

            <Stack gap={2}>
              {/* question and milestone */}
              <Stack>
                <Accordion
                  TransitionProps={{ timeout: 1200 }}
                  sx={{
                    backgroundColor: "#111218",
                    maxHeight: "66vh",
                    overflowY: "scroll",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography sx={{ color: "#EAEAEB" }}>
                      {data?.scenario_type}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data?.scenario_type == "FLAG" ? (
                      // <Stack gap={5}>
                      //   {data?.flag?.map((item) => (
                      //     <Flags item={item} />
                      //   ))}
                      // </Stack>

                      <Stack>
                        {data?.flag_data?.map((item, index) => (
                          <Flags
                            item={item}
                            index={index}
                            active_scenario_id={data?.active_scenario_id}
                            length={data?.flag_data?.length}
                            reload={reload}
                            setReload={setReload}
                            load={load}
                            flag_id={data?.flag_data[index]?.flag_id}
                            participant_id={data?.participant_id}
                            scenario_type={data?.scenario_type}
                            hint_used={data?.flag_data[index]?.hint_used}
                            hint_string={data?.flag_data[index]?.hint_string}
                            status ={data?.flag_data[index]?.status}
                          />
                        ))}
                      </Stack>
                    ) : (
                      <Stack gap={3}>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "13px",
                            lineHeight: "15px",
                            letterSpacing: "0.3393939435482025px",
                            color: "#6F727A !important",
                          }}
                        >
                          After completing the milestone click on milestone
                          achieved button to inform White teamer
                        </Typography>
                        <Milestone
                          item={data?.milestone_data}
                          scenario_id={gameId}
                          reload={reload}
                          setReload={setReload}
                          load={load}
                        />
                      </Stack>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Stack>
              {/* <Typography variant="h5" sx={{ color: "#BCBEC1 !important" }}>
                Browse to various IP’s to get started
              </Typography> */}

              {/* <Stack direction="row" justifyContent="start" alignItems="center" border='1px solid white'>
                <Button
                  variant="text"
                  sx={{
                    borderRadius: "4px 4px 0 0",
                    backgroundColor: "#002929 !important",
                    padding: "4px 60px",
                    borderBottom: "1px solid #00CCCC",
                  }}
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={handleClick}
                  endIcon={<Icons.downArrow />}
                >
                  IP Address
                </Button>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {ip?.map((item, index) => {
                    return (
                      <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        borderBottom="1px solid #EAEAEB"
                        padding={1}
                      >
                        <Typography
                          variant="h5"
                          sx={{ color: "#EAEAEB !important" }}
                        >
                          {item?.ip_address}
                        </Typography>
                        <Typography
                          variant="h5"
                          mx={1}
                          sx={{ color: "#EAEAEB !important" }}
                        >
                          -
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{ color: "#EAEAEB !important" }}
                        >
                          {item?.instance_name}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Menu>
              </Stack> */}
            </Stack>
            <Stack
              p={2}
              gap={2}
              sx={{ backgroundColor: "#16181F", borderRadius: "12px" }}
            >
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={1}
              >
                <Icons.userPin style={{ fontSize: "24px", color: "#BCBEC1" }} />
                <Typography variant="h4" sx={{ color: "#BCBEC1 !important" }}>
                  User Name :{" "}
                </Typography>
                <Typography variant="h4" sx={{ color: "#BCBEC1 !important" }}>
                  {data?.username}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={1}
              >
                <Icons.key style={{ fontSize: "24px", color: "#BCBEC1" }} />
                <Typography variant="h4" sx={{ color: "#BCBEC1 !important" }}>
                  Password :{" "}
                </Typography>
                <Typography variant="h4" sx={{ color: "#BCBEC1 !important" }}>
                  {data?.password}
                </Typography>
              </Stack>

              <Stack sx={{ borderBottom: "1px solid #535660" }}></Stack>

              <Stack
                direction="column-reverse"
                justifyContent="start"
                alignItems="start"
              >
                <Typography variant="h4">Walkthrough</Typography>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={4}
                  p={2}
                >
                  {data?.document_urls?.map((item, index) => {
                    return (
                      <Icons.pdf
                        key={index}
                        style={{
                          fontSize: "24px",
                          color: "#BCBEC1",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(item, "_blank")}
                      />
                    );
                  })}
                </Stack>
              </Stack>

              <Stack sx={{ borderBottom: "1px solid #535660" }}></Stack>

              <Typography variant="h3" sx={{ fontSize: "20px !important" }}>
                {" "}
                {data?.scenario_name}
              </Typography>

              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Icons.shield
                    style={{ fontSize: "24px", color: "#BCBEC1" }}
                  />
                  <Typography variant="h5" sx={{ color: "#BCBEC1 !important" }}>
                    {data?.severity}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Icons.userCheck
                    style={{ fontSize: "24px", color: "#BCBEC1" }}
                  />
                  <Typography variant="h5" sx={{ color: "#BCBEC1 !important" }}>
                    {data?.scenario_player_count}
                  </Typography>
                </Stack>
                <Typography
                  variant="h5"
                  sx={{
                    color: "#111218 !important",
                    backgroundColor: "#00CCCC",
                    borderRadius: "16px",
                    padding: "4px 16px",
                    fontWeight: "700 !important",
                  }}
                >
                  {data?.total_score} Points
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={0.5}
              >
                <Icons.show
                  style={{
                    fontSize: "24px",
                    color: "#92E7E1",
                    cursor: "pointer",
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    color: "#92E7E1 !important",
                    cursor: "pointer",
                  }}
                  onClick={showAllClickHandler}
                >
                  Show All
                </Typography>
              </Stack>
              {openModal && (
                <CustomModal open={openModal} onClose={closeModalHandler}>
                  <Box
                    sx={{ color: "#BCBEC1", height: "560px", overflow: "auto" }}
                    dangerouslySetInnerHTML={{
                      __html: data?.description,
                    }}
                  />
                </CustomModal>
              )}
              <Box sx={{ maxHeight: "300px", overflow: "auto" }}>
                <Box
                  sx={{ color: "#BCBEC1 " }}
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
        {/* setfull screen button */}
        <Tooltip title="Details Page" placement="top">
          <Icons.doubleRightArrow
            style={{
              fontSize: "36px",
              cursor: "pointer",
              width: full ? "0%" : "2.5%",
            }}
            onClick={() => setFull(!full)}
          />
        </Tooltip>

        {/* iframe section */}
        <Stack
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            width: !full ? "96%" : "78%",
            height: "95vh",
          }}
        >
          {/* <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {
                        cat?.map((item, index) => {
                            return (
                                <Tab label={item.name} key={index} onClick={() => handleApi(item?.url)} />
                            )
                        })
                    }


                </Tabs> */}
          <iframe
            // ref={iframeRef}
            src={data?.console_url}
            title="Tutorials"
            style={{ width: "100%", height: "100%" }}
            allow="same-origin"
          ></iframe>
        </Stack>
      </Stack>
      {/* iframe */}
      {/* <Stack
        style={{
          paddingTop: 4,
          paddingBottom: 4,
          display: "flex",
          width: !full ? "90vw" : "65vw",
          height: "95vh",
        }}
        position="fixed"
        // top={0}
        float="left"
        direction="row"
      >
        <iframe
          src={consoleData}
          title="Tutorials"
          style={{ width: "100%", height: "100%" }}
          allow="clipboard-read; clipboard-write"
        ></iframe>
      </Stack> */}

      {/* buttons and details combine */}
    </Stack>
  );
};

export default MachineProfileSenario;
