
const ApiConfig = {
  auth: {
    login: `/user/login/`,
    register: `/user/register/`,
    sendOtp: `/user/send-otp/`,
    otp: `/user/verify-otp/`,
    userDetails: `/user/unverified/`,
    refresh: '/user/token/refresh/'
  },
  machines: {
    createMachine: `/ctf/game/create/`,
    activeMachine: `/ctf/game/active/list/`,
    machine: `/ctf/game/`,
    list: `/ctf/machine/list`,
    create: `/ctf/machine/create/`,
    upload: `/ctf/game/create/target/upload/`,
    rate: `/ctf/game/severity/rate/`,
    draft: `/ctf/game/draft/list/`,
    CategoryMachine: `/ctf/game/based-on-category`,
    ctfWinningWall: `/user/common/winning-wall/ctf`,
    getCtfIp: `/ctf/game/ip/`,
    onCountEnd: "/api/ctf/game/score_by_id/"
  },
  user: {
    startCtf: `/ctf/game/start/`,
    endCtf: `/ctf/game/delete/`,
    getConsole: `/ctf/game/console/`,
    liveGame: `/cloud/user/live-game`,
    extendTime: `/ctf/game/extend-time/`,
    submitFlag: `/ctf/game/flag/submit/`,
    userProfile: `/user/detail/`,
    avatar: `/user/avatar/list/`,
    memberWall: `/user/list/`,
    mailingList: `/core/mailing-list/`,
    dashboardMembers: `/core/total/resources/`,
    topPerformer: `/user/top-performer/`,
    challenges: `/challenge/game/create/`,
    sendVerificationCode: "/user/forgot-password/",
    verifyOTP: "/user/verify-email/",
    setNewPassword: "/user/update-password/",
    totalUsers: "/core/total/resources/"
  },
  admin: {
    CreateCategory: `/ctf/category/create/`,
    user: `/ctf/admin/map-game/`,
    GetCategoryCtf: `/ctf/category/list/`, // remove
    getCTFCategories: `/admin/ctf/category/list/`,
    getCTFCategory: `/admin/ctf/category/update/`,
    addCTFCategory: `/admin/ctf/category/create/`,
    updateCTFCategory: `/admin/ctf/category/update/`,
    getScenarioCategories: `/admin/scenario/category/list/`,
    getScenarioCategory: `/admin/scenario/category/update/`,
    addScenarioCategory: `/admin/scenario/category/create/`,
    updateScenarioCategory: `/admin/scenario/category/update/`,
    getAllUsers: `/admin/user/list/`,
    addUser: `/admin/user/register/`,
    Mapping: `/ctf/admin/map-game/`,
    createScenarioCategory: `/scenario/category/create/`,
    listOfUnApprovedScenario: `/admin/scenario/game-approve/`,
    listOfUnApprovedCorporateRequests: `/admin/corporate/game-approve/`,
    approveScenario: `/admin/scenario/game-approve/`,
    approveCorporateRequests: `/admin/corporate/game-approve/`,
    approvalScenario: `/admin/scenario/game-unapprove/`,
    approvalCorporateRequests: `/admin/corporate/game-unapprove/`,
    unApprovalScenario: `/admin/scenario/game-unapprove/`,
    unApprovalCorporateRequests: `/admin/corporate/game-unapprove/`,
    userModify: `/admin/user/update/`,
    userDetails: `/admin/user/`,
    upMapCtf: `/admin/ctf/unmapped-game/list/`,
    mappedCtf: `/admin/ctf/mapped-game/`,
    submitCtf: `/admin/ctf/map-game/`,
    unmappedCtf: `/admin/ctf/mapping/delete/`,
    ctfList: `/admin/ctf/game/list/`,
    ctfUpdateGet: `/admin/ctf/game/update/`,
    ctfUpdatePost: `/admin/ctf/game/update/`,
    deleteUser: `/admin/user/remove/`,
    gameList: `/admin/scenario/game/list/`,
    gameDetails: `/admin/scenario/game/update/`,
    gameUpdate: `/admin/scenario/game/update/`,
    gameDelete: `/admin/scenario/game/document/remove/`,
    news: `/core/news/`,
    notification: "/notification/",
    getUserCTF: "/admin/user-specific/ctf/",
    removeUserCTF: "/admin/user-specific/ctf/",
    addUserCTF: "/admin/user/ctfs/",
    userCtfCat: "/admin/game/based-on-category/ctf/",


    getUserScenario: "/admin/user-specific/scenario/",
    removeUserScenario: "/admin/user-specific/scenario/",
    addUserScenario: "/admin/user/scenarios/",
    userScenarioCat: "/admin/game/based-on-category/scenario/",

    getUserCorporate: "/admin/user-specific/corporate/",
    removeUserCorporate: "/admin/user-specific/corporate/",
    addUserCorporate: "/admin/user/corporates/",
    userCorporateCat: "/admin/game/based-on-category/corporate/",




  },
  game: {
    leaderboard: `/ctf/game/leaderboard/`,
    challenge: `/ctf/game/challenge/`,
    challengeCreate: `/ctf/game/challenge/create/`,
  },
  category: {
    list: `/ctf/category/list`,
  },
  scenario: {
    createScenario: `/scenario/create/`,
    categoryList: `/scenario/category/list/`,
    infra: `/scenario/infra/create/`,
    ImageAndFal: `/core/instance/essentials/list/`,
    scenarioList: `/scenario/list/`,
    scenarioDetails: `/scenario/game/`,
    scenarioStartGame: `/scenario/game/start/`,
    activeScenarioList: `/scenario/active/game/`,
    scenarioConsole: `/scenario/game/console/`,
    scenarioDelete: `/scenario/game/delete/`,
    activeMachine: `/scenario/active/game/`,
    submitFlag: `/scenario/game/flag/submit/`,
    scenarioCategoryDetails: `/scenario/game/based-on-category/`,
    topology: `/scenario/game/topology`,
    scenarioWinningWall: `/user/common/winning-wall/scenario`,
    getScenarioIp: `/scenario/game/ips/`,
  },
  challenge: {
    ctf: `/challenge/game/challenged/list/ctf/`,
    scenario: `/challenge/game/challenged/list/scenario/`,
    create_challenge: `/challenge/game/create/`,
    delete_challenge: `/challenge/game/delete/`,
  },
};

export default ApiConfig;
