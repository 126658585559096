import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import BreadCrumbs from "../../../components/navbar/BreadCrumb";
import ScenarioFlagQuestions from "./ScenarioFlagQuestions";
import Teams from "./Teams";
import ScenarioWalkthrough from "../scenarioWalkthrough";
import FullScreenDialog from "../../CreateScenarios/scenarioDialog";
import ErrorHandler from "../../../ErrorHandler";
import { Icons } from "../../../components/icons";
import { toast, ToastContainer } from "react-toastify";

const initialTeamData = [
  {
    title: "Blue Team Flags",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#7A5AF8",
    active: true,
  },
  {
    title: "Red Team Flags",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#CC2E28",
    active: false,
  },
  {
    title: "Purple Team Flags",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#9747FF",
    active: false,
  },
  {
    title: "Yellow Team Flags",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#F79D28",
    active: false,
  },
];

function getDefaultQuestion() {
  return {
    question: "",
    answer: "",
    hint: "",
    score: 1,
   
    index: 0,
  };
}

const ScenarioFlags = ({ setFlag, handleApi, setToggle }) => {
  const [data, setData] = useState(initialTeamData);

  const validation = (data) => {
    console.log("data is here", data);
    if (!data.question || !data.answer || !data.hint || !data.score) {
      return true;
    }
  };
  const handleData = async () => {
    try {
      let blueWalkthrough = [];
      let redWalkthrough = [];
      let purpleWalkthrough = [];
      let yellowWalkthrough = [];
      let blueTeam = [];
      let redTeam = [];
      let purpleTeam = [];
      let yellowTeam = [];

      let validationValue = true;
      validationValue = data?.map((item) => {
        let validationData = true;

        if (
          item?.questionInformation[0].question !== "" ||
          item.questionInformation[0].answer !== "" ||
          item.questionInformation[0].hint !== ""
        ) {
          if (item?.title === "Blue Team Flags") {
            validationData = validation(item?.questionInformation[0]);
            if (validationData) {
              toast.error("All field are required in Blue Team question");
              return false;
            }
            blueTeam = item?.questionInformation;
            blueWalkthrough = item?.walkthrough;
          } else if (item?.title === "Red Team Flags") {
            validationData = validation(item?.questionInformation[0]);
            if (validationData) {
              toast.error("All field are required in Red Team question");
              return false;
            }
            redTeam = item?.questionInformation;
            redWalkthrough = item?.walkthrough;
          } else if (item?.title === "Purple Team Flags") {
            validationData = validation(item?.questionInformation[0]);
            if (validationData) {
              toast.error("All field are required in Purple Team question");
              return false;
            }
            purpleTeam = item?.questionInformation;
            purpleWalkthrough = item?.walkthrough;
          } else {
            validationData = validation(item?.questionInformation[0]);
            if (validationData) {
              toast.error("All field are required in Yellow Team question");
              return false;
            }
            yellowWalkthrough = item?.walkthrough;
            yellowTeam = item?.questionInformation;
          }
        }
      });

      if (validationValue[0] != undefined) return false;
      if (blueTeam[0]?.question === "") {
        blueTeam.shift();
      }

      if (redTeam[0]?.question === "") {
        redTeam.shift();
      }
      if (purpleTeam[0]?.question === "") {
        purpleTeam.shift();
      }

      if (yellowTeam[0]?.question === "") {
        yellowTeam.shift();
      }

      const milestoneObj = JSON.stringify({
        red_team: redTeam,
        blue_team: blueTeam,
        yellow_team: yellowTeam,
        purple_team: purpleTeam,
      });

      if (validationValue) {
        return await handleApi(
          milestoneObj,
          blueWalkthrough,
          redWalkthrough,
          purpleWalkthrough,
          yellowWalkthrough
        );
      }
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const breadcrumbs = [
    { name: "Dashboard", link: "/" },
    { name: "Scenario", link: "/users" },
    { name: "Create ", link: "/createscenarios" },
  ];

  const teamChangeHandler = (value) => {
    const updatedData = data.map((team) => ({
      ...team,
      active: team.color === value,
    }));
    setData(updatedData);
  };

  const addMoreFlagButtonHandler = () => {
    const activeTeam = data.find((team) => team.active);
    const index = activeTeam?.questionInformation.length;

    if (activeTeam) {
      const newQuestionInformation = {
        question: "",
        answer: "",
        hint: "",
        score: 1,
        index: index,
      };

      const updatedData = data.map((team) =>
        team.active
          ? {
              ...team,
              walkthrough: [],
              questionInformation: [
                newQuestionInformation,
                ...team.questionInformation,
              ],
            }
          : team
      );

      setData(updatedData);
    }
  };

  const updateFieldHandler = (index, field, value) => {
    const updatedData = [...data];
    updatedData.forEach((team) => {
      if (team.active) {
        team.questionInformation[index][field] = value;
      }
    });
    setData(updatedData);
  };

  const onSubmit = async () => {
    try {
      return await handleData();
    } catch (error) {
      ErrorHandler(error);
    }
  };

  const onDeleteQuestionHandler = (questionIndex) => {
    const updatedData = data.map((team) => {
      if (team.active) {
        if (team.questionInformation.length === 1) {
          return {
            ...team,
            walkthrough: [],
            questionInformation: [getDefaultQuestion()],
          };
        } else {
          return {
            ...team,
            questionInformation: team.questionInformation.filter(
              (_, index) => index !== questionIndex
            ),
          };
        }
      } else {
        return team;
      }
    });

    setData(updatedData);
  };

  const isAddMoreFlagButtonDisabled = () => {
    const activeTeam = data.find((team) => team.active);

    if (activeTeam) {
      const firstQuestion = activeTeam.questionInformation[0];

      const keysToCheck = Object.keys(firstQuestion).filter(
        (key) => key !== "hint"
      );

      return !keysToCheck.every(
        (key) => firstQuestion[key] !== undefined && firstQuestion[key] !== ""
      );
    }

    return true;
  };

  const onFileUpload = (files) => {
    const activeTeam = data.find((team) => team.active);

    if (activeTeam) {
      const updatedData = data.map((team) => {
        if (team.active) {
          return {
            ...team,
            walkthrough: [...team?.walkthrough, ...files],
          };
        } else {
          return team;
        }
      });

      setData(updatedData);
    }
  };

  const deleteFileHandler = (fileIndex) => {
    const activeTeamIndex = data.findIndex((team) => team.active);

    if (activeTeamIndex !== -1) {
      const updatedData = [...data];
      const activeTeam = updatedData[activeTeamIndex];

      if (fileIndex >= 0 && fileIndex < activeTeam.walkthrough.length) {
        activeTeam.walkthrough = activeTeam.walkthrough.filter(
          (_, index) => index !== fileIndex
        );

        updatedData[activeTeamIndex] = activeTeam;
        setData(updatedData);
      }
    }
  };

  const toggleButton = () => {
    setToggle(0);
  };
  return (
    <>
    <ToastContainer/>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <Stack px={2} py={4}>
        <Box onClick={toggleButton} sx={{ cursor: "pointer" }}>
          <Icons.leftArrow onClick={toggleButton} />
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography component="h1" variant="h1">
            Create
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <FullScreenDialog
              onSubmit={onSubmit}
              scenarioId={null}
              datas="Save & Continue"
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Stack
          p={2}
          sx={{
            borderRadius: "16px",
            backgroundColor: "#16181F",
            width: "772px",
          }}
        >
          <Box
            gap={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "start",
            }}
          >
            <Teams team={data} cta={teamChangeHandler} />
            <Stack gap={1} sx={{ width: "100%" }}>
              <Typography
                variant="h4"
                sx={{
                  color: "#9C9EA3 !important",
                  fontWeight: "500",
                  order: 0,
                }}
              >
                Flag Walkthrough
              </Typography>
              <ScenarioWalkthrough
                walkthrough={
                  data.find((team) => team.active)?.walkthrough || []
                }
                onFileUpload={onFileUpload}
                deleteFileHandler={deleteFileHandler}
              />
              <Box my={3} sx={{ border: "1px solid #282C38" }}></Box>
              <button
                style={{
                  width: "fit-content",
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  lineHeight: "16px !important",
                  borderRadius: "4px",
                  border: "none",
                  padding: "2px 16px",
                  cursor: isAddMoreFlagButtonDisabled()
                    ? "not-allowed"
                    : "pointer",
                  color: isAddMoreFlagButtonDisabled() ? "#005252" : "#00FFFF",
                  backgroundColor: isAddMoreFlagButtonDisabled()
                    ? "#1C1F28"
                    : "#002929",
                  marginBottom: "16px",
                }}
                onClick={addMoreFlagButtonHandler}
                disabled={isAddMoreFlagButtonDisabled()}
              >
                Add More Flag
              </button>

              <ScenarioFlagQuestions
                team={data}
                updateFieldHandler={updateFieldHandler}
                onDeleteQuestion={onDeleteQuestionHandler}
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default ScenarioFlags;
