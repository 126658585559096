import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import uploadImg from "../../components/assests/uploadIcon.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { createMachine, getCategory } from "../../APIConfig/CtfConfig";
import { toast } from "react-toastify";
import Form from "./Form";
import ListItem from "./ListItem";
import "./index.css";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import StepperHorizontal from "../../components/Stepper";
import TextEditor from "../../components/TextEditor";
import PlusButton from "../../components/PlusButton";
import BreadCrumbs from "../../components/navbar/BreadCrumb";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
      backgroundColor: "custom.main",
    },
  },
}));

const UploadMachine = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);

  const token = localStorage.getItem("access_token");
  // set all the inputs
  const [inputs, setInputs] = useState({
    ctf_description: "",
    ctf_severity: "",
    ctf_category_id: "",
    ctf_name: "",
    ctf_time: "",
    ctf_flags_information: "",

    // flags: []
  });
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  };

  const [file, setFile] = useState({ pdf: "", jpeg: "" });
  const [pdfFile, setPdfFile] = useState();
  const [fileData, setFileData] = useState({
    target_machine: "",
    ctf_id: "",
  });
  const [text, setText] = useState("");
  const [flagText, setFlagText] = useState("");
  const [rulesText, setRulesText] = useState("");
  // const [fileScreen, setFileScreen] = useState(false);

  let sol = "";

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (token) {
      setOpen(true);
    } else {
      navigate("/login");
    }
  };
  const handleClose = () => setOpen(true);

  const [flag, setflag] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const _handleSubmit = (e) => {
    if (inputValue.trim() === "") {
      toast.error("Flag name is required");
      return;
    }

    const newArr = flag.slice();
    newArr.splice(0, 0, { name: inputValue });
    setflag(newArr);
    setInputValue("");
  };

  //    get category
  useEffect(() => {
    const getCategorys = async () => {
      const data = await getCategory();
      setCategory(data?.data);
    };
    getCategorys();
  }, []);

  useEffect(() => {}, [file]);

  const handleInputChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileData((state) => ({ ...state, target_machine: e.target.files[0] }));
      return;
    }
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  useEffect(() => {}, [inputs, sol]);

  const flagChanges = () => {
    ////console.log('flag', flag)

    for (let i = 0; i < flag.length; i++) {
      sol = sol + flag[i].name + " ";
    }

    createMachines(sol);
  };

  const createMachines = async (sol) => {
    try {
      const data = await createMachine(inputs, sol, text, flagText, rulesText);
      if (data.data.ctf_creator_id) {
        navigate("/uploadmachine/uploadfile", {
          state: { ctf_id: data?.data?.ctf_id },
        });
      }
    } catch (error) {
    
      const obj = error.response.data.errors;
      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
            i.slice(1).replace(/_/g, " ") +
            " - " +
            obj[i]
        );
      }
    }
  };

  const data = {
    index: 0,
    steps: ["Fill Form", "Upload Machine"],
  };

  const handlechangePdf = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      if (image.type.split("/").pop() == "pdf") {
        setFile((state) => ({
          ...state,
          [image.type.split("/").pop()]: e.target.files[0],
        }));
        setInputs((state) => ({
          ...state,
          [e.target.name]: e.target.files[0],
        }));
        return;
      } else {
        toast.error("Please upload a pdf");
      }
    }
  };

  const handlechangeJpeg = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      if (
        image.type.split("/").pop() == "jpeg" ||
        image.type.split("/").pop() == "png"
      ) {
        setFile((state) => ({ ...state, ["jpeg"]: e.target.files[0] }));
        setInputs((state) => ({
          ...state,
          [e.target.name]: e.target.files[0],
        }));
        return;
      } else {
        toast.error("Please upload a jpeg");
      }
    }
  };

  const removeFlag = (index) => {
    const newArr = flag.slice();
    newArr.splice(index, 1);

    return setflag(newArr);
  };

  const changeHandler = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const breadcrumbs = [
    { name: "Dashboard", link: "/" },
    { name: "Upload Solo", link: "/uploadmachine" },
  ];
  return (
    <Stack>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mx: 5, mt: 2 }}
      >
        <Typography variant="h2">Create Solo challange</Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            width: "160px",
            p: 2,
            borderRadius: "8px",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "normal",
            fontStyle: "normal",
          }}
          onClick={flagChanges}
        >
          Save & Continue
        </Button>
      </Stack>
      <Stack margin={5} gap={4} sx={{ overflowY: "scroll", height: "80vh" }}>
        <Stack
          gap={4}
          justifyContent="center"
          alignItems="center"
          bgcolor="background.secondary"
          padding={8}
        >
          <StepperHorizontal data={data} />
          {/*form side*/}
          <Stack width="90%" gap={3}>
            <Stack width="100%">
              <TextField
                name="ctf_name"
                type="text"
                label="Game Name"
                variant="outlined"
                onChange={changeHandler}
              />
            </Stack>
            <Stack direction="row" gap="1%">
              <Box
                sx={{
                  minWidth: "49.5%",

                  borderRadius: "8px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs.ctf_category_id}
                    label="Select Category"
                    name="ctf_category_id"
                    onChange={handleInputChange}
                    // MenuProps={MenuProps}

                    sx={{
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#acacac !important",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#686868",
                        },
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {category?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.ctf_category_id}>
                          {item.ctf_category_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{
                  minWidth: "49.5%",
                  borderRadius: "8px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={inputs.ctf_severity}
                    name="ctf_severity"
                    label="Severity"
                    onChange={handleInputChange}
                    sx={{
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#acacac !important",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#686868",
                        },
                      },
                    }}
                  >
                    <MenuItem value="Very Easy">Very Easy</MenuItem>
                    <MenuItem value="Easy">Easy</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Hard">Hard</MenuItem>
                    <MenuItem value="Very Hard">Very Hard</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Stack direction="row" gap="1%">
              <TextField
                name="ctf_score"
                style={{ width: "49.5%" }}
                type="text"
                label="Score"
                variant="outlined"
                onChange={changeHandler}
              />

              <Box
                sx={{
                  minWidth: 120,
                  borderRadius: "8px",
                  width: "49.5%",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: "#12464C" }}
                  >
                    Time(hr)
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs.ctf_time}
                    label="Timehr"
                    name="ctf_time"
                    onChange={handleInputChange}
                    sx={{
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#acacac !important",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#686868",
                        },
                      },
                    }}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>

            <Stack marginBottom={4}>
              <Typography style={{ color: "#acacac", size: "large" }}>
                Description
              </Typography>
              <TextEditor setText={setText} text={text} />
            </Stack>
            <Stack marginBottom={4}>
              <Typography style={{ color: "#acacac", size: "large" }}>
                Flag Information
              </Typography>
              <TextEditor setText={setFlagText} text={flagText} />
            </Stack>
            <div>
              <Stack gap={2}>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    name="ctf_flags"
                    style={{ width: "90%" }}
                    value={inputValue}
                    label="Enter Flags"
                    onChange={(e) => setInputValue(e.target.value.trimStart())}
                    variant="outlined"
                    onKeyPress={handleEnterKeyPress}
                  />

                  <PlusButton onClick={_handleSubmit} />
                </Stack>
                <Stack
                  flexDirection="column"
                  width="100%"
                  height="150px"
                  display={flag.length >= 1 ? "flex" : "none"}
                  overflow="scroll"
                  gap={2}
                  style={{ maxHeight: "150px", color: "white" }}
                >
                  {flag.map((f, index) => (
                    <ListItem
                      sx={{ color: "white" }}
                      key={index}
                      flag={f}
                      remove={() => removeFlag(index)}
                    />
                  ))}
                </Stack>
              </Stack>
            </div>
            {/* <Link to="/uploadmachine/uploadfile">  */}

            {/* </Link> */}
            <Stack marginBottom={4}>
              <Typography style={{ color: "#acacac", size: "large" }}>
                Rules and Regulation
              </Typography>
              <TextEditor setText={setRulesText} text={rulesText} />
            </Stack>
          </Stack>
          {/* upload side */}

          <Stack
            direction="row"
            sx={{
              color: "white",
              justifyContent: "space-around",
              marginTop: 2,
              marginBottom: 2,
              width: "100%",
              gap: 2,
              p: 1,
            }}
          >
            <Stack
              width="50%"
              alignItems="center"
              justifyContent="center"
              sx={{
                border: "1px dashed #12464C",
                borderRadius: "8px",
                p: 2,
                overflow: "hidden",
              }}
            >
              <Typography variant="h2">Upload Thumbnail</Typography>
              {file?.jpeg ? (
                <Stack direction="row" mt={2}>
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {file?.jpeg && `${file?.jpeg?.name}`}
                  </Typography>
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFile((state) => ({ ...state, ["jpeg"]: "" }));
                    }}
                  />
                </Stack>
              ) : (
                <Box
                  component="label"
                  variant="text"
                  sx={{
                    color: "#fff",
                    backgroundColor: "custom.main",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <Stack
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 4,
                      padding: 4,
                    }}
                  >
                    <img
                      src={uploadImg}
                      width="24px"
                      height="24px"
                      style={{ marginTop: 32 }}
                    />
                    <Typography
                      variant="body"
                      fontSize={12}
                      style={{ textAlign: "center", color: "#6f727a" }}
                    >
                      Select a .jpeg/.png file from your computer to upload
                    </Typography>
                    <input
                      type="file"
                      hidden
                      onChange={handlechangeJpeg}
                      name="ctf_thumbnail"
                    />
                  </Stack>
                </Box>
              )}
            </Stack>
            <Stack
              width="50%"
              alignItems="center"
              justifyContent="center"
              sx={{
                border: "1px dashed #12464C",
                borderRadius: "8px",
                p: 2,
                overflow: "hidden",
              }}
            >
              <Typography variant="h2">Upload Walkthrough</Typography>
              {file?.pdf ? (
                <Stack direction="row" mt={2}>
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {file?.pdf && `${file?.pdf?.name}`}
                  </Typography>
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFile((state) => ({ ...state, ["pdf"]: "" }));
                    }}
                  />
                </Stack>
              ) : (
                <Box
                  component="label"
                  variant="text"
                  sx={{
                    color: "#fff",
                    backgroundColor: "custom.main",
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <Stack
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 4,
                      padding: 4,
                    }}
                  >
                    <img
                      src={uploadImg}
                      width="24px"
                      height="24px"
                      style={{ marginTop: 32 }}
                    />
                    <Typography
                      variant="body"
                      fontSize={12}
                      style={{ textAlign: "center", color: "#6f727a" }}
                    >
                      Select a PDF file from your computer to upload{" "}
                    </Typography>
                    <input
                      type="file"
                      hidden
                      onChange={handlechangePdf}
                      name="ctf_walkthrough"
                    />
                  </Stack>
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UploadMachine;
