import React, {useEffect, useState} from "react";
import "../index.css";
import {getAllAvatars, registerUser} from "../APIConfig/CtfConfig";
import {Avatar, Box, Button, Stack, Typography,} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {toast,} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logincon from "../components/assests/Logincon.jpg";
import CustomizeInput from "../components/CustomizeInput";
import CustomizeHiddenInput from "../components/CustomizeHiddenInput";
import Dropdown from "../components/DropDown";

export const Register = () => {
  const navigate = useNavigate();

  const [avatars, setAvatars] = useState([]);
  const options = [
    {value: 'RED TEAM ', label: 'RED TEAM'},
    {value: 'BLUE TEAM', label: 'BLUE TEAM'},
    {value: 'PURPLE TEAM', label: 'PURPLE TEAM'},
    {value: 'WHITE TEAM', label: 'WHITE TEAM'},
    {value: 'YELLOW TEAM', label: 'YELLOW TEAM'},
    {value: 'OTHER TEAM', label: 'OTHER TEAM'},

  ];
  const [sign, setSign] = useState({
    user_full_name: "",
    mobile_number: "",
    email: "",
    password: "",
    confirm_password: "",
    user_avatar: "",
    user_role: "RED TEAM",
  });
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState({
    password: true,
  });
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  useEffect(() => {
    // //console.log(sign);
    getAvatars();
    setSign({...sign, user_avatar: avatars[0]});
  }, []);

  const handleSignInChange = (e) => {
    const {name, value} = e.target;
    const input = value.replace(/\s/g, '');

    if (!isNaN(input) && sign?.mobile_number.length < 10 && name == 'mobile_number') {
      setSign({
        ...sign,
        [name]: input,
      });
    } else setSign({...sign, [name]: value.trimStart()});

  }

  const handleChangeSign = async () => {
    try {
      const response = await registerUser(sign);
      navigate("/auth/login");
    } catch (error) {
      const obj = error.response.data.errors;
      for (let i in obj) {
        let str = ''
        if (i !== "non_field_errors") {
          str = str + i.charAt(0).toUpperCase() + i.slice(1).replace(/_/g, " ") + " "
        }
        str = str + obj[i]
        toast.error(str);
      }
    }
  }
  const [showPassword, setShowPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowChangePassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [selected, setSelected] = useState(0);

  const getAvatars = async () => {
    const response = await getAllAvatars();
    setAvatars(response?.data?.user_avatar_list);
  };

  const handleAvatarClick = (index, avatar) => {
    setSelected(index);
    setSign({...sign, user_avatar: avatar});
  };

  return (<Stack justifyContent="space-between" direction="row" width="100%">
    <Stack
      width={{xs: '100%', sm: '100%', md: '50%', lg: '40%', xl: '40%'}}
      display="flex"
      flexDirection="column"
      gap={2}
      p={5}

    >
      <Stack>
        <Typography
          style={{fontWeight: 'bold', color: '#F4F4F4'}}
          variant="h1">
          Create Account
        </Typography>
        <Typography style={{color: '#fff'}} variant="body2">
          The account has permission to connect to the cyber range network and access the simulated systems, servers,
          and devices within
          the environment{' '}
        </Typography>
      </Stack>
      <Box>
        <Typography sx={{color: '#fff', textAlign: 'left', mb: 1}}>
          Choose Avatar:
        </Typography>
        <Box
          display="flex"
          gap={2}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
        >
          {avatars?.map((avatar, i) => {
            return (<Avatar
              key={i}
              src={avatar}
              sx={{
                cursor: 'pointer', border: selected === i ? '2px solid white' : '',
              }}
              onClick={() => handleAvatarClick(i, avatar)}
            />)
          })}
        </Box>
      </Box>
      <Box>
        {/* <FormControl sx={{ minWidth: "100%", }}>
            <Typography>User Role</Typography>
            <Select
            labelId="demo-simple-select-label"
                id="demo-simple-select"
              value={sign.user_role}
              name="user_role"
              onChange={handleSignInChange}

             inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="RED_TEAM"> RED_TEAM </MenuItem>
              <MenuItem value="BLUE_TEAM">BLUE_TEAM</MenuItem>
              <MenuItem value="PURPLE_TEAM">PURPLE_TEAM</MenuItem>
              <MenuItem value="WHITE_TEAM">WHITE_TEAM</MenuItem>
              <MenuItem value="YELLOW_TEAM">YELLOW_TEAM</MenuItem>
              <MenuItem value="YELLOW_TEAM">OTHER_TEAM</MenuItem>
            </Select>
          </FormControl> */}
        <Stack width="100%">
          <Dropdown
            name="user_role"
            value={sign.user_role}
            options={options}
            onChange={handleSignInChange}
          />
        </Stack>
      </Box>
      <Stack
        display="flex"
        flexDirection="column"
      >
        <CustomizeInput
          label="Email"
          type="text"
          name="email"
          onChange={handleSignInChange}
          value={sign.email}
          placeholder="Enter your Email I'd"
        />
      </Stack>
      <Stack
        display="flex"
        flexDirection="column"
      >
        <CustomizeInput
          label="Name"
          type="text"
          name="user_full_name"
          onChange={handleSignInChange}
          value={sign.user_full_name}
          placeholder="Enter your name"
        />
      </Stack>
      <Stack
        display="flex"
        flexDirection="column"
      >
        <CustomizeInput
          label="Mobile number"
          type="number"
          name="mobile_number"
          placeholder="Enter Mobile Number"
          onChange={handleSignInChange}
          value={sign.mobile_number}
          />
      </Stack>
      <Stack>
        <CustomizeHiddenInput
          label="Password"
          type="password"
          name="password"
          key="password"
          placeholder="Enter Password"
          onChange={handleSignInChange}
          value={sign.password}
        />
      </Stack>

      <Stack>
        <CustomizeHiddenInput
          label="Confirm Password"
          type="password"
          key="password"
          name="confirm_password"
          placeholder="Confirm Password"
          onChange={handleSignInChange}
          value={sign.confirm_password}
        />
      </Stack>
      <Stack item display="flex" justifyContent="space-between">
        <Button
          sx={{marginTop: '10px', width: '100%'}}
          variant="contained"
          color="secondary"
          onClick={() => handleChangeSign()}
        >
          Continue
        </Button>
        <Stack sx={{alignItems: 'center'}}>
          <Typography
            variant="body1"
            mt={1}
            sx={{color: '#F4F4F4', textDecoration: 'none'}}
          >
            Already have an account ?{' '}
            <span
              style={{color: '#00FFFF', cursor: 'pointer'}}
              onClick={() => navigate('/auth/login')}
            >
              Login
            </span>{' '}
          </Typography>
        </Stack>
        {/* <Button sx={{ fontWeight: 'bold' }} variant='contained' color="secondary" onClick={handleChangeSign}>Submit</Button> */}
      </Stack>
    </Stack>
    <Stack width={{xs: '0%', sm: '0%', md: '50%', lg: '60%', xl: '60%'}} overflow="hidden" position="fixed" right={0}>
      <img
        src={logincon}
        alt="login-img"
        style={{width: '100%', height: '100vh', cursor: 'default'}}
      />
    </Stack>
  </Stack>)
}
