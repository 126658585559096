import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Icons } from "../../../components/icons";
import { toast, ToastContainer } from "react-toastify";
import { addUser, UserDataUpdate } from "../../../APIConfig/adminConfig";
import { useNavigate } from "react-router-dom";
import { getAllAvatars } from "../../../APIConfig/CtfConfig";
import ReactMultiCarousel from "react-multi-carousel";
import ErrorHandler from "../../../ErrorHandler";

const AddOrUpdateUser = (props) => {
  const { template, data } = props;

  const navigate = useNavigate();

  // Constants for max and min lengths
  const MAX_NAME_LENGTH = 100;

  const [avatars, setAvatars] = useState([]);

  const [selectedAvatar, setSelectedAvatar] = useState("");

  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
    team: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    isVerified: false,
    isPremium: false,
    isActive: false,
    isAdmin: false,
    display_all_ctf: false,
    display_all_scenario: false,
    display_all_corporate: false,
    display_locked_ctf: false,
    display_locked_scenario: false,
    display_locked_corporate: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 5,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 4,
    },
  };

  useEffect(() => {
    const getAvatars = async () => {
      const response = await getAllAvatars();
      setAvatars(response?.data?.user_avatar_list);
    };
    getAvatars();
  }, []);

  useEffect(() => {
    if (data) {
      setUser({
        ...user,
        name: data?.user_full_name,
        email: data?.email,
        team: data?.user_role,
        mobileNumber: data?.mobile_number,
        password: data?.password,
        confirmPassword: data?.confirm_password,
        isVerified: data?.is_verified,
        isPremium: data?.is_premium,
        isAdmin: data?.is_admin,
        isActive: data?.is_active,
        display_all_ctf: data?.display_all_ctf,
        display_all_scenario: data?.display_all_scenario,
        display_all_corporate: data?.display_all_corporate,
        display_locked_ctf: data?.display_locked_ctf,
        display_locked_scenario: data?.display_locked_scenario,
        display_locked_corporate: data?.display_locked_corporate,
      });
      setSelectedAvatar(data.user_avatar);
    }
  }, [data]);

  useEffect(() => {
    if (template === "Add User") {
      setSelectedAvatar(avatars[0]);
    }
  }, [avatars]);

  const avatarInputHandler = (value) => {
    setSelectedAvatar(value);
  };

  const nameInputHandler = (value) => {
    setUser({ ...user, name: value });
  };

  const emailInputHandler = (value) => {
    setUser({ ...user, email: value });
  };

  const teamInputHandler = (value) => {
    setUser({ ...user, team: value });
  };

  const mobileNumberInputHandler = (value) => {
    setUser({ ...user, mobileNumber: value });
  };

  const passwordInputHandler = (value) => {
    setUser({ ...user, password: value });
  };

  const confirmPasswordInputHandler = (value) => {
    setUser({ ...user, confirmPassword: value });
  };

  const passwordVisibilityHandler = () => {
    setShowPassword(!showPassword);
  };

  const confirmPasswordVisibilityHandler = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const isVerifiedHandler = () => {
    setUser({ ...user, isVerified: !user.isVerified });
  };

  const isPremiumHandler = () => {
    setUser({ ...user, isPremium: !user.isPremium });
  };

  const isAdminHandler = () => {
    setUser({ ...user, isAdmin: !user.isAdmin });
  };
  const isActiveHandler = () => {
    setUser({ ...user, isActive: !user.isActive });
  };

  const handleDisplay_all_ctf = () => {
    setUser({ ...user, display_all_ctf: !user.display_all_ctf });
  };

  const handleDisplay_all_scenario = () => {
    setUser({ ...user, display_all_scenario: !user.display_all_scenario });
  };

  const handleDisplay_all_corporate = () => {
    setUser({ ...user, display_all_corporate: !user.display_all_corporate });
  };

  const handle_Locked_ctf = () => {
    setUser({ ...user, display_locked_ctf: !user.display_locked_ctf });
  };

  const handle_Locked_scenario = () => {
    setUser({
      ...user,
      display_locked_scenario: !user.display_locked_scenario,
    });
  };

  const handle_Locked_corporate = () => {
    setUser({
      ...user,
      display_locked_corporate: !user.display_locked_corporate,
    });
  };

  const isFormValid = () => {
    if (template === "Add User") {
      if (
        !user.name ||
        !user.email ||
        !user.team ||
        !user.mobileNumber ||
        !user.password ||
        !user.confirmPassword
      ) {
        toast.error("All fields are required.");
        return false;
      }

      if (user.password.length < 8 || /^\d+$/.test(user.password)) {
        toast.error(
          "Password must be at least 8 characters long and cannot be entirely numeric."
        );
        return false;
      }

      if (/^\d+$/.test(user.password)) {
        toast.error("Password cannot be entirely numeric");
        return false;
      }
      if (user.password !== user.confirmPassword) {
        toast.error("Password and Confirm Password do not match");
        return false;
      }
    } else {
      if (!user.name || !user.email || !user.team || !user.mobileNumber) {
        toast.error("All fields are required.");
        return false;
      }
      if (user?.password?.length < 8 || /^\d+$/.test(user?.password)) {
        toast.error(
          "Password must be at least 8 characters long and cannot be entirely numeric."
        );
        return false;
      }

      if (user?.password !== user?.confirmPassword) {
        toast.error("Password and Confirm Password do not match");
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      try {
        if (template === "Add User") {
          const response = await addUser(user, selectedAvatar);
          if (response) {
            toast.success(response.data.message);
            navigate("/admin/users");
          }
        } else {
          const response = await UserDataUpdate(
            data.user_id,
            user,
            selectedAvatar
          );
          if (response) {
            toast.success(response.data.message);
            navigate("/admin/users");
          }
        }
      } catch (error) {
        ErrorHandler(error);
      }
    }
  };

  // useEffect(() => {
  //   console.log(user.display_all_ctf, "user data display_all_ctf");
  //   console.log(user, "user  ");
  // }, [user.display_all_ctf]);

  // useEffect(() => {
  //   console.log(user.display_all_scenario, "user data display_all_ctf");
  // }, [user.display_all_scenario]);

  return (
    <>
      <Stack px={2} py={4}>
        <ToastContainer />
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography componant="h1" variant="h1">
              {template}
            </Typography>
            <Button variant="contained" onClick={handleSubmit}>
              {template}
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Stack
              mt={4}
              spacing={3}
              sx={{
                borderRadius: "16px",
                backgroundColor: "#16181F",
                width: "100%",
                maxWidth: "772px",
                padding: "24px 40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  componant="body2"
                  variant="body2"
                  mb={3}
                  sx={{ color: "#EAEAEB !important" }}
                >
                  Choose Avatar
                </Typography>
                <img
                  src={selectedAvatar}
                  alt="upload_file_icon"
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "100%",
                  }}
                />
              </Box>

              {/* <ReactMultiCarousel responsive={responsive} slidesToSlide={1}>
                {avatars.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                    alt="avatar-icon"
                    onClick={() => avatarInputHandler(url)}
                  />
                ))}
              </ReactMultiCarousel> */}

              <Stack direction="row" spacing={3}>
                <Box style={{ position: "relative", width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={user.name}
                    onChange={(event) => nameInputHandler(event.target.value)}
                    style={{
                      width: "100%",
                      backgroundColor: "#1C1F28",
                      borderRadius: "8px",
                      height: "48px",
                      color: "#acacac",
                      border: "none",
                      padding: "12px 14px",
                    }}
                    maxLength={MAX_NAME_LENGTH}
                  />
                  <Box
                    style={{
                      backgroundColor: "#1C1F28",
                      display: "flex",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Icons.user
                      style={{ fontSize: "24px", color: "#6F727A" }}
                    />
                  </Box>
                </Box>
                <input
                  type="text"
                  placeholder="Email Address"
                  value={user.email}
                  onChange={(event) => emailInputHandler(event.target.value)}
                  style={{
                    width: "100%",
                    backgroundColor: "#1C1F28",
                    borderRadius: "8px",
                    height: "48px",
                    color: "#acacac",
                    border: "none",
                    padding: "12px 14px",
                  }}
                />
              </Stack>

              {/*<Box style={{*/}
              {/*  position: 'relative',*/}
              {/*  width: '100%',*/}
              {/*  backgroundColor: '#1C1F28',*/}
              {/*  borderRadius: '8px',*/}
              {/*  height: '48px',*/}
              {/*  padding: '12px 14px',*/}
              {/*  cursor: 'pointer',*/}
              {/*  display: 'inline-block'*/}
              {/*}}>*/}
              {/*  <select*/}
              {/*    name='Select Role'*/}
              {/*    id='role'*/}
              {/*    value={user.role}*/}
              {/*    onChange={(event) => roleInputHandler(event.target.value)}*/}
              {/*    style={{*/}
              {/*      width: '100%',*/}
              {/*      backgroundColor: '#1C1F28',*/}
              {/*      color: '#6F727A',*/}
              {/*      border: 'none',*/}
              {/*      padding: '0',*/}
              {/*      cursor: 'pointer',*/}
              {/*      position: 'relative',*/}
              {/*    }}>*/}
              {/*    <option value='' style={{cursor: 'pointer'}} disabled selected hidden>Select Role</option>*/}
              {/*    <option value='Admin' style={{cursor: 'pointer'}}>Admin</option>*/}
              {/*    <option value='Super Admin' style={{cursor: 'pointer'}}>Super Admin</option>*/}
              {/*  </select>*/}
              {/*  <div style={{*/}
              {/*    position: 'absolute',*/}
              {/*    top: '50%',*/}
              {/*    right: '4px',*/}
              {/*    transform: 'translateY(-50%)',*/}
              {/*    pointerEvents: 'none',*/}
              {/*    color: '#acacac',*/}
              {/*  }}>*/}
              {/*  </div>*/}
              {/*</Box>*/}
              <Stack direction="row" spacing={3}>
                <Box
                  style={{
                    position: "relative",
                    width: "100%",
                    backgroundColor: "#1C1F28",
                    borderRadius: "8px",
                    height: "48px",
                    padding: "12px 14px",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <select
                    name="Select Role"
                    value={user.team}
                    id="role"
                    onChange={(event) => teamInputHandler(event.target.value)}
                    style={{
                      width: "100%",
                      backgroundColor: "#1C1F28",
                      color: "#6F727A",
                      border: "none",
                      padding: "0",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <option
                      value=""
                      style={{ cursor: "pointer" }}
                      disabled
                      selected
                      hidden
                    >
                      Select Team
                    </option>
                    <option value="RED TEAM" style={{ cursor: "pointer" }}>
                      Red Team
                    </option>
                    <option value="WHITE TEAM" style={{ cursor: "pointer" }}>
                      White Team
                    </option>
                    <option value="PINK TEAM" style={{ cursor: "pointer" }}>
                      Pink Team
                    </option>
                    <option value="YELLOW TEAM" style={{ cursor: "pointer" }}>
                      Yellow Team
                    </option>
                  </select>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "4px",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                      color: "#acacac",
                    }}
                  ></div>
                </Box>

                <Box style={{ position: "relative", width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    value={user.mobileNumber}
                    onChange={(event) => {
                      // Use a regular expression to remove non-numeric characters
                      const numericValue = event.target.value.replace(
                        /\D/g,
                        ""
                      );

                      // Ensure the length does not exceed 10 characters
                      const limitedValue = numericValue.slice(0, 10);

                      // Update the state with the sanitized value
                      mobileNumberInputHandler(limitedValue);
                    }}
                    style={{
                      width: "100%",
                      backgroundColor: "#1C1F28",
                      borderRadius: "8px",
                      height: "48px",
                      color: "#acacac",
                      border: "none",
                      padding: "12px 14px",
                    }}
                  />
                  <Box
                    style={{
                      display: "flex",
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Icons.phone
                      style={{ fontSize: "24px", color: "#6F727A" }}
                    />
                  </Box>
                </Box>
              </Stack>
              <Box style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  value={user.password}
                  onChange={(event) => passwordInputHandler(event.target.value)}
                  style={{
                    width: "100%",
                    backgroundColor: "#1C1F28",
                    borderRadius: "8px",
                    height: "48px",
                    color: "#acacac",
                    border: "none",
                    padding: "12px 14px",
                  }}
                />
                <Box
                  style={{
                    backgroundColor: "#1C1F28",
                    display: "flex",
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showPassword ? (
                    <Icons.hide
                      style={{
                        fontSize: "24px",
                        color: "#6F727A",
                        cursor: "pointer",
                      }}
                      onClick={passwordVisibilityHandler}
                    />
                  ) : (
                    <Icons.show
                      style={{
                        fontSize: "24px",
                        color: "#6F727A",
                        cursor: "pointer",
                      }}
                      onClick={passwordVisibilityHandler}
                    />
                  )}
                </Box>
              </Box>

              <Box style={{ position: "relative" }}>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={user.confirmPassword}
                  onChange={(event) =>
                    confirmPasswordInputHandler(event.target.value)
                  }
                  style={{
                    width: "100%",
                    backgroundColor: "#1C1F28",
                    borderRadius: "8px",
                    height: "48px",
                    color: "#acacac",
                    border: "none",
                    padding: "12px 14px",
                  }}
                />
                <Box
                  style={{
                    backgroundColor: "#1C1F28",
                    display: "flex",
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showConfirmPassword ? (
                    <Icons.hide
                      style={{
                        fontSize: "24px",
                        color: "#6F727A",
                        cursor: "pointer",
                      }}
                      onClick={confirmPasswordVisibilityHandler}
                    />
                  ) : (
                    <Icons.show
                      style={{
                        fontSize: "24px",
                        color: "#6F727A",
                        cursor: "pointer",
                      }}
                      onClick={confirmPasswordVisibilityHandler}
                    />
                  )}
                </Box>
              </Box>

              {/* bottom stack for check and toggle */}
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  // border: "1px solid white",
                }}
              >
                {/* first box  */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    // border: "2px solid yellow",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* verrified user */}
                  <Stack direction="row">
                    {user.isVerified ? (
                      <Icons.checkboxTrue
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={isVerifiedHandler}
                      />
                    ) : (
                      <Icons.checkboxFalse
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={isVerifiedHandler}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={isVerifiedHandler}
                    >
                      Verify this account?
                    </label>
                  </Stack>
                </Box>

                {/* second box */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    // border: "2px solid yellow",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* premium access check */}
                  <Stack direction="row">
                    {user.isPremium ? (
                      <Icons.checkboxTrue
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={isPremiumHandler}
                      />
                    ) : (
                      <Icons.checkboxFalse
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={isPremiumHandler}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={isPremiumHandler}
                    >
                      Give Premium Access
                    </label>
                  </Stack>
                </Box>

                {/* third box */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    // border: "2px solid yellow",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* create admin */}
                  <Stack direction="row">
                    {user.isAdmin ? (
                      <Icons.checkboxTrue
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={isAdminHandler}
                      />
                    ) : (
                      <Icons.checkboxFalse
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={isAdminHandler}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={isAdminHandler}
                    >
                      Create Admin
                    </label>
                  </Stack>
                </Box>

                {/* fourth box */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    // border: "2px solid yellow",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* active status */}
                  <Stack direction="row">
                    {user.isActive ? (
                      <Icons.checkboxTrue
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={isActiveHandler}
                      />
                    ) : (
                      <Icons.checkboxFalse
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={isActiveHandler}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={isActiveHandler}
                    >
                      Active Status
                    </label>
                  </Stack>
                </Box>
              </Stack>

              <Box
                sx={{
                  // border: "1px solid white",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent:'space-between'
                }}
              >
                
                {/* first box  */}
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-evenly",
                    // border: "2px solid yellow",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* ctf display check */}
                  <Stack direction="row">
                    {user?.display_all_ctf ? (
                      <Icons.toggleRight
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={handleDisplay_all_ctf}
                      />
                    ) : (
                      <Icons.toggleLeft
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={handleDisplay_all_ctf}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleDisplay_all_ctf}
                    >
                      Solo
                    </label>
                  </Stack>

                  {/* ctf locked check */}
                  <Stack direction="row">
                    {user?.display_locked_ctf ? (
                      <Icons.toggleRight
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={handle_Locked_ctf}
                      />
                    ) : (
                      <Icons.toggleLeft
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={handle_Locked_ctf}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handle_Locked_ctf}
                    >
                      Solo Locked
                    </label>
                  </Stack>
                </Box>

                {/* second box */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    // border: "2px solid yellow",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* toggle 2 for squad */}
                  <Stack direction="row">
                    {user?.display_all_scenario ? (
                      <Icons.toggleRight
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={handleDisplay_all_scenario}
                      />
                    ) : (
                      <Icons.toggleLeft
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={handleDisplay_all_scenario}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleDisplay_all_scenario}
                    >
                      Squad
                    </label>
                  </Stack>

                  {/* toggle 2 for squad lock */}
                  <Stack direction="row">
                    {user?.display_locked_scenario ? (
                      <Icons.toggleRight
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={handle_Locked_scenario}
                      />
                    ) : (
                      <Icons.toggleLeft
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={handle_Locked_scenario}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handle_Locked_scenario}
                    >
                      Squad Locked
                    </label>
                  </Stack>
                </Box>

                {/* third box */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    // border: "2px solid yellow",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {/* toggle for corporate display or not */}
                  <Stack direction="row">
                    {user?.display_all_corporate ? (
                      <Icons.toggleRight
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={handleDisplay_all_corporate}
                      />
                    ) : (
                      <Icons.toggleLeft
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={handleDisplay_all_corporate}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleDisplay_all_corporate}
                    >
                      Corporate
                    </label>
                  </Stack>

                  {/* toggle for corporate locked or not */}
                  <Stack direction="row">
                    {user?.display_locked_corporate ? (
                      <Icons.toggleRight
                        style={{
                          fontSize: "24px",
                          color: "#00FFFF",
                          cursor: "pointer",
                        }}
                        onClick={handle_Locked_corporate}
                      />
                    ) : (
                      <Icons.toggleLeft
                        style={{
                          fontSize: "24px",
                          color: "#6F727A",
                          cursor: "pointer",
                        }}
                        onClick={handle_Locked_corporate}
                      />
                    )}
                    <label
                      htmlFor="vehicle1"
                      style={{
                        fontSize: "14px",
                        color: "#6F727A",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handle_Locked_corporate}
                    >
                      Corporate Locked
                    </label>
                  </Stack>
                </Box>
              </Box>


            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default AddOrUpdateUser;
