import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { BiUpload } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import { BiLinkExternal } from "react-icons/bi";
import Dialog from "@mui/material/Dialog";
import {
  getConsoleVersion2,
  milestoneHintVersion2,
  milestoneApprovedVersion2,
  milestoneSubmitVersion2,
  milestoneUnApprovedVersion2,
} from "../../../APIConfig/version2Scenario";
import { toast } from "react-toastify";
import { useState } from "react";
import light_bulb from "../../../components/assests/light_bulb.svg";
import fuse_bulb from "../../../components/assests/fuse bulb.svg";
import hove_bulb from "../../../components/assests/State=Hover.svg";
import default_bulb from "../../../components/assests/State=Default.svg";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import {
  Backdrop,
  CircularProgress,
  ListItemButton,
  Slide,
} from "@mui/material";
import { BiXCircle } from "react-icons/bi";
import {
  AppBar,
  IconButton,
  List,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { EventRepeat } from "@mui/icons-material";

export default function Milestone({
  item,
  scenario_id,
  reload,
  setReload,
  load,
}) {
  const { loading, userss, error } = useSelector((state) => state.user);
  const { userId } = useParams();
  const role = userss.user_role;
  

  const [isHovered, setIsHovered] = useState(false);
  const [hintUsedMap, setHintUsedMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // console.log("item is here", item, scenario_id);
  // const [activeStep, setActiveStep] = React.useState(0);

  // const handleMilestone = (milestone_id, item) => {};

  // const handleAchieved = async (milestone_id) => {
  //   // handleMilestone(milestone_id, item);
  //   const value = await milestoneSubmitVersion2(scenario_id, milestone_id);
  //   if (value) {
  //     setReload(!reload);
  //   }
  // };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleAchieved = async (milestone_id) => {
    // console.log("File is here", selectedFile);
    try {
      console.log("selected file", selectedFile);
      const value = await milestoneSubmitVersion2(
        scenario_id,
        milestone_id,
        selectedFile[`${milestone_id}`]
      );
      if (value) {
        setReload(!reload);
      }
    } catch (error) {
      console.error("Error submitting milestone:", error);
      // Handle errors appropriately
      setReload(!reload); // You might want to remove this line if you handle errors differently
    }
  };

  const handleNextApproved = async (milestone_id) => {
    console.log("approved", milestone_id, scenario_id);
    const value = await milestoneApprovedVersion2(
      scenario_id,
      milestone_id,
      userId
    );
    if (value) {
      setReload(!reload);
    }
    // if (value) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleunApproved = async (milestone_id) => {
    try {
      const value = await milestoneUnApprovedVersion2(
        scenario_id,
        milestone_id,
        userId
      );
      if (value) {
        setReload(!reload);
      }
    } catch (error) {
      console.error("Error unapproved submitting milestone:", error);
    }
  };

  const handleHintUsed = async (milestoneId) => {
    try {
      const hint = await milestoneHintVersion2(scenario_id, milestoneId);
     
      const hintData = hint?.data?.hint;
      setHintUsedMap((prevMap) => ({
        ...prevMap,
        [milestoneId]: hintData,
      }));
      setReload(!reload);
    } catch (error) {
      console.error("Error fetching hint:", error);
      toast.error("Failed to fetch hint");
      setReload(!reload);
    }
  };

  const [screenshotUploaded, setScreenshotUploaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  // Other state variables and functions...

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        // 5 MB limit
        setSelectedFile({ [name]: file });
        setErrorMessage("");
        console.log("selected file", selectedFile[name]);
      } else {
        setSelectedFile({});
        setErrorMessage("*Image size more than 5 MB ");
      }
    }
  };

  const handleRemoveFile = (keyToRemove) => {
    // Create a copy of the object
    console.log("key", keyToRemove);
    const newObj = { ...selectedFile };
    // Remove the key from the copied object
    delete newObj[keyToRemove];
    // Update the state with the new object
    setSelectedFile(newObj);
    setErrorMessage("");
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [open, setOpen] = React.useState(false);
  const [screenshot_url, setScreenshotUrl] = useState("");

  React.useEffect(() => {}, [selectedFile]);

  const handleClickOpen = (sceernshot_url_data) => {
    setScreenshotUrl(sceernshot_url_data); // Set the screenshot URL
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // React.useEffect(() => {
  //   console.log(isloading, "isloadingisloadingisloadingisloading");
  // }, [isloading]);

  // React.useEffect(() => {
  //   console.log(newItem, "newItemnewItemnewItemnewItem");
  // }, [newItem]);

  

  return (
    <Stack
      direction="row"
      sx={{
        maxWidth: 400,
        padding: 2,
        height: role === "WHITE TEAM" ? "8vh" : "66vh",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="inherit" />
       
      </Backdrop>
      <Box
        // activeStep={activeStep}
        orientation="vertical"
      >
        {item?.map((step, index) => {
          return (
            <Stack direction="row" gap="8px">
              <Stack alignItems="center">
                <Box
                  width={20}
                  height={20}
                  borderRadius="50%"
                  sx={{
                    backgroundColor: step?.is_achieved
                      ? step?.is_approved
                        ? "#039855"
                        : "#F79D28"
                      : "grey",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    p: 2,
                    color: "white",
                    border: "0.5px solid white",
                  }}
                >
                  {step?.is_achieved
                    ? step?.is_approved
                      ? "✓"
                      : "!"
                    : index + 1}
                </Box>
                <Box
                  border="1px solid white"
                  height="100%"
                  sx={{ display: item.length - 1 === index && "none" }}
                />
              </Stack>

              <Box key={step.label} sx={{ width: "90%" }}>
                <Box>
                  <Box sx={{ display: "flex", pt: "4px" }}>
                    <Typography
                      sx={{ color: " #9C9EA3 !important", textWrap: "nowrap" }}
                    >
                      {`Task ${index + 1}:`}
                    </Typography>
                    <Typography
                      sx={{
                        color: " #EAEAEB !important",
                        textWrap: "wrap",
                        // border: "1px solid white",
                        maxWidth: "100%",
                        overflowWrap: "anywhere",
                      }}
                    >
                      {`${step.milestone_name}`}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ pb: 4, pt: 1 }}>
                  <Typography>{step.milestone_description}</Typography>

                  <Box sx={{ mb: 2 }}>
                    {role === "WHITE TEAM" ? (
                      <Stack
                        gap={1}
                        sx={{
                          display: "flex",
                          pr: "20px",
                          direction: "coloumn",
                        }}
                      >
                        <Button
                          onClick={() => handleClickOpen(step.screenshot_url)} // Pass screenshot URL to handleClickOpen
                          style={{
                            marginLeft: "-30px",
                            color: "#00A3A3",
                            padding: "8px 55px 4px 44px",
                            textWrap: "nowrap",
                            display: step?.is_achieved
                              ? step?.is_approved
                                ? "none"
                                : "flex"
                              : "none",
                            mt: 1,
                          }}
                        >
                          <BiLinkExternal
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#00A3A3",
                            }}
                          />
                          Preview Screenshot
                        </Button>
                        <Dialog fullScreen open={open} onClose={handleClose}>
                          <AppBar sx={{ position: "relative" }}>
                            <Toolbar
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "white",
                              }}
                            >
                              <IconButton
                                edge="end"
                                onClick={handleClose}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Toolbar>
                          </AppBar>
                          {/* Render the screenshot in the dialog */}
                          <img
                            src={screenshot_url}
                            alt="Screenshot will be previewed here"
                          />
                        </Dialog>

                        <Stack direction="row" gap={1}>
                          {" "}
                          {/* Added direction="row" */}
                          {/* First Button */}
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleNextApproved(step?.milestone_id)
                            }
                            // disabled={!step.is_achieved}
                            sx={{
                              textWrap: "nowrap",
                              display: step?.is_achieved
                                ? step?.is_approved
                                  ? "none"
                                  : "flex"
                                : "none",
                              mt: 1,
                            }}
                          >
                            Approved
                          </Button>
                          {/* Second Button */}
                          <Button
                            variant="contained"
                            onClick={() => handleunApproved(step?.milestone_id)}
                            // disabled={!step.is_achieved}
                            sx={{
                              textWrap: "nowrap",
                              display: step?.is_achieved
                                ? step?.is_approved
                                  ? "none"
                                  : "flex"
                                : "none",
                              justifyContent: "center",
                              mt: 1,
                            }}
                          >
                            Un-Approved
                          </Button>
                        </Stack>
                      </Stack>
                    ) : (
                      <Box
                        sx={{
                          display: step?.is_achieved
                            ? step?.is_approved
                              ? "none"
                              : "none"
                            : "flex",
                          // border: "1px solid white",
                          mt: 1,
                        }}
                      >
                        <Stack sx={{ display: "flex" }}>
                          <>
                            {selectedFile[step?.milestone_id] ? (
                              <div
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    width: "176px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {selectedFile[step?.milestone_id].name}
                                </span>
                                <Button
                                  onClick={() =>
                                    handleRemoveFile(step?.milestone_id)
                                  }
                                  style={{ marginLeft: "10px" }}
                                >
                                  <BiXCircle />
                                </Button>
                              </div>
                            ) : (
                              <Button
                                component="label"
                                startIcon={<BiUpload />}
                                style={{ fontSize: "small" }}
                              >
                                Upload Screenshot (Max 5 MB)
                                <input
                                  type="file"
                                  name={step?.milestone_id}
                                  accept="image/jpeg, image/png"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                              </Button>
                            )}
                            {errorMessage && (
                              <p style={{ color: "red" }}>{errorMessage}</p>
                            )}
                          </>

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Button
                              variant="contained"
                              onClick={() => handleAchieved(step?.milestone_id)}
                              sx={{
                                width: "150px",
                                height: "42",
                                textWrap: "nowrap",
                                "&.Mui-disabled": {
                                  // Define styles for the disabled state
                                  backgroundColor: "initial", // Reset the background color to its initial value
                                  color: "inherit", // Use the default text color
                                },
                              }}
                              disabled={!selectedFile[step?.milestone_id]} // Disable the button if no screenshot is uploaded
                            >
                              Achive Milestone
                            </Button>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "8px",
                                // backgroundColor: "#13141B",

                                width: hintUsedMap[step?.milestone_id]
                                  ? "80px"
                                  : isHovered === step?.milestone_id &&
                                    !step?.hint_used &&
                                    !hintUsedMap[step?.milestone_id]
                                  ? "50px"
                                  : "45px",
                                height: "58px",
                              }}
                              onMouseEnter={() =>
                                setIsHovered(step?.milestone_id)
                              }
                              onMouseLeave={() => setIsHovered(null)}
                            >
                              <img
                                src={
                                  step?.hint_used ||
                                  hintUsedMap[step?.milestone_id]
                                    ? fuse_bulb
                                    : isHovered === step?.milestone_id &&
                                      !hintUsedMap[step?.milestone_id]
                                    ? hove_bulb
                                    : default_bulb
                                }
                                alt="Light Bulb"
                                style={{
                                  width: "100%",
                                  cursor:
                                    step?.hint_used ||
                                    hintUsedMap[step?.milestone_id]
                                      ? "not-allowed"
                                      : "pointer", // Change cursor style based on the availability of hint
                                }}
                                onClick={() =>
                                  step?.hint_used ||
                                  hintUsedMap[step?.milestone_id]
                                    ? null
                                    : handleHintUsed(step?.milestone_id)
                                }
                              />
                            </Box>
                          </div>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                  {console.log(step, "testt")}
                  {step?.hint_string && !step?.is_achieved && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "6px",
                        pr: "38px",
                      }}
                    >
                      {/* to be correct */}
                      <Box>Hint:</Box>
                      <Typography>
                        {step?.hint_string || hintUsedMap[step?.milestone_id]}
                      </Typography>
                    </Box>
                    
                  )}
                </Box>
              </Box>
            </Stack>
          );
        })}
      </Box>
    </Stack>
  );
}
