import * as React from "react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import "./index.css";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import Form from "./Form";
import ListItem from "./ListItem";
import HTMLRenderer from "../../../components/HtmlRendering";
import NetworkGraph from "../../../components/NetworkGraph";
import WinningWall from "../../../components/winningWall/WinningWall";
import BreadCrumbs from "../../../components/navbar/BreadCrumb";
import LoaderImg from "../../../components/ui/loader";
import ResponsiveDateTimePickers from "./ScheduleDateTimePickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getWinningWallDataCorporate,
  scenarioDetailsVersion2,
  scenarioStartGameVersion2,
} from "../../../APIConfig/version2Scenario";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import pdfFileIcon from "../../../components/assests/file-pdf.svg";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Practice from "./practice";
import { Icons } from "../../../components/icons";
import ErrorNavigator from "../../../ErrorHandler/errorNavigator";

const ScenarioDetailsVersion2 = () => {
  const [tabValue, setTabValue] = useState(0);
  const [value, setValue] = React.useState("1");
  const [loading, setLoading] = React.useState(true);
  const [topology, setTopology] = React.useState({});
  const [isActive, setIsActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [diagram, setDiagram] = React.useState(false);
  const [mail, setMail] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [DTvalue, setDTvalue] = React.useState(new Date());
  const [data, setData] = React.useState([]);
  const [winningWallData, setWinningWallData] = useState([]);
  const [scenarioMail, setScenarioMail] = React.useState([]);
  const [machine, setMachine] = React.useState([]);
  const [hide, setHide] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location?.pathname;
  const token = localStorage.getItem("access_token");
  const user = jwtDecode(token);
  const { userss } = useSelector((state) => state.user);
  const IdUser = user?.user_id;

  // Split the URL by slashes and get the last element
  const parts = currentPath.split("/");
  const lastValue = parts[parts.length - 1];
  const [calenderToggle, setCalenderToggle] = useState(false);
  const [joinToggle, setJoinToggle] = useState(false);

  const formatDate = (date) => {
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
    return formattedTime;
  };

  const [selectedDate, setSelectedDate] = useState({
    date: formatDate(new Date()),
    time: formatTime(new Date()),
  });

  const handleSelectedDate = (date) => {
    setSelectedDate({
      date: formatDate(date),
      time: formatTime(date),
    });
  };

  const handleCalenderToggle = (toggle) => {
    setCalenderToggle(!toggle);
  };

  React.useEffect(() => {
    const getApi = async () => {
      setIsActive(true);
      try {
        const response = await scenarioDetailsVersion2(lastValue);
        setData(response?.data);
        setMachine(response?.data?.machine_names);
      } catch (error) {
        navigate("/error/page_not_found");
      } finally {
        setIsActive(false);
      }

      // const response2 = await topologyDetails(response?.data?.scenario_id);
      // setTopology(response2?.data);
    };

    const getWinningWallData = async () => {
      try {
        const response = await getWinningWallDataCorporate(lastValue);
        setWinningWallData(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getApi();
    getWinningWallData();
  }, []);

  // for handle schedule now button and open its dialog box
  const handleClickOpen = () => {
    setOpen(true);
  };
  // for handle join now button and open its dialog box
  const handleClickJoin = () => {
    setJoinToggle(true);
  };

  const handleClose = () => {
    // handledialog box schedule
    setOpen(false);
    // handle dialog box for join now
    setCalenderToggle(false);
    setJoinToggle(false);
  };
  const handleGame = () => {
    navigate("/activegame");
  };

  const _handleSubmit = (data) => {
    //console.log("here i am");
    const { name, team } = data;
    if (name === "") {
      toast.error("E-mail is required");
      return;
    }
    if (team === "") {
      toast.error("Please select the machine");
      return;
    }

    const newMail = { name: [name], player_instance: [team] };
    setMail((prevMail) => [newMail, ...prevMail]);
    const filteredResult = machine.filter((name) => name != team);
    setMachine(filteredResult);
  };
  const DTonChange = (stat) => {
    setDTvalue(stat);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const removemail = (index, f) => {
    //console.log("index", f);
    setMail((prevMail) => {
      const newMail = [...prevMail];
      newMail.splice(index, 1);
      return newMail;
    });
    setMachine((prevItems) => [...prevItems, f.player_instance[0]]);
  };

  // start game
  const joinHandle = async () => {
    setIsActive(true);
    try {
      setJoinToggle(false);

      let arr = [];
      for (let i = 0; i < mail.length; i++) {
        let obj = {
          participant_email: mail[i].name[0],
          participant_machine: mail[i].player_instance[0],
        };
        arr.push(obj);
      }

      const response = await scenarioStartGameVersion2(lastValue, arr, IdUser);
      if (response) {
        setIsActive(false);
        toast.success(response?.data?.message);
      }
    } catch (error) {
      const obj = error.response.data.errors;

      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
            i.slice(1).replace(/_/g, " ") +
            " - " +
            obj[i]
        );
      }
      setIsActive(false);
    }
  };

  const scenariosData = [
    {
      id: data?.id,
      category: data?.category_name,
      points: data?.points,
      time: data?.scenario_time,
      severity: data?.severity,
      img: data?.thumbnail_url,
      scenarioName: data?.name,
      description: data?.description,
      cpu:
        data?.scenario_hardware_details?.vcpu || data?.hardware_details?.vcpu,
      hdSpace:
        data?.scenario_hardware_details?.disk_size ||
        data?.hardware_details?.disk_size,
      ram: data?.scenario_hardware_details?.RAM || data?.hardware_details?.RAM,
      objectives: data?.objective,
      tool_technology: data?.objective,
      prerequisites: data?.prerequisite,
      machine:
        data?.scenario_hardware_details?.vm_count ||
        data?.hardware_details?.vm_count,
      type: data?.type,
    },
  ];

  const breadcrumbs = [
    { name: "Dashboard", link: "/" },
    {
      name: "Category",
      link: "/Corporate/category",
    },
    { name: "Detail Page", link: `${location.pathname}` },
  ];

  const handleTabChangeForPDF = (newValue) => {
    setTabValue(newValue);
    console.error(newValue);
  };

  const capitalizeTeamName = (team) => {
    return team.replace(/_/g, " ");
  };

  const teams = Object.keys(data?.files_data || {}).filter(
    (team) => data?.files_data[team]?.length > 0
  );
  const selectedTeam = teams[tabValue] || "";
  const files = data?.files_data?.[selectedTeam] || [];

  useEffect(() => {}, []);

  return (
    <Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isActive}
      >
        <LoaderImg />
      </Backdrop>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <Stack spacing={2} display="flex" width="100%" justifyContent="center">
        {/* container */}
        <Stack
          sx={{ justifyContent: "center", width: "100%", alignItems: "center" }}
        >
          {/* box */}
          <Stack width="90%" justifyContent="center" gap={2}>
            {/* Heading */}
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h1" fontSize="40px">
                Squad
              </Typography>
            </Stack>
            {/* scenario Details */}
            <Stack>
              <Box>
                {scenariosData?.map((data, index) => {
                  return (
                    <Stack
                      key={index}
                      sx={{
                        backgroundColor: "background.secondary",
                        //   border: "1px solid #12464C",
                        marginTop: 2,
                        borderRadius: "8px",
                      }}
                    >
                      <Stack direction="row" gap={4} p={3}>
                        <img
                          src={data.img}
                          width="160px"
                          height="211px"
                          alt="no img"
                          style={{ borderRadius: "16px" }}
                        />
                        <Stack sx={{ display: "flex", width: "100%" }}>
                          <Stack
                            mb={1}
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Chip
                              label={data.category}
                              sx={{
                                width: "10%",
                                minWidth: "250px",
                                backgroundColor: "#393939",
                                height: "30px",
                              }}
                            />
                            <Stack direction="row" alignItems="center" gap={2}>
                              {/* <ExitToAppIcon style={{ color: "#ffffff" }} /> */}
                              {/* <AccountTreeOutlinedIcon style={{ color: "#ffffff" }} /> */}
                              <Stack>
                                <Box sx={{ display: "flex", gap: "8px" }}>
                                  {/* <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{width: "150px", height: "50px"}}
                                    onClick={handleClickOpen}
                                    //   onClick={() => navigate("/createscenario")}
                                  >
                                    Schedule
                                  </Button> */}
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                      width: "150px",
                                      height: "50px",
                                      display:
                                        userss?.user_role !== "WHITE TEAM" &&
                                        "none",
                                    }}
                                    // disabled={(userss?.user_role)==='WHITE TEAM' ? false:true}

                                    onClick={handleClickJoin}
                                  >
                                    Join Now
                                  </Button>
                                </Box>

                                {/* Schedule Now Dialog box */}
                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  maxWidth="xl"
                                  scroll="body"
                                >
                                  <Box
                                    p={2}
                                    sx={{
                                      backgroundColor: "background.secondary",
                                      maxWidth: "900px",
                                      display: "flex",
                                      gap: "10px",
                                      // justifyContent:''
                                    }}
                                  >
                                    {/* <Stack width="40%">
                                <DateTimePicker
                                  onChange={DTonChange}
                                  value={DTvalue}
                                />
                              </Stack> */}
                                    {/* <Calendar /> */}
                                    {!calenderToggle && (
                                      <Box
                                        sx={{
                                          width: "100%",
                                        }}
                                      >
                                        <ResponsiveDateTimePickers
                                          handleClose={handleClose}
                                          calenderToggle={calenderToggle}
                                          handleCalenderToggle={
                                            handleCalenderToggle
                                          }
                                          handleSelectedDate={
                                            handleSelectedDate
                                          }
                                        />
                                      </Box>
                                    )}

                                    {calenderToggle && (
                                      <Stack>
                                        <Typography variant="h2">
                                          Invite your friends
                                        </Typography>
                                        <Typography
                                          variant="body1"
                                          sx={{ color: "#9C9EA3 !important" }}
                                          mb={2}
                                        >
                                          Enter your friends' registered email
                                          IDs and assign roles to them for the
                                          scenario. Click "Join with Members" to
                                          send the invitations and start the
                                          game.
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: "64px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: "2px",
                                            }}
                                          >
                                            <CalendarMonthIcon />
                                            {selectedDate.date}{" "}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              gap: "2px",
                                            }}
                                          >
                                            <AccessTimeIcon />
                                            {selectedDate.time}
                                          </Typography>
                                          <Button
                                            variant="text"
                                            onClick={() =>
                                              handleCalenderToggle(
                                                calenderToggle
                                              )
                                            }
                                          >
                                            Edit
                                          </Button>
                                        </Box>

                                        <Stack>
                                          <Stack gap={2}>
                                            <Form
                                              onSubmit={_handleSubmit}
                                              value={inputValue}
                                              machine={machine}
                                              setMachine={setMachine}
                                              onChange={(e) =>
                                                setInputValue(e.target.value)
                                              }
                                            />
                                            <Stack
                                              display="flex"
                                              flexDirection="column"
                                              width="100%"
                                              gap={2}
                                              style={{
                                                maxHeight: "150px",
                                                overflow: "auto",
                                                color: "white",
                                              }}
                                            >
                                              {mail.map((f, index) => (
                                                <Stack key={index}>
                                                  <ListItem
                                                    sx={{ color: "white" }}
                                                    mail={f}
                                                    remove={() =>
                                                      removemail(index, f)
                                                    }
                                                  />
                                                </Stack>
                                              ))}
                                            </Stack>
                                          </Stack>
                                          <Stack direction="row" gap={2} mt={2}>
                                            {/* <Button
                                              onClick={() => {
                                                toast.success("schedule done");
                                                setOpen(false);
                                                setCalenderToggle(false)
                                              }}
                                              variant="contained"
                                              color="secondary"
                                            >
                                              Schedule
                                            </Button> */}

                                            {/* <Button
                                        onClick={handleClose}
                                        variant="outlined"
                                        color="secondary"
                                      >
                                        Individual
                                      </Button> */}
                                          </Stack>
                                          <Stack mt={2}>
                                            <Typography
                                              component="a"
                                              href="/createscenarios"
                                              className="createScenarios"
                                              sx={{
                                                textDecoration: "none",
                                                variant: "body2",
                                              }}
                                            >
                                              Want to{" "}
                                              <span
                                                style={{ color: "#b46228" }}
                                              >
                                                Create Squad
                                              </span>{" "}
                                              ?
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                      </Stack>
                                    )}
                                  </Box>
                                </Dialog>

                                {/* Join Dialog Box */}
                                <Dialog
                                  open={joinToggle}
                                  onClose={handleClose}
                                  maxWidth="xl"
                                  scroll="body"
                                >
                                  <Box
                                    p={2}
                                    sx={{
                                      backgroundColor: "background.secondary",
                                      maxWidth: "900px",
                                      display: "flex",
                                      gap: "10px",
                                      // justifyContent:''
                                    }}
                                  >
                                    {/* <Stack width="40%">
                                <DateTimePicker
                                  onChange={DTonChange}
                                  value={DTvalue}
                                />
                              </Stack> */}

                                    <Stack>
                                      <Typography variant="h2">
                                        Invite your friends
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        sx={{ color: "#9C9EA3 !important" }}
                                        mb={2}
                                      >
                                        Enter your friends' registered email IDs
                                        and assign roles to them for the
                                        scenario. Click "Join with Members" to
                                        send the invitations and start the game.
                                      </Typography>

                                      <Stack>
                                        <Stack gap={2}>
                                          <Form
                                            onSubmit={_handleSubmit}
                                            value={inputValue}
                                            machine={machine}
                                            setMachine={setMachine}
                                            onChange={(e) =>
                                              setInputValue(e.target.value)
                                            }
                                          />
                                          <Stack
                                            display="flex"
                                            flexDirection="column"
                                            width="100%"
                                            gap={2}
                                            style={{
                                              maxHeight: "150px",
                                              overflow: "auto",
                                              color: "white",
                                            }}
                                          >
                                            {mail.map((f, index) => (
                                              <Stack key={index}>
                                                <ListItem
                                                  sx={{ color: "white" }}
                                                  mail={f}
                                                  remove={() =>
                                                    removemail(index, f)
                                                  }
                                                />
                                              </Stack>
                                            ))}
                                          </Stack>
                                        </Stack>
                                        <Stack direction="row" gap={2} mt={2}>
                                          <Button
                                            onClick={joinHandle}
                                            variant="contained"
                                            color="secondary"
                                          >
                                            Join Now
                                          </Button>

                                          {/* <Button
                                        onClick={handleClose}
                                        variant="outlined"
                                        color="secondary"
                                      >
                                        Individual
                                      </Button> */}
                                        </Stack>
                                        <Stack mt={2}>
                                          <Typography
                                            component="a"
                                            href="/createscenarios"
                                            className="createScenarios"
                                            sx={{
                                              textDecoration: "none",
                                              variant: "body2",
                                            }}
                                          >
                                            Want to{" "}
                                            <span style={{ color: "#b46228" }}>
                                              Create Scenarios
                                            </span>{" "}
                                            ?
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </Stack>
                                  </Box>
                                </Dialog>
                              </Stack>
                            </Stack>
                          </Stack>
                          <Stack direction="row" gap={2} alignItems="center">
                            <Typography variant="body1" className="cardPoints">
                              <StarBorderPurple500Icon sx={{ mb: -0.5 }} />
                              {data.points} Points
                            </Typography>
                            {/* <Typography variant="body1" className="cardPoints">
                              <TimerOutlinedIcon sx={{ mb: -0.5 }} />
                              {data.time} hour
                            </Typography> */}
                            <Typography variant="body1" className="cardPoints">
                              <InsertChartOutlinedIcon sx={{ mb: -0.5 }} />
                              {data?.severity}
                            </Typography>

                            {data?.type && (
                              <Typography
                                variant="body1"
                                className="cardPoints"
                              >
                                <WorkspacePremiumOutlinedIcon
                                  sx={{ mb: -0.5 }}
                                />
                                {data?.type}
                              </Typography>
                            )}
                          </Stack>
                          <Stack mb={2}>
                            <Typography variant="h1">
                              {data?.scenarioName}
                            </Typography>
                            {hide ? (
                              <Stack sx={{ width: "100%" }}>
                                <Typography>
                                  {data?.description
                                    ?.replace(/(<([^>]+)>)/gi, "")
                                    .substring(0, 250)}
                                </Typography>
                                <Typography
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "#00ffff",
                                    width: "fit-content",
                                  }}
                                  onClick={() => setHide(!hide)}
                                >
                                  Read more
                                </Typography>
                              </Stack>
                            ) : (
                              <Stack sx={{ width: "100%" }}>
                                <HTMLRenderer htmlContent={data?.description} />
                                <Typography
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "#00ffff",
                                    width: "fit-content",
                                  }}
                                  onClick={() => setHide(!hide)}
                                >
                                  Read less
                                </Typography>
                              </Stack>
                            )}
                          </Stack>
                          {/* mapping diagram */}
                          {/* <Stack direction="row" sx={{ my: 2, gap: 3 }}>
                            <Button
                              startIcon={<AccountTreeIcon />}
                              variant="contained"
                              sx={{ height: "50px" }}
                              size="large"
                            >
                              Mitre Mapping
                            </Button>
                            <Button
                              startIcon={<AccountTreeIcon />}
                              variant="contained"
                              sx={{ height: "50px" }}
                              size="large"
                              onClick={() => setDiagram(!diagram)}
                            >
                              Network Topology
                            </Button>
                          </Stack> */}
                          <Stack
                            style={{
                              display: diagram ? "block" : "none",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              position: "relative",
                            }}
                          >
                            <NetworkGraph
                              id={data?.id}
                              topology={topology}
                              setDiagram={setDiagram}
                              diagram={diagram}
                            />
                          </Stack>
                          {/* scenario config. */}
                          <Stack
                            direction="row"
                            // justifyContent="space-between"
                            // width="20%"
                            alignItems="start"
                            width="100%"
                            gap={5}
                          >
                            <Stack
                              alignItems="center"
                              // border={"1px solid white"}
                            >
                              <Typography
                                variant="body2"
                                className="configuration"
                              >
                                CPU
                              </Typography>
                              <Typography variant="body1">
                                {data.cpu}
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                variant="body2"
                                className="configuration"
                              >
                                HD Space
                              </Typography>
                              <Typography variant="body1">
                                {data.hdSpace}
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                variant="body2"
                                className="configuration"
                              >
                                RAM
                              </Typography>
                              <Typography variant="body1">
                                {data.ram}
                              </Typography>
                            </Stack>
                            <Stack alignItems="center">
                              <Typography
                                variant="body2"
                                className="configuration"
                              >
                                Machine
                              </Typography>
                              <Typography variant="body1">
                                {data.machine}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        {/* network diagram */}
                      </Stack>
                    </Stack>
                  );
                })}
              </Box>
            </Stack>

            <Stack direction="row" gap={2}>
              {/* scenario tabs details and practices details */}
              <Stack direction="row" gap={2}>
                <Stack
                  sx={{
                    backgroundColor: "background.secondary",
                    p: 2,
                    borderRadius: "16px",
                    width: "100%",
                  }}
                >
                  <TabContext value={value}>
                    <Box>
                      <TabList
                        onChange={handleTabChange}
                        textColor="#ffffff"
                        TabIndicatorProps={{
                          sx: { backgroundColor: "#b46228" },
                        }}
                      >
                        {/* <Tab label="Objectives" value="" /> */}
                        <Tab label="objective" value="1" />
                        <Tab label="prerequisite" value="2" />
                      </TabList>
                    </Box>

                    <TabPanel
                    //  value="1"
                    >
                      <Stack
                        style={{
                          backgroundColor: "custom.main",
                          borderRadius: "16px",
                          justifyContent: "space-around",
                          alignItems: "space-around",
                          gap: 54,
                        }}
                      >
                        <Stack>{data.scenario_objectives}</Stack>

                        <Stack gap={2}>
                          {data?.document_urls?.map((item, index) => {
                            return (
                              <Button
                                key={index}
                                variant="outlined"
                                color="secondary"
                                onClick={() => window.open(item, "_blank")}
                              >
                                VIEW DOCUMENT
                              </Button>
                            );
                          })}
                        </Stack>
                      </Stack>
                    </TabPanel>

                    <TabPanel value="1">
                      <Stack
                        sx={{
                          backgroundColor: "custom.main",
                          borderRadius: "16px",
                          height: "500px",
                          overflow: "scroll",
                          px: 4,
                        }}
                      >
                        <HTMLRenderer htmlContent={data?.objective} />
                      </Stack>
                    </TabPanel>
                    <TabPanel value="2">
                      <Stack
                        sx={{
                          backgroundColor: "custom.main",
                          borderRadius: "16px",
                          height: "500px",
                          overflow: "scroll",
                          px: 4,
                        }}
                      >
                        <HTMLRenderer htmlContent={data?.prerequisite} />
                      </Stack>
                    </TabPanel>
                  </TabContext>
                </Stack>
                {/* practice details */}
                <Stack
                  sx={{
                    backgroundColor: "background.secondary",
                    p: 2,
                    borderRadius: "16px",
                    width: data?.scenario_documents && "50%",
                    display: !data?.scenario_documents && "none",
                  }}
                >
                  <Practice item={data?.scenario_documents} />
                </Stack>
              </Stack>

              {teams.length > 0 && (
                <Stack
                  sx={{
                    backgroundColor: "background.secondary",
                    p: 3,
                    borderRadius: 1.5,
                    width: "100%",
                  }}
                >
                  <Typography variant="h2">Walkthroughs</Typography>
                  <Stack>
                    <Tabs
                      value={tabValue}
                      textColor="secondary"
                      indicatorColor="secondary"
                      onChange={(event, newValue) =>
                        handleTabChangeForPDF(newValue)
                      }
                    >
                      {teams.map(
                        (team, index) =>
                          data?.files_data[team].length > 0 && (
                            <Tab
                              key={index}
                              label={capitalizeTeamName(team)}
                              sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#EAEAEB",
                                textTransform: "capitalize",
                              }}
                            />
                          )
                      )}
                    </Tabs>

                    <Divider sx={{ border: "1px solid rgba(83, 86, 96, 1)" }} />

                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      pt={2.5}
                      spacing={3}
                    >
                      {files.map((file, index) => (
                        <Stack
                          key={index}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={1}
                          sx={{
                            width: "90px",
                            height: "112px",
                            borderRadius: "12px",
                            padding: "12px 8px",
                            backgroundColor: "rgba(145, 158, 171, 0.12)",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(file, "_blank")}
                        >
                          <img
                            src={pdfFileIcon}
                            alt="pdf_icon"
                            style={{ height: "60px", width: "60px" }}
                          />
                          <Typography variant="h5" color="#919EAB !important">
                            PDF {index + 1}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
            {winningWallData.length > 0 && (
              <WinningWall
                template="winningWall"
                data={winningWallData}
                header="Achievers"
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ScenarioDetailsVersion2;
