
import { toast } from "react-toastify";

const ErrorHandler = (error, navigate) => {
  if (error.response) {
    console.log('here')
    const obj = error.response.data.errors;
    console.log('obj',obj)
if(!Array.isArray(error.response.data.errors.non_field_errors)){
  toast.error(error.response.data.errors)

  return
}
console.log('obj-2',obj)
    for (let i in obj) {
      let str = ''
      if (i != "non_field_errors") {
        str = str + i.charAt(0).toUpperCase() + i.slice(1).replace(/_/g, " ") + " "

      }
      str = str + obj[i]
      toast.error(str);
    }

  }
  else if (error.errors) {
    // navigate(`/error/server_error`)
    toast.error(error.errors)
  }
  else {
    // navigate('/error/server_error')
  }
}



export default ErrorHandler;