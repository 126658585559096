import {
  BiAnalyse,
  BiArrowBack,
  BiAtom,
  BiBarChartAlt2,
  BiBookAlt,
  BiCalendarAlt,
  BiCheckbox,
  BiCheckCircle,
  BiCheckSquare,
  BiChevronDown,
  BiChevronsLeft,
  BiChevronsRight,
  BiCog,
  BiCommentEdit,
  BiEnvelope,
  BiGame,
  BiGroup,
  BiHide,
  BiHive,
  BiHomeAlt2,
  BiKey,
  BiLinkExternal,
  BiLogOut,
  BiPhone,
  BiRevision,
  BiRightArrowCircle,
  BiSearch,
  BiShield,
  BiShow,
  BiSitemap,
  BiSolidEditAlt,
  BiSolidFilePdf,
  BiSolidUserCheck,
  BiTime,
  BiToggleLeft,
  BiToggleRight,
  BiTrashAlt,
  BiUser,
  BiXCircle,
  BiObjectsHorizontalLeft,
  BiUserPin,
  BiBuildings,
  BiMap,
  BiDownload,
  BiLock,
  BiDumbbell
} from "react-icons/bi";

export const Icons = {
  CTF: BiGame,
  RLMS: BiBookAlt,
  calendar: BiCalendarAlt,
  categories: BiObjectsHorizontalLeft,
  checkboxFalse: BiCheckbox,
  checkboxTrue: BiCheckSquare,
  checkCircle: BiCheckCircle,
  clock: BiTime,
  crossCircle: BiXCircle,
  corporate: BiBuildings,
  dashboard: BiHomeAlt2,
  delete: BiTrashAlt,
  downArrow: BiChevronDown,
  download: BiDownload,
  edit: BiSolidEditAlt,
  envelope: BiEnvelope,
  externalLink: BiLinkExternal,
  hide: BiHide,
  leftArrow: BiArrowBack,
  doubleLeftArrow: BiChevronsLeft,
  doubleRightArrow: BiChevronsRight,
  key: BiKey,
  logout: BiLogOut,
  location: BiMap,
  lock: BiLock,
  phone: BiPhone,
  pdf: BiSolidFilePdf,
  refresh: BiRevision,
  rightArrowCircle: BiRightArrowCircle,
  scenario: BiAtom,
  search: BiSearch,
  settings: BiCog,
  shield: BiShield,
  show: BiShow,
  toggleLeft: BiToggleLeft,
  toggleRight: BiToggleRight,
  topology: BiSitemap,
  user: BiUser,
  userCheck: BiSolidUserCheck,
  userPin: BiUserPin,
  users: BiGroup,
  winningWall: BiBarChartAlt2,
  mapping: BiAnalyse,
  BiHive: BiHive,
  update: BiCommentEdit,
  dumbbell:BiDumbbell
};
