import {
  Backdrop,
  Box,
  Button,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography,
} from "@mui/material";
import Wtable from "./Wtable";
import { useNavigate } from "react-router-dom";
import {
  challengesApi,
  dashboardList,
  getCategory,
  MemberWallApi,
  topPerformerApi,
  UserProfileApi,
} from "../../APIConfig/CtfConfig";
import "./index.css";
import { scenariolisting } from "../../APIConfig/scenarioConfig";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ScenarioMachine from "./ScenarioMachine";
import Carsoul from "./Carsoul";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BreadCrumbs from "../../components/navbar/BreadCrumb";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ErrorHandler from "../../ErrorHandler";
import SearchBar from "../../components/ui/SearchBar";
import userGroup from "../../components/assests/user_group.svg";
import { totalUserCount } from "../../APIConfig/userConfig";
import LoaderImg from "../../components/ui/loader";

const useStyles = makeStyles({
  stack: {
    transition: "transform 0.3s", // Add a smooth transition effect

    "&:hover": {
      transform: "scale(1.05)", // Increase the size on hover
    },
  },
  Button: {
    backgroundColor: "#002929",
    transition: "transform 0.3s",
    fontSize: "14px !important", // Add a smooth transition effect
    "&:hover": {
      transform: "scale(1.05)", // Increase the size on hover
      backgroundColor: "#12464C",
    },
  },
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#1c1f28",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "#07A8BE",
  },
}));

const Dashboard = () => {
  const [cat, setCat] = React.useState({});
  const [mem, setMem] = React.useState([]);
  const [memInput, setMemInput] = React.useState("");
  const [scanario, setScanario] = React.useState([]);
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();
  const [member, setMembers] = React.useState({});
  const [topPerformer, setTopPerformer] = React.useState([]);
  const [memberWall, setMemberWall] = React.useState([]);
  const [challenges, setChallenges] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const [isActive, setIsActive] = useState(false);
  const classes = useStyles();

  const [totalUser, setTotalUser] = useState(null);

  function handleClick(event) {
    event.preventDefault();
  }

  React.useEffect(() => {
    const ApiCall = async () => {
      try {
        setIsActive(true);
        const allPromise = await Promise.all([
          scenariolisting(),
          topPerformerApi(),
          challengesApi(),
          dashboardList(),
          getCategory(),
          MemberWallApi(),
          totalUserCount(),
        ]);
        setScanario(allPromise[0]);
        setData(allPromise[0]?.data);
        setTopPerformer(allPromise[1]?.data);
        setChallenges(allPromise[2]?.data);
        setMembers(allPromise[3]?.data);
        setCat(allPromise[4]);
        setMem(allPromise[5]?.data);
        setMemberWall(allPromise[5]?.data);
        setTotalUser(allPromise[6]?.data.total_members);
      } catch (error) {
        ErrorHandler(error, navigate);
      } finally {
        setIsActive(false); // Set isActive to false after the API call is completed
      }
    };
    ApiCall();
  }, []);

  const bar = [
    {
      name: 1,
    },
    {
      name: 1,
    },
    {
      name: 1,
    },
  ];

  const handleSearch = (e) => {
    if (e.target.value == "") {
      setMemberWall(mem);
      setMemInput("");
    } else {
      setMemInput(e.target.value.trimLeft().toLowerCase());
      const filterData = mem.filter((item) =>
        item.user_full_name.toLowerCase().includes(memInput)
      );
      setMemberWall(filterData);
    }
  };
  const breadcrumbs = [{ name: "Dashboard", link: "/" }];

  const handleClickTopPerformer = async (user_id) => {
    const token = localStorage.getItem("access_token");
    const userDetails = await UserProfileApi(user_id);
    //console.log("user details ", userDetails)
    if (userDetails) {
      navigate("/playerProfile", { state: { the: userDetails?.data } });
    }
  };

  useEffect(() => {}, [memberWall, mem]);
  return (
    <Stack>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isActive}
      >
        <LoaderImg />
      </Backdrop>

      <Stack
        style={{
          display: "flex",
          gap: 6,
          marginTop: 6,
          padding: 12,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack sx={{ width: "100%", gap: 2 }}>
          <Stack
            style={{ width: "100%", gap: "24px", height: "100%" }}
            direction="row"
          >
            {/* carousel */}
            <Stack
              style={{ width: "68%", borderRadius: "12px" }}
              data-aos="fade-right"
            >
              <Carsoul challenges={challenges} />
            </Stack>
            {/* profile */}
            <Stack
              style={{
                backgroundColor: "#16181F",
                width: "100%",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: 24,
              }}
              data-aos="fade-left"
            >
              <img src={userGroup} alt="user_group" />

              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" sx={{ fontSize: "28px !important" }}>
                  {totalUser}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#6F727A !important", fontWeight: "700" }}
                >
                  Total user Count
                </Typography>
              </Stack>

              {/*  Badge system*/}

              {/*  <Stack direction='row' gap={2}>*/}
              {/*    <Box component='img' src='https://5.imimg.com/data5/XQ/KP/MY-40305254/kids-toy-500x500.jpg'*/}
              {/*         style={{width: '41.5px', height: '41.5px', borderRadius: "50%"}}></Box>*/}
              {/*    <Typography variant='h2'>Bronze</Typography>*/}
              {/*  </Stack>*/}
              {/*  <Stack gap={1}>*/}
              {/*    <Box component='img' src={award} alt='image' style={{width: '50px', height: '50px'}}/>*/}
              {/*    <BorderLinearProgress variant='determinate' value={50}/>*/}
              {/*    <Stack direction='row' justifyContent='space-between'>*/}
              {/*      {*/}
              {/*        bar.map((item, index) => {*/}
              {/*          return (*/}
              {/*            <Box style={{width: '1px', height: '4px', backgroundColor: '#8B8D92'}} key={index}>*/}

              {/*            </Box>*/}

              {/*          )*/}
              {/*        })*/}
              {/*      }*/}
              {/*    </Stack>*/}
              {/*  </Stack>*/}
              {/*  <Stack direction='row' style={{justifyContent: 'space-between'}}>*/}
              {/*    <Typography>*/}
              {/*      Current:Bronze*/}
              {/*    </Typography>*/}
              {/*    <Typography>*/}
              {/*      Next:Silver*/}
              {/*    </Typography>*/}
              {/*  </Stack>*/}
            </Stack>
          </Stack>

          <Stack
            sx={{
              // minHeight: '688px',
              width: "100%",
              gap: 2,
              borderRadius: "16px",
            }}
            direction="row"
          >
            {/* CTF CATEGORIES DETAIL CARD */}

            <Stack
              width="36%"
              justifyContent="left"
              direction="row"
              data-aos="fade-left"
            >
              <Box
                sx={{
                  bgcolor: "custom.main",
                  width: "100%",
                  padding: 3,
                }}
              >
                <Stack
                  direction="row"
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography variant="h2">Solo Categories</Typography>
                  <Button
                    // sx={{ minWidth: "140px" }}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/categories/ctf_category")}
                    // className={classes.Button}
                    // size="small"
                    // endIcon={<ArrowForwardIcon />}
                  >
                    See All
                  </Button>
                </Stack>
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                    gap: 24,
                    padding: 4,
                    marginTop: 24,
                  }}
                >
                  {/* card information detail for ctf*/}
                  {cat?.data?.length >= 1 ? (
                    cat?.data?.map((item, index) => {
                      if (index < 5) {
                        return (
                          <Stack
                            key={index}
                            style={{
                              width: "100%",
                              justifyContent: "space-between",
                              height: "50px",
                              borderRadius: "12px",
                              padding: "16px 20px",
                              backgroundColor: "#342D3C",
                              cursor: "pointer",
                            }}
                            direction="row"
                            className={classes.stack}
                            onClick={() => {
                              navigate("/categories/ctf_category", {
                                state: {
                                  id: item.ctf_category_id,
                                  index: index,
                                },
                              });
                            }}
                          >
                            <Typography>{item.ctf_category_name}</Typography>
                            <Stack>
                              <KeyboardArrowRightIcon />
                            </Stack>
                          </Stack>
                        );
                      }
                    })
                  ) : (
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>No data!!!</Typography>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Stack>
            {/* Scenarios details */}
            <Stack
              sx={{
                backgroundColor: "custom.main",
                borderRadius: "16px",
                padding: 3,
                justifyContent: "space-between",
              }}
              width="64%"
              // minHeight='688px'
              data-aos="fade-left"
            >
              <Stack>
                <Stack
                  direction="row"
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography variant="h2">Squad</Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/scenarios/scenario_category")}
                    // className={classes.Button}
                    // size="small"
                    // endIcon={<ArrowForwardIcon />}
                  >
                    See All
                  </Button>
                </Stack>
                <Stack width="100%" gap={5} marginTop={4}>
                  {scanario?.data?.length >= 1 ? (
                    scanario?.data?.map((item, index) => {
                      if (index <= 1) {
                        return <ScenarioMachine item={item} key={index} />;
                      }
                    })
                  ) : (
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography>No data!!!</Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* news */}
          {/* {
          news.length > 1 && (
            <Box sx={{backgroundColor: '#16181f', marginTop: 2, borderRadius: '12px', width: '96%',}}>
              <NewsSpotlight data={news}/>
            </Box>
          )
        } */}

          <Stack sx={{ width: "100%", gap: 4 }} direction="row">
            <Box
              sx={{
                backgroundColor: "custom.main",
                width: "70%",
                height: "100%",
                borderRadius: "16px",
                padding: 3,
              }}
              data-aos="fade-up"
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h2">Member Wall</Typography>
                <SearchBar
                  value={memInput}
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </Stack>
              <Wtable mem={memberWall} />
            </Box>

            <Stack
              sx={{
                backgroundColor: "custom.main",
                width: "30%",
                height: "575px",
                overflow: "scroll",
                borderRadius: "16px",
                padding: 3,
              }}
              data-aos="fade-up"
            >
              <Typography variant="h2" style={{ marginTop: "8px" }}>
                Top Performers
              </Typography>
              {topPerformer.length >= 1 ? (
                <Stack sx={{ my: 2 }}>
                  {topPerformer.map((item, index) => {
                    //console.log('item is here', item)
                    return (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        borderTop="2px solid #2A2C35"
                        sx={{ py: "8px", cursor: "pointer" }}
                        key={index}
                        onClick={() => handleClickTopPerformer(item.user_id)}
                      >
                        <Stack
                          sx={{
                            width: "20%",
                            alignItems: "start",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={item.avatar}
                            sx={{
                              borderRadius: "50%",
                              width: "32px",
                              height: "32px",
                              border: "1px solid white",
                            }}
                          />
                        </Stack>
                        <Stack sx={{ width: "53%" }}>
                          <Typography variant="h4">{item.full_name}</Typography>
                          <Typography variant="body2">{item.badge}</Typography>
                        </Stack>
                        <Stack sx={{ width: "33%" }}>
                          <Typography variant="h4">
                            {item.total_score}
                          </Typography>
                          <Typography variant="body2">Total Points</Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              ) : (
                <Stack
                  sx={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Typography variant="h3" style={{ textAlign: "center" }}>
                    Be the first to be on Board!!!
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Dashboard;
