import React from 'react';
import CreateCategories from "../common/createCategories";
import BreadCrumbs from "../../../components/navbar/BreadCrumb";

const CreateScenarioCategories = () => {
  const breadcrumbs = [
    {
      name: 'Dashboard',
      link: "/"
    },
    {
      name: 'Categories',
      link: "/admin/scenario-categories"
    }, {
      name: 'Create Category',
      link: "/admin/create-scenario-categories"
    },
  ];
  return (
    <>
      <BreadCrumbs breadcrumbs={breadcrumbs}/>
      <CreateCategories template='Scenario'/>
    </>
  );
};

export default CreateScenarioCategories;