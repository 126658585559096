import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({text,setText}) => {
  

  // Load the saved text from local storage on component mount
  // useEffect(() => {
  //   const savedText = localStorage.getItem('text');
  //   if (savedText) {
  //     setText(savedText);
  //   }
  // }, []);

  // Save the current text to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('text', text);
  }, [text]);

  const editorStyle = {
    height: '200px', // Set the desired height here
  };
  return (
    <div>
      <ReactQuill value={text} onChange={setText} style={editorStyle}/>
    </div>
  );
};

export default TextEditor;
