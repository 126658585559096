import APIVERSION2 from "../Axios/version2";
import ErrorHandler from "../ErrorHandler";
import ApiVersion2 from "./version2Api";
import jwtDecode from "jwt-decode";
const token = localStorage.getItem("access_token");

export const createScenario = async (modifiedFormData) => {
  const token = localStorage.getItem("access_token");
  try {
    return await APIVERSION2.post(
      `${ApiVersion2.scenario.detail}`,
      modifiedFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    ErrorHandler(error);
  }
};

export const createInfraVersion2 = async (scenarioId, dataValue) => {
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.post(
    `${ApiVersion2.scenario.submitInfra}?scenario_id=${scenarioId}`,
    { scenario_infra: dataValue },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const scenarioList = async () => {
  const token = localStorage.getItem("access_token");
  //live-game
  return await APIVERSION2.get(`${ApiVersion2.scenario.scenarioList}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// scenario details
export const scenarioDetailsVersion2 = async (id) => {
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.get(
    `${ApiVersion2.scenario.scenarioDetials}${id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//Achievers winning wall corporate
export const getWinningWallDataCorporate = async (id) => {
  return await APIVERSION2.get(
    `${ApiVersion2.scenario.achievers}${id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// start game api
export const scenarioStartGameVersion2 = async (scenarioId, arr, userId) => {
  const val = JSON.stringify(arr);
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.post(
    `${ApiVersion2.scenario.scenarioStart}`,

    {
      scenario_id: scenarioId,
      started_by: userId,
      participant_data: arr,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getConsoleId = async (scenarioId, id) => {
  return await APIVERSION2.post(
    `${ApiVersion2.scenario.getConsole}?scenario_id=${scenarioId}&participant_id=${id}`
  );
};

// active game
// scenario details
export const activeGameVersion2 = async () => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);
  return await APIVERSION2.get(`${ApiVersion2.scenario.activegame}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// get console details
// scenario details for players
export const getConsoleVersion2 = async (id) => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);
  return await APIVERSION2.get(`${ApiVersion2.scenario.getconsole}${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// scenario details for white team of players
export const getConsoleForWhiteVersion2 = async (scenairoId, userId) => {
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.post(
    `${ApiVersion2.scenario.white_team_player_other}`,
    {
      active_scenario_id: scenairoId,
      participant_id: userId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// milestone submit
// export const milestoneSubmitVersion2 = async (
//   scenario_id,
//   milestone_id,
//   selectedFile
// ) => {
//   const token = localStorage.getItem("access_token");
//   const user = token && jwtDecode(token);
//   console.log("user", user.user_id);
//   console.warn("File is here", selectedFile);
//   return await APIVERSION2.post(
//     `${ApiVersion2.scenario.submitmilestone}`,
//     {
//       active_scenario_id: scenario_id,
//       milestone_id: milestone_id,
//       screenshot_url: selectedFile,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

export const milestoneSubmitVersion2 = async (
  scenario_id,
  milestone_id,
  selectedFile
) => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);
  console.log("user", user.user_id);
  console.warn("File is here", selectedFile);

  const formData = new FormData();
  formData.append("active_scenario_id", scenario_id);
  formData.append("milestone_id", milestone_id);
  formData.append("screenshot_url", selectedFile);

  return await APIVERSION2.post(
    `${ApiVersion2.scenario.submitmilestone}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

// /white team active game

export const whiteTeamVersion2 = async () => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);

  return await APIVERSION2.get(`${ApiVersion2.scenario.whiteTeam}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// console team

// scenario details
export const getConsoleTeamVersion2 = async () => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);
  return await APIVERSION2.get(`${ApiVersion2.scenario.console_team}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// approved milestone
export const milestoneApprovedVersion2 = async (
  scenario_id,
  milestone_id,
  userId
) => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);
  return await APIVERSION2.post(
    `${ApiVersion2.scenario.approved}`,
    {
      active_scenario_id: scenario_id,
      milestone_id: milestone_id,
      participant_id: userId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// un-approved milestone
export const milestoneUnApprovedVersion2 = async (
  scenario_id,
  milestone_id,
  userId
) => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);
  return await APIVERSION2.post(
    `${ApiVersion2.scenario.unApproved}`,
    {
      active_scenario_id: scenario_id,
      milestone_id: milestone_id,
      participant_id: userId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// flag submit
export const flagSubmitApi = async (modifiedFormData) => {
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.post(
    `${ApiVersion2.scenario.flag}`,
    modifiedFormData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//hint
export const milestoneHintVersion2 = async (scenario_id, id, scenario_type) => {
  const token = localStorage.getItem("access_token");
  const user = token && jwtDecode(token);
  // console.log(scenario_id, user.user_id, "dataaaa for test purpose");
  // console.log(scenario_type,  "scenario_type -- scenario_type");
  // console.log(user.user_id, 'user.user_iduser.user_id');

  if (scenario_type === "FLAG") {
    return await APIVERSION2.post(
      `${ApiVersion2.scenario.hint}`,
      {
        active_scenario_id: scenario_id,
        flag_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    return await APIVERSION2.post(
      `${ApiVersion2.scenario.hint}`,
      {
        active_scenario_id: scenario_id,
        milestone_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};

// end game api delete
export const endGameV2 = async (id) => {
  const token = localStorage.getItem("access_token");
  //end-game
  return await APIVERSION2.get(`${ApiVersion2.scenario.endGame}${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// get report data list
export const getReportList = async (userId) => {
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.get(
    `${ApiVersion2.scenario.getReportList}${userId}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// download Report
export const downloadReport = async (activeScenarioId, userId) => {
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.get(
    `${ApiVersion2.scenario.downloadReport}${activeScenarioId}/${userId}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// getCategoryCorporate

export const getCategoryCorporate = async (categoryId) => {
  const token = localStorage.getItem("access_token");
  return await APIVERSION2.get(
    `${ApiVersion2.scenario.getCategoryCorporate}${categoryId}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const liveScoreList = async (group) => {
  const token = localStorage.getItem("access_token");
  //live-game
  return await APIVERSION2.get(`${ApiVersion2.scenario.liveScore}${group}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//winning wall
export const getCorporateWinningWall = async (id) => {
  return await APIVERSION2.get(`${ApiVersion2.scenario.scenarioWinningWall}`);
};

export const userReportApi = async (id,user_id) => {
  const token = localStorage.getItem("access_token");
  //live-game
  return await APIVERSION2.get(`${ApiVersion2.scenario.report_api}${id}/${user_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};