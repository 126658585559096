import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getScenarioCategory} from "../../../../APIConfig/adminConfig";
import BreadCrumbs from "../../../../components/navbar/BreadCrumb";
import CreateCategories from "../../common/createCategories";

const EditScenarioCategories = () => {

  const [scenarioCategoryData, setScenarioCategoryData] = useState(null)
  const {categoryId} = useParams();

  const breadcrumbs = [
    {
      name: 'Dashboard',
      link: '/'
    },
    {
      name: 'Categories',
      link: '/admin/scenario-categories'
    }, {
      name: 'Edit Category',
      link: `/admin/create-scenario-categories/${categoryId}`
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await getScenarioCategory(categoryId);
        setScenarioCategoryData(response.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [categoryId]);


  return (
    <>
      <BreadCrumbs breadcrumbs={breadcrumbs}/>
      {scenarioCategoryData &&
        <CreateCategories template='Edit Scenario' data={scenarioCategoryData} categoryId={categoryId}/>}
    </>
  );
};

export default EditScenarioCategories;