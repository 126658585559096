import { Backdrop, Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import img from "../../assests/rangestrom.png";
import { LineChart } from "@mui/x-charts";
import SimpleLineChart from "./LineChart";
import { userReportApi } from "../../APIConfig/version2Scenario";
import InfoCard from "./Info";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorHandler from "../../ErrorHandler";
import ErrorNavigator from "../../ErrorHandler/errorNavigator";
import { CircularProgress } from "@material-ui/core";
import HTMLRenderer from "../../components/HtmlRendering";
import { Icons } from "../../components/icons";
import ReactToPrint from "react-to-print";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from './index.module.css';

const Report = () => {
  const componentRef = useRef();

  return (
    <Stack sx={{ alignItems: "center", justifyContent: "center", paddingY: 5 }}>
      <ReactToPrint
        trigger={() => <Button variant="outlined">Print this out!</Button>}
        content={() => componentRef.current}
      />
      <PrintableComponent ref={componentRef} />
    </Stack>
  );
};

const PrintableComponent = React.forwardRef((props, ref) => {
  const { corporate_id, user_id } = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [item, setReportData] = useState([]);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    console.log(event.target, "name");
    setAge(event.target.name);
    paginate(event.target.value + 1);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await userReportApi(corporate_id, user_id);
        if (data) {
          setReportData(data.data);
          setLoad(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        ErrorNavigator(error, navigate);
      }
    };

    getData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(1); // Set items per page here

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = item.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  console.log(currentItems, "currentItems");
  return (
    <Stack sx={{ justifyContent: "center", alignItems: "center", padding: 5 }}>
      <Stack direction="row" gap={5}>
        {item?.length > 1 && (
          <Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Participants
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Participants"
                onChange={handleChange}
              >
                {item?.map((data, i) => {
                  return (
                    <MenuItem value={i} name={data?.user_full_name}>
                      {data.user_full_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}
      </Stack>

      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "800px",
          height: "100%",
          p: 6,
          gap: 5,
        }}
        ref={ref}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {currentItems.map((reportData, index) => (
          <Stack
            key={index}
            sx={{
              gap: 4,
              backgroundColor: "#fff",
              gap: 2,
            }}
          >
            <Stack
              direction="row"
              sx={{
                backgroundColor: "#1c1f28",
                width: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                p: 5,
              }}
            >
              <Stack direction="row" sx={{ alignItems: "center",gap:"12px" }}>
                <Box
                  component="img"
                  src={reportData?.user_avatar}
                  alt="image"
                  sx={{ width: "72px", height: "72px", borderRadius: "50%" }}
                />
                <Stack>
                  <p className={styles.name}>{reportData?.user_full_name}</p>
                  <p className={styles.email}>{reportData?.email}</p>
                </Stack>
              </Stack>
              <p className={styles.team}>{reportData?.team} TEAM</p>
            </Stack>
            <Stack>
              <Stack padding={5}>
                <p className={styles.heading}>Scenario Details</p>
                <div className={styles.line}></div>
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  sx={{ marginY: "16px" }}
                >
                  <Box
                    component="img"
                    src={reportData?.thumbnail_url}
                    alt="image"
                    sx={{ width: "72px", height: "72px", borderRadius: "41px" }}
                  />
                  <Stack>
                    <p className={styles.textHeading}>{reportData?.scenario_name}</p>
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: "space-between",
                        minWidth: "200px",
                      }}
                    >
                      <Stack direction="row" alignItems="center">
                        <Icons.dumbbell style={{ fontSize: "24px" }} />
                        <p className={styles.icon}>{reportData?.scenario_severity}</p>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Icons.users style={{ fontSize: "24px" }} />
                        <p className={styles.icon}>
                          {reportData?.type == "flag_based"
                            ? "Flag"
                            : "Milestone"}
                        </p>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <HTMLRenderer htmlContent={reportData?.description} />

                <p className={styles.heading}>Insights</p>
                <div className={styles.line}></div>
              </Stack>
            </Stack>

            <Stack direction="row" justifyContent="space-around" >
              <Stack className={styles.box}>
                {reportData?.series && (
                  <p
                    className={styles.textHeading}
                  >{`Number of Hint Used ${reportData?.series[0]?.data[0]?.value} / ${reportData?.series[0]?.data[1]?.value}  `}</p>
                )}
                {reportData?.series && (
                  <PieChart
                    series={reportData?.series}
                    color="black"
                    width={"400"}
                    height={"200"}
                  />
                )}
              </Stack>

              <Stack className={styles.box}>
                <Typography variant="h3">
                  {" "}
                  <p
                    className={styles.textHeading}
                  >{`Total Score Obtained ${reportData?.total_obtained_score} / ${reportData?.total_score} `}</p>
                </Typography>
                <div className={styles.line}></div>
                {reportData?.score_obtained && (
                  <PieChart
                    series={reportData?.score_obtained}
                    width={"400"}
                    height={"200"}
                    color="black"
                  />
                )}
              </Stack>
            </Stack>
            <Stack  className={styles.bar} px={5}>
              <p
               className={styles.textHeading}
              >
                Time spend on each question(in Min)
              </p>
              {reportData?.time_taken && (
                <BarChart
                  width={"1000"}
                  height={"300"}
                  series={[
                    { data: reportData?.time_taken, label: "time in minutes" },
                  ]}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: reportData?.retires_array_x_value,
                    },
                  ]}
                />
              )}
            </Stack>
            <Stack p={5} gap={4}>
              <div>
              <p
               className="styles.textHeading"
              >
                Flags Information
              </p>
              <div className={styles.line}></div>
              </div>
              <Stack gap={5}>
                {reportData?.question &&
                  reportData?.question?.reverse().map((item, index) => {
                    return (
                      <Stack
                        direction="row"
                        gap={2}
                        justifyContent="space-between"
                      >
                        <Stack direction="row" gap={2}>
                          <p
                            className={styles.question}
                          >
                            Q{index + 1}
                          </p>
                          <Stack>
                            <p
                               className={styles.question}
                            >
                              {item?.question}
                              {item?.name}
                            </p>
                            <Stack gap={2}>
                              {typeof (
                                item?.submitted_response == "string"
                              ) && (
                                <p
                                  className={styles.answer}
                                >
                                  Final submission:{"  "}
                                  {item?.submitted_response}
                                </p>
                              )}
                              {item?.answer && (
                                <p
                                  className={styles.answer}
                                >
                                  Correct answer: {item?.answer}
                                </p>
                              )}
                              {typeof item?.is_achieved == "boolean" && (
                                <p
                                  className={styles.answer}
                                >
                                  {item?.is_achieved
                                    ? "Achieved"
                                    : "Not Achieved"}
                                </p>
                              )}
                              {typeof item?.is_approved == "boolean" && (
                                <p
                                  className={styles.answer}
                                >
                                  {item?.is_approved
                                    ? "Approved"
                                    : "Not approved"}
                                </p>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            minHeight: "68px",
                            width: "auto",
                            border: "1px solid #41D5CB",
                            borderRadius: "8px",
                            minWidth: "150px",
                            padding:2
                          }}
                        >
                          <Stack
                            direction="row"
                            sx={{
                              justifyContent: "space-between",
                              padding: "4px 8px 4px 8px",
                            }}
                          >
                            <p
                             className={styles.question}
                            >
                              Score
                            </p>
                            <p
                              className={styles.answer}
                            >
                              {item?.obtained_score}/{item?.score}
                            </p>
                          </Stack>
                          {typeof item?.retires == "number" && (
                            <Stack
                              direction="row"
                              sx={{
                                justifyContent: "space-between",
                                padding: "4px 8px 4px 8px",
                              }}
                            >
                              <p
                               className={styles.question}
                              >
                                Retire count
                              </p>
                              <p
                                className={styles.answer}
                              >
                                {item?.retires}
                              </p>
                            </Stack>
                          )}

                          <Stack
                            direction="row"
                            sx={{
                              justifyContent: "space-between",
                              padding: "4px 8px 4px 8px",
                            }}
                          >
                            <p
                             className={styles.question}
                            >
                              Hints used
                            </p>
                            <p
                              className={styles.answer}
                            >
                              {item?.hint_used ? "1" : "0"}
                            </p>
                          </Stack>
                        </Stack>
                      </Stack>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" gap={2}>
        {/* {item.length > itemsPerPage &&
          Array.from(
            { length: Math.ceil(item.length / itemsPerPage) },
            (_, i) => (
              <Button
                key={i}
                onClick={() => paginate(i + 1)}
                sx={{
                  backgroundColor:
                    currentPage === i + 1 ? "#41D5CB" : "#FFFFFF",
                  color: currentPage === i + 1 ? "#FFFFFF" : "#000000",
                  border: "1px solid #41D5CB",
                  borderRadius: "",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor:
                      currentPage === i + 1 ? "#41D5CB" : "#E0E0E0",
                  },
                }}
              >
                {item?.user_full_name}
              </Button>
            )
          )} */}
      </Stack>
    </Stack>
  );
});
export default Report;
