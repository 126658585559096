import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { GiMeshNetwork } from "react-icons/gi";
import FullScreenDialog from "./scenarioDialog";
import { toast } from "react-toastify";
import uploadImg from "../../components/assests/uploadIcon.png";
import { createSenarios, getCategorList } from "../../APIConfig/scenarioConfig";
import ListItem from "../uploadMachine/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import TextEditor from "../../components/TextEditor";
import BreadCrumbs from "../../components/navbar/BreadCrumb";
import "./index.css";

const CreateScenarios = ({
  setToggle,
  setForm,
  image,
  setImage,
  category,
  setCategory,
  inputs,
  setInputs,
  selectedValue,
  setSelectedValue,
  multipleFile,
  setMutipleFile,
  text,
  setText,
  toolsAndTechnologies,
  setToolsAndTechnologies,
  prerequisites,
  setPrerequisites,
  file,
  setFile,
}) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value != 1 ? "FLAG" : "MILESTONE",
    });
  };

  let arr = [];

  useEffect(() => {
    const apiCall = async () => {
      const data = await getCategorList();
      setCategory(data?.data);
      data?.data?.map((item) => {
        let val = {
          value: item.scenario_category_id,
          label: item.scenario_category_name,
        };
        arr.push(val);
      });
    };
    apiCall();
  }, []);

  // useEffect(() => { }, [value, inputs]);

  useEffect(() => {}, [multipleFile, category]);

  const changeHandler = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handlechangePdf = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      if (image.type.split("/").pop() == "pdf") {
        if (image.size < 5 * 1024 * 1024) {
          setFile((state) => ({
            ...state,
            [image.type.split("/").pop()]: e.target.files[0],
          }));
          setMutipleFile([...multipleFile, e.target.files[0]]);
        } else {
          toast.error("upload a file less than 5 MB");
        }
      } else {
        toast.error("Please upload a pdf");
      }
    }
  };

  const handlechangeJpeg = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      if (
        image.type.split("/").pop() == "jpeg" ||
        image.type.split("/").pop() == "png"
      ) {
        if (image.size < 5 * 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(image);
          setFile((state) => ({ ...state, ["jpeg"]: e.target.files[0] }));
          setInputs((state) => ({
            ...state,
            [e.target.name]: e.target.files[0],
          }));
        } else {
          toast.error("upload a file less than 5MB!");
        }
      } else {
        toast.error("Please upload a jpeg");
      }
    }
  };

  const handleRemoveItem = (name) => {
    setMutipleFile(multipleFile.filter((item) => item.name !== name));
  };

  const breadcrumbs = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: " Create Corporate",
      link: "/createscenarios",
    },
  ];

  const handleNext = () => {
    if (
      inputs.scenario_name &&
      inputs.scenario_category_id &&
      inputs.scenario_assigned_severity &&
      file &&
      selectedValue
    ) {
      if (
        text.length > 500 &&
        text.length < 5000 &&
        toolsAndTechnologies.length > 500 &&
        toolsAndTechnologies.length < 5000 &&
        prerequisites.length > 500 &&
        prerequisites.length < 5000
      ) {
        setForm({ inputs, text, toolsAndTechnologies, prerequisites, file });
        setToggle(selectedValue);
      } else {
        if (text.length <= 500) {
          toast.error("Description should be more than 500 characters.");
        }
        if (text.length >= 5000) {
          toast.error("Description should be less than 5000 characters.");
        }
        if (toolsAndTechnologies.length <= 500) {
          toast.error(
            "Tools and Technologies should be more than 500 characters."
          );
        }
        if (toolsAndTechnologies.length >= 5000) {
          toast.error(
            "Tools and Technologies should be less than 5000 characters."
          );
        }
        if (prerequisites.length <= 500) {
          toast.error("Prerequisites should be more than 500 characters.");
        }
        if (prerequisites.length >= 5000) {
          toast.error("Prerequisites should be less than 5000 characters.");
        }
      }
    } else {
      toast.error("All fields are required!");
    }
  };

  return (
    <Stack marginBottom={5}>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <Stack direction="row" justifyContent="space-between" padding={3}>
        <Typography variant="h1">Create Corporate </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            width: "160px",
            p: 2,
            borderRadius: "8px",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "normal",
            fontStyle: "normal",
          }}
          onClick={handleNext}
        >
          Save & Continue
        </Button>
      </Stack>
      <Stack alignItems="center">
        <Stack width="80%" justifyContent="center" alignItems="center">
          {/* top input */}
          <Stack
            direction="row"
            justifyContent="space-evenly"
            gap={2}
            width="100%"
            backgroundColor="#16181F"
            padding={5}
          >
            <Stack gap={1} width="100%" bottomPadding={20}>
              <Typography variant="h3" sx={{ color: "#9C9EA3 !important" }}>
                Upload Thumbnail
              </Typography>
              <Stack sx={{ mb: 1 }}>
                {file?.jpeg ? (
                  <Stack direction="row" gap={1}>
                    {image && (
                      <img
                        src={image}
                        alt="Preview"
                        width="100%"
                        height="132px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    {/* <Typography
                sx={{
                  color: "#fff",
                  width: "content-fit",
                  whiteSpace: "nowrap",
                }}
              >
                {file?.jpeg && `${file?.jpeg?.name}`}
              </Typography> */}
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFile((state) => ({ ...state, ["jpeg"]: "" }));
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack
                    component="label"
                    variant="text"
                    sx={{
                      color: "#fff",
                      backgroundColor: "none",
                      width: "100%",
                    }}
                  >
                    <Stack
                      spacing={2}
                      style={{
                        border: "3px dashed #12464C",
                        borderRadius: "8px",
                        height: "132px",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={uploadImg}
                        alt="upload_image"
                        height="36px"
                        width="36px"
                      />
                      <Typography variant="body2" style={{ color: "#6F727A" }}>
                        Drop your thumbnail here, or Browse Upload A. jpeg
                      </Typography>

                      <input
                        type="file"
                        accept="image/jpeg,image/png"
                        hidden
                        onChange={handlechangeJpeg}
                        name="scenario_thumbnail"
                      />
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Stack gap={2} width="100%" direction="row">
                <Stack width="100%" sx={{ mb: 1 }}>
                  <TextField
                    name="scenario_name"
                    id="outlined-basic"
                    label="Scenario Name"
                    variant="outlined"
                    onChange={changeHandler}
                  />
                </Stack>
              </Stack>
              <Stack gap={2} width="100%" direction="row">
                <Stack
                  width="100%"
                  sx={{
                    borderRadius: "8px",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Severity
                    </InputLabel>
                    <Select
                      MenuProps={{
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#686868",
                          },
                        },
                      }}
                      sx={{
                        color: "white",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acacac",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acacac",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acacac",
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#acacac !important",
                        },
                      }}
                      value={inputs.scenario_assigned_severity}
                      name="scenario_assigned_severity"
                      label="Severity"
                      onChange={handleInputChange}
                    >
                      <MenuItem value="Very Easy">Very Easy</MenuItem>
                      <MenuItem value="Easy">Easy</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="Hard">Hard</MenuItem>
                      <MenuItem value="Very Hard">Very Hard</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack
                  overflowX="auto"
                  width="100%"
                  sx={{
                    borderRadius: "8px",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inputs.scenario_category_id}
                      label="Select Category"
                      name="scenario_category_id"
                      onChange={handleInputChange}
                      sx={{
                        color: "white",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acacac",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acacac",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acacac",
                        },
                        ".MuiSvgIcon-root ": {
                          fill: "#acacac !important",
                        },
                      }}
                      MenuProps={{
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#686868",
                          },
                          maxHeight: "300px",
                        },
                      }}
                    >
                      {category?.map((item, index) => {
                        return (
                          <MenuItem
                            value={item.scenario_category_id}
                            key={index}
                          >
                            {item.scenario_category_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                {/* <Stack width="100%">
              <TextField
                id="outlined-basic"
                label="Points"
                variant="outlined"
                onChange={handleInputChange}
                name="scenario_score"
              />
            </Stack> */}
              </Stack>
              {/* <Stack width="100%">
            <TextField
              id="outlined-basic"
              label="Time Duration"
              variant="outlined"
              name="scenario_time"
              onChange={handleInputChange}
            />
          </Stack> */}

              <Stack>
                <Typography
                  variant="h3"
                  mb={1}
                  sx={{ color: "#9C9EA3 !important" }}
                >
                  Exercise Type
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="1"
                    name="type"
                    control={<Radio onChange={handleChange} />}
                    label={
                      <Typography style={{ color: "#9C9EA3" }}>
                        Milestones Based
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="2"
                    name="type"
                    control={<Radio onChange={handleChange} />}
                    label={
                      <Typography style={{ color: "#9C9EA3" }}>
                        Flag Based
                      </Typography>
                    }
                    style={{ color: "blue" }}
                  />
                </RadioGroup>
              </Stack>
              <Stack width="100%">
                <Typography
                  variant="h3"
                  mb={1}
                  sx={{ color: "#9C9EA3 !important" }}
                >
                  Description
                </Typography>
                <TextEditor setText={setText} text={text} />
              </Stack>

              <Stack width="100%">
                <Typography
                  variant="h3"
                  mt={6}
                  mb={1}
                  sx={{ color: "#9C9EA3 !important" }}
                >
                  Tools & Technologies
                </Typography>
                <TextEditor
                  setText={setToolsAndTechnologies}
                  text={toolsAndTechnologies}
                />
              </Stack>

              <Stack width="100%">
                <Typography
                  variant="h3"
                  mt={6}
                  mb={1}
                  sx={{ color: "#9C9EA3 !important" }}
                >
                  Prerequisites
                </Typography>
                <TextEditor setText={setPrerequisites} text={prerequisites} />
              </Stack>
              <Stack sx={{ height: "40px" }}></Stack>
            </Stack>

            {/* <Stack gap={8} width="30%" p={1} alignItems="center" overflow="hidden">
        


          <Typography variant="h3">Upload Walkthrough</Typography>
          <Button
            component="label"
            variant="text"
            sx={{
              color: "#fff",
              backgroundColor: "none",
              width: "100%",
            }}
          >
            <Stack
              spacing={2}
              style={{
                border: "1px dashed #12464C",
                borderRadius: "8px",
                height: "100px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={uploadImg}
                alt="upload_image"
                height="36px"
                width="36px"
              />
              <Typography variant="body2" style={{ color: "#6F727A" }}>
                Drop your files here, or Browse Upload A.pdf
              </Typography>
              <input
                type="file"
                hidden
                onChange={handlechangePdf}
                name="scenario_documents"
              />
            </Stack>
          </Button>
          <>
            {multipleFile.map((item, index) => {
              return (
                <Stack direction="row" gap={1} key={index}>
                  <Typography sx={{ color: "#fff" }}>{item?.name}</Typography>
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveItem(item.name)}
                  />
                </Stack>
              );
            })}
          </>
        </Stack> */}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateScenarios;
