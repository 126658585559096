import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material"
import { useSelector } from "react-redux";
import { theme } from "./Pallete/DarkPallete";

const ThemeConfig = ({ children }) =>{
    const themeState = (state)=> state.theme.mode
    const storeState = useSelector(themeState)
   
    return(
         <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
         </StyledEngineProvider>
    )
}

export default ThemeConfig;