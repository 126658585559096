import {
  Typography,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { Icons } from "../../../components/icons";
import {
  flagSubmitApi,
  milestoneHintVersion2,
} from "../../../APIConfig/version2Scenario";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import fuse_bulb from "../../../components/assests/fuse bulb.svg";
import hove_bulb from "../../../components/assests/State=Hover.svg";
import default_bulb from "../../../components/assests/State=Default.svg";

const Flags = ({
  item,
  index,
  active_scenario_id,
  length,
  load,
  reload,
  setReload,
  participant_id,
  flag_id,
  scenario_type,
  hint_used,
  hint_string,
  status,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  // const [hintUsed, setHintUsed] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [showArrowIcon, setShowArrowIcon] = useState(true);
  const [showTickIcon, setShowTickIcon] = useState(false);
  const [hintUsedMap, setHintUsedMap] = useState({});
  // const [bulb, setBulb] = useState(fuse_bulb);

  const handleKeyDown = (e) => {
    const { name, value } = e.target;
    if (e?.key === "Enter" && inputValue.trim() !== "") {
      // submitResponse();
      flagSubmit(name, value);

      // setTimeout(() => {
      //   setShowArrowIcon(true);
      //   setShowTickIcon(false);
      // }, 1000);
    }
  };

  const flagSubmit = async (name, val) => {
    try {
      const token = localStorage.getItem("access_token");
      const user = token && jwtDecode(token);

      const modifiedData = {
        participant_id: user?.user_id,
        active_scenario_id: active_scenario_id,
        flag_id: name,
        submitted_answer: val,
      };
      const value = await flagSubmitApi(modifiedData);

      if (value?.data.message == "Wrong Answer") {
        toast.error("Wrong answer");
        setReload(!reload);
      } else {
        toast.success("Correct answer");
        setShowTickIcon(true);
        setReload(!reload);
      }
    } catch (error) {}
  };

  const handleHintUsed = async (flagId) => {
    try {
      const hint = await milestoneHintVersion2(
        active_scenario_id,
        flagId,
        scenario_type
      );
      if (hint) {
        setReload(!reload);
        const hintData = hint.data.hint;
        setHintUsedMap((prevMap) => ({
          ...prevMap,
          [flagId]: hintData,
        }));
      }
    } catch (error) {
      console.error("Error fetching hint:", error);
      toast.error("Failed to fetch hint");
      setReload(!reload);
    }
  };

  // console.log(hint_used, "hint_usedhint_usedhint_usedhint_used", "datatest");
  // console.log(isHovered, "isHoveredisHovered", "datatest");
  // console.log(hintUsedMap, "hintUsedMaphintUsedMap", "datatest");

  return (
    <Box sx={{ display: "flex", justifyContent: "left", gap: 1 }}>
      <Backdrop
        sx={{
          color: "#fff",
          // zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={load}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack alignItems="center">
        <Box
          width={20}
          height={20}
          borderRadius="50%"
          sx={{
            backgroundColor: item?.is_correct ? "#039855" : "#F79D28",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            p: 2,
            color: "white",
            border: "0.5px solid white",
          }}
        >
          {item?.is_correct ? "✓" : index + 1}
        </Box>

        <Box
          border="1px solid white"
          height="100%"
          sx={{ display: length - 1 === index && "none" }}
        />
      </Stack>

      {status ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            pb: 6,
            pt: "4px",
            // border: "1px solid white",
            width: "90%",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography>Q{index + 1}.</Typography>
            <Typography>
              {item?.question} ({item?.score})
            </Typography>
          </Box>

          {!item?.is_correct && (
            <Stack
              direction="row"
              position="relative"
              display={"flex"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
              width={"100%"}
              gap={1}
            >
              <TextField
                maxRows={4}
                variant="standard"
                placeholder="Enter Your Flag"
                style={{
                  width: "85%",
                  backgroundColor: "#1C1F28",
                  borderRadius: "8px",
                  // height: "auto",
                  color: "#acacac",
                  border: "none",
                  padding: "12px 40px 12px 14px",
                  // resize:'none',
                  resize: "none !important",
                }}
                name={item.flag_id}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value.trimStart())}
                onFocus={() => {
                  setIsFocused(true);
                  setShowArrowIcon(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                  setShowArrowIcon(false);
                }}
                onKeyDown={handleKeyDown}
                // multiline
              />

              {/*             
            <input
              placeholder="Enter Your Flag"
              style={{
                width: "75%",
                backgroundColor: "#1C1F28",
                borderRadius: "8px",
                height: "48px",
                color: "#acacac",
                border: "none",
                padding: "12px 40px 12px 14px",
              }}
              name={item.flag_id}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value.trimStart())}
              onFocus={() => {
                setIsFocused(true);
                setShowArrowIcon(true);
              }}
              onBlur={() => {
                setIsFocused(false);
                setShowArrowIcon(false);
              }}
              onKeyDown={handleKeyDown}
            /> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#13141B",
                  width: "75px",
                  height: "58px",

                  pr: !isHovered && !hint_used && "14px",
                }}
                onMouseEnter={() => setIsHovered(flag_id)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  src={
                    hint_used
                      ? fuse_bulb
                      : isHovered && !hint_used
                      ? hove_bulb
                      : default_bulb
                  }
                  alt="Light Bulb"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => (hint_used ? null : handleHintUsed(flag_id))}
                />
              </Box>

              {isFocused && showArrowIcon && (
                <Icons.rightArrowCircle
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "90px",
                    transform: "translateY(-50%)",
                    fontSize: "20px",
                    color: "#acacac",
                    cursor: "pointer",
                  }}
                />
              )}
              {showTickIcon && (
                <Icons.checkCircle
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "85px",
                    transform: "translateY(-50%)",
                    fontSize: "20px",
                    color: "#00FFFF",
                    cursor: "pointer",
                  }}
                />
              )}
            </Stack>
          )}

          {hint_used && !item?.is_correct && (
            <Box
              sx={{
                display: "flex",
                gap: "6px",
                pr: "38px",
              }}
            >
              <Box>Hint:</Box>
              <Box>{hint_string || hintUsedMap[flag_id]}</Box>
            </Box>
          )}
        </Box>
      ) : (
        <Stack
          direction="row"
          gap={1}
          sx={{
            width: "100%",
            alignItems: "flex-start",
            minHeight: "100px",
            justifyContent: "flex-start",
          }}
        >
          <Typography>Q{index + 1}.</Typography>
          <Stack width="100%" direction="row" justifyContent="flex-start" alignItems='flex-start'>
            <Typography>The question is locked, but will open later.</Typography>
            <Icons.lock style={{ width: "72px", height: "48px",alignContent:'flex-start' }} />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default Flags;
