import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import uploadImg from "../../../../components/assests/uploadIcon.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { createMachine, getCategory } from "../../../../APIConfig/CtfConfig";
import { toast } from "react-toastify";
import "./index.css";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ctfUpdatePost,
  singleCtfUpdateGetApi,
} from "../../../../APIConfig/adminConfig";
import TextEditor from "../../../../components/TextEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
      backgroundColor: "custom.main",
    },
  },
}));

const UpadateCtf = () => {
  const { userId } = useParams();
  const [category, setCategory] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [text, setText] = useState("");
  const [textObj, setTextObj] = useState("");
  const [textPre, setTextPre] = useState("");

  const token = localStorage.getItem("access_token");
  // set all the inputs
  const [inputs, setInputs] = useState({
    ctf_description: "",
    ctf_severity: "",
    ctf_category_id: "",
    ctf_name: "",
    ctf_time: "",
    ctf_score: "",

    // flags: []
  });
  const location = useLocation();
  const currentPath = location?.pathname.split("/", 3).join("/");
  //console.log('active handle data location', currentPath)
  //console.log('splited current path', currentPath.split('/', 3).join('/'))

  const [file, setFile] = useState({ pdf: "", jpeg: "" });
  const [pdfFile, setPdfFile] = useState();
  const [fileData, setFileData] = useState({
    target_machine: "",
    ctf_id: "",
  });

  useEffect(() => {
    //console.log('hrer')
    setInputs({
      ctf_description: "",
      ctf_severity: "",
      ctf_category_id: "",
      ctf_name: "",
      ctf_time: "",
      ctf_score: "",
    });
    const getApi = async () => {
      setAdmin(true);
      const value = await singleCtfUpdateGetApi(userId);
      //console.log("value is here of ctf", value.data)
      setInputs({
        ctf_description: value.data.ctf_description,
        ctf_severity: value.data.ctf_severity,
        ctf_category_id: value.data.ctf_category_id,
        ctf_name: value.data.ctf_name,
        ctf_time: value?.data.ctf_time,
        ctf_score: value?.data?.ctf_score,
      });
      setTextObj(value?.data?.ctf_flags_information);
      setTextPre(value?.data?.ctf_rules_regulations);
      setText(value?.data?.ctf_description);
      setflag(value?.data.ctf_flags);
      setFile({
        pdf: value?.data?.ctf_walkthrough,
        jpeg: value?.data?.ctf_thumbnail,
      });
    };
    if (userId) {
      getApi();
    }
  }, []);
  const classes = useStyles();
  const navigate = useNavigate();

  // const [fileScreen, setFileScreen] = useState(false);

  let sol = "";

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (token) {
      setOpen(true);
    } else {
      navigate("/login");
    }
  };
  const handleClose = () => setOpen(true);

  const [flag, setflag] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const _handleSubmit = (e) => {
    if (inputValue === "") return toast.error("Flag name is required");

    const newArr = flag.slice();
    newArr.splice(0, 0, inputValue);
    setflag(newArr);
    setInputValue("");
  };

  //    get category
  useEffect(() => {
    const getCategorys = async () => {
      const data = await getCategory();
      //console.log(data);
      setCategory(data?.data);
      //console.log("category", category);
    };
    getCategorys();
  }, []);

  useEffect(() => {
    //console.log(file);
  }, [file]);

  const handleEnterKeyPress = (e) => {
    console.log("here");
    if (e.key === "Enter") {
      _handleSubmit();
    }
  };

  const handleInputChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileData((state) => ({ ...state, target_machine: e.target.files[0] }));
      return;
    }
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  useEffect(() => {
    //console.log("inputs", inputs);
  }, [inputs, sol]);

  const flagChanges = () => {
    //console.log('flag', flag)
    if (admin) {
      for (let i = 0; i < flag.length; i++) {
        sol = sol + flag[i] + " ";
      }
    } else {
      for (let i = 0; i < flag.length; i++) {
        sol = sol + flag[i] + " ";
      }
    }

    //console.log('server', sol)
    createMachines(sol);
  };

  const createMachines = async (sol) => {
    try {
      if (admin) {
        //console.log('sol is here', sol)
        const apiPost = await ctfUpdatePost(
          inputs,
          sol,
          userId,
          text,
          textObj,
          textPre
        );
        toast.success("update has been done successfully!!!");
        return;
      }
      const data = await createMachine(inputs, sol);
      //console.log("data", data);
      if (data.data.ctf_creator_id) {
        navigate("/uploadmachine/uploadfile", {
          state: { ctf_id: data?.data?.ctf_id },
        });
      }
    } catch (error) {
      //console.log("error", error);
      const obj = error.response.data.errors;
      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
            i.slice(1).replace(/_/g, " ") +
            " - " +
            obj[i]
        );
      }
    }
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#ACACAC" href="/" variant="h3">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="1"
      color="#ACACAC"
      href="/categories"
      variant="h3"
    >
      Categories
    </Link>,
    <Link
      underline="hover"
      key="2"
      variant="h3"
      color="#ACACAC"
      href="/uploadmachine"
    >
      Upload Machine
    </Link>,
  ];

  const handlechangePdf = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      if (image.type.split("/").pop() == "pdf") {
        setFile((state) => ({
          ...state,
          [image.type.split("/").pop()]: e.target.files[0],
        }));
        setInputs((state) => ({
          ...state,
          [e.target.name]: e.target.files[0],
        }));
        return;
      } else {
        toast.error("Please upload a pdf");
      }
    }
  };

  const handlechangeJpeg = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      //console.log(image);
      if (
        image.type.split("/").pop() == "jpeg" ||
        image.type.split("/").pop() == "png"
      ) {
        setFile((state) => ({ ...state, ["jpeg"]: e.target.files[0] }));
        //console.log(file);
        setInputs((state) => ({
          ...state,
          [e.target.name]: e.target.files[0],
        }));
        return;
      } else {
        toast.error("Please upload a jpeg");
      }
    }
  };

  const removeFlag = (index) => {
    const newArr = flag.slice();
    newArr.splice(index, 1);

    return setflag(newArr);
  };

  const changeHandler = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    //console.log(inputs);
  };

  return (
    <Stack margin={5} gap={4}>
      <Typography variant="h1">Update Solo challange</Typography>

      <Stack gap={12} sx={{ backgroundColor: "custom.main", padding: 4 }}>
        {/*form side*/}

        <Stack width="100%" gap={3} marginTop={1}>
          <Stack direction="row" sx={{ gap: 4 }}>
            <Stack width="55%" gap={2}>
              <Stack width="100%">
                <TextField
                  name="ctf_name"
                  value={inputs.ctf_name}
                  type="text"
                  label="Game Name"
                  variant="outlined"
                  onChange={changeHandler}
                />
              </Stack>
              <Stack width="100%">
                <TextField
                  name="ctf_score"
                  value={inputs.ctf_score}
                  type="text"
                  label="Ctf Score"
                  variant="outlined"
                  onChange={changeHandler}
                />
              </Stack>
              <Box
                sx={{
                  minWidth: 120,

                  borderRadius: "8px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs.ctf_category_id}
                    label="Select Category"
                    name="ctf_category_id"
                    onChange={handleInputChange}
                    sx={{
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#acacac !important",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#686868",
                        },
                      },
                    }}
                  >
                    {category?.map((item) => {
                      return (
                        <MenuItem
                          value={item.ctf_category_id}
                          key={item.ctf_category_id}
                        >
                          {item.ctf_category_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  minWidth: 120,
                  borderRadius: "8px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={inputs.ctf_severity}
                    name="ctf_severity"
                    label="Severity"
                    onChange={handleInputChange}
                    sx={{
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#acacac !important",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#686868",
                        },
                      },
                    }}
                  >
                    <MenuItem value="Very Easy">Very Easy</MenuItem>
                    <MenuItem value="Easy">Easy</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Hard">Hard</MenuItem>
                    <MenuItem value="Very Hard">Very Hard</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{
                  minWidth: 120,
                  borderRadius: "8px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: "#12464C" }}
                  >
                    Time
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs.ctf_time}
                    label="Time"
                    name="ctf_time"
                    onChange={handleInputChange}
                    sx={{
                      color: "white",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#acacac",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#acacac !important",
                      },
                    }}
                    MenuProps={{
                      sx: {
                        "&& .Mui-selected": {
                          backgroundColor: "#686868",
                        },
                      },
                    }}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>

            {/* upload side */}

            <Stack gap={6}>
              <Typography variant="h2">Upload Thumbnail</Typography>
              {file?.jpeg ? (
                <Stack direction="row" gap={2} alignItems="center">
                  {admin ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => window.open(file.jpeg, "_blank")}
                    >
                      Thumbnail
                    </Button>
                  ) : (
                    <Typography sx={{ color: "#fff" }}>
                      {file?.jpeg && `${file?.jpeg?.name}`}
                    </Typography>
                  )}

                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFile((state) => ({ ...state, ["jpeg"]: "" }));
                    }}
                  />
                </Stack>
              ) : (
                <Button
                  component="label"
                  variant="text"
                  sx={{
                    color: "#fff",
                    backgroundColor: "custom.main",
                    width: "448px",
                    my: 2,
                  }}
                >
                  <Stack
                    style={{
                      border: "1px dashed #12464C",
                      borderRadius: "8px",
                      height: "152px",
                      width: "448px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 4,
                      padding: 4,
                    }}
                  >
                    <img
                      src={uploadImg}
                      width="24px"
                      height="24px"
                      style={{ marginTop: 2 }}
                    />
                    <Typography variant="body" style={{ textAlign: "center" }}>
                      Browse and chose the Image you want to upload a .jpeg file
                      from your computer
                    </Typography>
                    <input
                      type="file"
                      hidden
                      onChange={handlechangeJpeg}
                      name="ctf_thumbnail"
                    />
                  </Stack>
                </Button>
              )}

              <Typography variant="h2">Upload Walkthrough</Typography>
              {file?.pdf ? (
                <Stack direction="row" gap={2} alignItems="center">
                  {admin ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => window.open(file.pdf, "_blank")}
                    >
                      WALK THROUGH PDF
                    </Button>
                  ) : (
                    <Typography sx={{ color: "#fff" }}>
                      {file?.pdf && `${file?.pdf?.name}`}
                    </Typography>
                  )}

                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFile((state) => ({ ...state, ["pdf"]: "" }));
                    }}
                  />
                </Stack>
              ) : (
                <Button
                  component="label"
                  variant="text"
                  sx={{
                    color: "#fff",
                    backgroundColor: "custom.main",
                    width: "448px",
                    my: 2,
                  }}
                >
                  <Stack
                    style={{
                      border: "1px dashed #12464C",
                      borderRadius: "8px",
                      height: "152px",
                      width: "448px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 4,
                      padding: 4,
                    }}
                  >
                    <img src={uploadImg} width="24px" height="24px" />
                    <Typography variant="body" style={{ textAlign: "center" }}>
                      Browse and chose the Image you want to upload a .pdf file
                      from your computer
                    </Typography>
                    <input
                      type="file"
                      hidden
                      onChange={handlechangePdf}
                      name="ctf_walkthrough"
                    />
                  </Stack>
                </Button>
              )}
            </Stack>
          </Stack>
          {/* <TextField
              multiline={true}
              value={inputs.ctf_description}
              rows={3}
              name="ctf_description"
              label="Description"
              variant="outlined"
              style={{
                color: "white !important"
              }}
              onChange={changeHandler}
            /> */}

          <Stack width="100%" sx={{ mb: 3 }}>
            <Typography variant="h3">Description</Typography>
            <TextEditor setText={setText} text={text} />
          </Stack>
          <Stack width="100%" sx={{ mb: 3 }}>
            <Typography varaint="h3">Flag Information</Typography>
            <TextEditor setText={setTextObj} text={textObj} />
          </Stack>
          <Stack width="100%" sx={{ mb: 3 }}>
            <Typography variant="h3">Rules and Regulation</Typography>
            <TextEditor setText={setTextPre} text={textPre} />
          </Stack>
          <Stack marginTop={1}>
            <Stack gap={2}>
              <Stack direction="row" gap={2}>
                <TextField
                  name="ctf_flags"
                  style={{ width: "90%" }}
                  value={inputValue}
                  label="Enter Flags"
                  onChange={(e) => setInputValue(e.target.value)}
                  variant="outlined"
                  onKeyPress={handleEnterKeyPress}
                />
                <Button
                  variant="outlined"
                  type="submit"
                  color="secondary"
                  onClick={_handleSubmit}
                >
                  Add
                </Button>
              </Stack>
              <Stack
                display="flex"
                flexDirection="column"
                width="100%"
                gap={2}
                style={{ maxHeight: "150px", overflow: "auto", color: "white" }}
              >
                {flag.map((f, index) => {
                  //console.log('flas is here', f)
                  return (
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        listStyle: "list",
                        color: "#fff",
                      }}
                      key={index}
                    >
                      {f}
                      <Button
                        color="error"
                        variant="contained"
                        sx={{ marginLeft: "8px" }}
                        onClick={removeFlag}
                      >
                        Remove
                      </Button>
                    </li>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
          {/* <Link to="/uploadmachine/uploadfile">  */}
          {!admin ? (
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "130px", p: 2, borderRadius: "8px" }}
              onClick={flagChanges}
            >
              Continue
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: "130px", p: 2, borderRadius: "8px" }}
              onClick={flagChanges}
            >
              submit
            </Button>
          )}
          {/* </Link> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UpadateCtf;
