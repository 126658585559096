import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Button, Stack } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "../../../components/DropDown";
import { useState } from "react";
import { useEffect } from "react";
import CustomizeHiddenInput from "../../../components/CustomizeHiddenInput";
import CustomizeInput from "../../../components/CustomizeInput";
import { getAllAvatars, registerUser } from "../../../APIConfig/CtfConfig";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Popup({ handleChangeSign }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [avatars, setAvatars] = React.useState([]);
  const options = [
    { value: "RED_TEAM ", label: "RED_TEAM" },
    { value: "BLUE_TEAM", label: "BLUE_TEAM" },
    { value: "PURPLE_TEAM", label: "PURPLE_TEAM" },
    { value: "WHITE_TEAM", label: "WHITE_TEAM" },
    { value: "YELLOW_TEAM", label: "YELLOW_TEAM" },
    { value: "OTHER_TEAM", label: "OTHER_TEAM" },
  ];
  const [sign, setSign] = useState({
    user_full_name: "",
    mobile_number: "",
    email: "",
    password: "",
    confirm_password: "",
    user_avatar: "",
    user_role: "RED_TEAM",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    // //console.log(sign);
    getAvatars();
    setSign({ ...sign, user_avatar: avatars[0] });
  }, []);

  const handleSignInChange = (e) => {
    const { name, value } = e.target;
    //console.log('value is here', value)
    setSign({
      ...sign,
      [name]: value,
    });
    //console.log(sign)
  };

  const [selected, setSelected] = useState(0);

  const getAvatars = async () => {
    const response = await getAllAvatars();
    //console.log(response)
    setAvatars(response?.data?.user_avatar_list);
  };

  const handleAvatarClick = (index, avatar) => {
    setSelected(index);
    setSign({ ...sign, user_avatar: avatar });
  };
  const handleClose = async () => {
    const value = await handleChangeSign(sign);
    setOpen(false);
  };

  const handleClose1 = async () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Add User
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          sx={{ backgroundColor: "custom.secondary", m: 0, p: 2 }}
        >
          Add User
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose1}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack justifyContent="space-between" direction="row" width="100%">
            <Stack
              width="100%"
              display="flex"
              flexDirection="column"
              gap={2}
              p={5}
            >
              <Box>
                <Typography sx={{ color: "#fff", textAlign: "left", mb: 1 }}>
                  Choose Avatar:
                </Typography>
                <Box
                  display="flex"
                  gap={2}
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="center"
                >
                  {avatars?.map((avatar, i) => {
                    //console.log(avatar)
                    return (
                      <Avatar
                        key={i}
                        src={avatar}
                        sx={{
                          cursor: "pointer",
                          border: selected === i ? "2px solid white" : "",
                        }}
                        onClick={() => handleAvatarClick(i, avatar)}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Box>
                <Stack width="100%">
                  <Dropdown
                    name="user_role"
                    value={sign.user_role}
                    options={options}
                    onChange={handleSignInChange}
                  />
                </Stack>
              </Box>
              <Stack display="flex" flexDirection="column">
                <CustomizeInput
                  label="Email"
                  type="text"
                  name="email"
                  onChange={handleSignInChange}
                  value={sign.email}
                  placeholder="Enter your Email I'd"
                />
              </Stack>
              <Stack display="flex" flexDirection="column">
                <CustomizeInput
                  label="Name"
                  type="text"
                  name="user_full_name"
                  onChange={handleSignInChange}
                  value={sign.user_full_name}
                  placeholder="Enter your name"
                />
              </Stack>
              <Stack display="flex" flexDirection="column">
                <CustomizeInput
                  label="Mobile number"
                  type="text"
                  name="mobile_number"
                  placeholder="Enter Mobile Number"
                  onChange={handleSignInChange}
                  value={sign.mobile_number}
                />
              </Stack>
              <Stack>
                <CustomizeHiddenInput
                  label="Password"
                  type="password"
                  name="password"
                  key="password"
                  placeholder="Enter Password"
                  onChange={handleSignInChange}
                  value={sign.password}
                />
              </Stack>

              <Stack>
                <CustomizeHiddenInput
                  label="Confirm Password"
                  type="password"
                  key="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  onChange={handleSignInChange}
                  value={sign.confirm_password}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "custom.secondary" }}>
          <Button
            autoFocus
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
