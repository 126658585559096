import {
  Backdrop,
  Box,
  Button,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
//   import Popup from "./popup";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  endGame,
  getConsoleDetailsSenario,
  getScenarioIpAddress,
  submitFlag,
} from "../../../../APIConfig/scenarioConfig";
import { Icons } from "../../../../components/icons";
import Milestone from "../../ScenarioDetails/Milestone";

const IndividualPlayer = ({ data,reload,setReload, load }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [full, setFull] = useState(true);
  const [Data, setScore] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [isActive, setIsActive] = useState(false);
 
  const iframeRef = useRef(null);
  const [ip, setIp] = useState([]);
  useEffect(() => { }, [inputValue]);
  const location = useLocation();

  const [consoleData, setConsoleUrl] = useState(data?.console_url);

  const endGameConsole = async () => {
    setIsActive(true);
    const value = await endGame(data?.scenario_game_id);
    navigate("/");
    setScore(value);
    setIsActive(false);
    setOpen(true);
  };


  // refresh the consoleUrl
  const refreshConsole = async () => {
    setIsActive(true);
    // const res = await getConsoleDetailsSenario(data?.scenario_game_id);
    // setConsoleUrl(res.data?.scenario_console_url);
    setReload(!reload)
    setIsActive(false);
  };

  // set console for white team
  const handleApi = (url) => {
    setConsoleUrl(url);
  };

 

 



  const getActiveIp = async () => {
    setIsActive(true);
    try {
      const value = await getScenarioIpAddress(data?.scenario_game_id);
      setIp(value?.data?.scenario_instance_ip_list);
      setIsActive(false);
    } catch (error) {
      setIsActive(false);
      const obj = error.response.data.errors;

      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
          i.slice(1).replace(/_/g, " ") +
          " - " +
          obj[i]
        );
      }
    }
  };





  return (
    <Stack  width="100%">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isActive}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* container */}
      <Stack direction="row" gap={1}>
        {/* details section */}
        <Stack
          sx={{
            width: full ? "28%" : "0%",
            transition: full ? "width 0.3s ease-out" : "width 0.3s ease-in",
          }}
          height="95vh"
          style={{ overflowY: "scroll", scrollbarWidth: "none" }}
          className="example"
        >
          <Stack gap={3} sx={{ position: "sticky", top: 0 }}>
            {/* top section */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* <Button variant="contained" onClick={endGameConsole}>
                End
              </Button> */}
              <Stack direction="row" gap={1} alignItems="center">
                <Tooltip title="Refresh">
                  <Icons.refresh
                    onClick={refreshConsole}
                    style={{ fontSize: "24px", cursor: "pointer" }}
                  />
                </Tooltip>
                <Icons.doubleLeftArrow
                  style={{ fontSize: "36px", cursor: "pointer" }}
                  onClick={() => setFull(!full)}
                />
              </Stack>
            </Stack>
            <Milestone item={data?.milestone_data} scenario_id={data?.active_scenario_id} reload={reload} setReload={setReload} load={load}/>
          </Stack>
        </Stack>
        {/* setfull screen button */}
        <Tooltip title="Details Page" placement="top">
          <Icons.doubleRightArrow
            style={{
              fontSize: "36px",
              cursor: "pointer",
              width: full ? "0%" : "2.5%",
            }}
            onClick={() => setFull(!full)}
          />
        </Tooltip>
         
        {/* iframe section */}
        <Stack
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            display: "flex",
            width: !full ? "96%" : "78%",
            height: "95vh",
          }}
        >
        
          <iframe
            ref={iframeRef}
            src={data?.console_url}
            title="Tutorials"
            style={{ width: "100%", height: "100%" }}
            allow="same-origin"
          ></iframe>
        </Stack>
      </Stack>
   
    </Stack>
  );
};

export default IndividualPlayer;
