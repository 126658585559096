import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import BreadCrumbs from "../../../components/navbar/BreadCrumb";
import MilestoneFlag from "./MilestoneFlag";
import Teams from "./Teams";
import FullScreenDialog from "../../CreateScenarios/scenarioDialog";
import ScenarioWalkthrough from "../scenarioWalkthrough";
import { Icons } from "../../../components/icons";
import { GiTruce } from "react-icons/gi";
import { toast } from "react-toastify";
import { isTimeView } from "@mui/x-date-pickers/internals/utils/time-utils";
import { ImportExport } from "@mui/icons-material";

const initialTeamData = [
  {
    title: "Blue Team Milestone",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#7A5AF8",
    active: true,
  },
  {
    title: "Red Team Milestone",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#CC2E28",
    active: false,
  },
  {
    title: "Purple Team Milestone",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#9747FF",
    active: false,
  },
  {
    title: "Yellow Team Milestone",
    walkthrough: [],
    questionInformation: [getDefaultQuestion()],
    color: "#F79D28",
    active: false,
  },
];

function getDefaultQuestion() {
  return {
    name: "",
    description: "",
    hint: "",
    score: 1,
    index: 0,
  };
}

const MileStone = ({ setToggle, setMileData, handleApi, mile }) => {
  const [data, setData] = useState(initialTeamData);
  useEffect(() => {}, [mile]);

  const validation = (data) => {
    console.log("data is here", data);
    if (!data.name || !data.description || !data.hint || !data.score) {
      return true;
    }
  };

  const validationFirst = (data) => {
    console.log("data is here", data);
    if (!data.name && !data.description && !data.hint) {
      return true;
    }
  };

  const [sysTools, setSysTools] = useState(true);
  const handleData = async () => {
    let blueWalkthrough = [];
    let redWalkthrough = [];
    let purpleWalkthrough = [];
    let yellowWalkthrough = [];
    let blueTeam = [];
    let redTeam = [];
    let purpleTeam = [];
    let yellowTeam = [];
    console.log("data", data);
    let num =0;
    // checking if all the length of question is fill or not
    for (let i = 0; i < data.length; i++) {
      if (data[i].questionInformation[0].name =="" && data[i].questionInformation[0].description =="" && data[i].questionInformation[0].description =="") {
       
        num +=1;
      }
    }
    if(num == 4){
      num = 0;
      toast.error("Atleast one question is required");
      return false;
    }

    for (let j = 0; j < data.length; j++) {
      if (
        data[j].title == "Blue Team Milestone" &&
        data[j].questionInformation.length > 0
      ) {
        for (let k = 0; k < data[j].questionInformation.length; k++) {
          if(k==0 && validationFirst(data[j].questionInformation[k])){
            continue;
          }
          let validator = validation(data[j].questionInformation[k]);
          if (validator) {
            toast.error("All field are required in Blue Team milestone");
            return false;
          }
        }
      }
      if (
        data[j].title == "Red Team Milestone" &&
        data[j].questionInformation.length > 0
      ) {
        for (let k = 0; k < data[j].questionInformation.length; k++) {
          if(k==0 && validationFirst(data[j].questionInformation[k])){
            continue;
          }
          let validator = validation(data[j].questionInformation[k]);
          if (validator) {
            toast.error("All field are required in Red Team milestone");
            return false;
          }
        }
      }
      if (
        data[j].title == "Purple Team Milestone" &&
        data[j].questionInformation.length > 0
      ) {
        for (let k = 0; k < data[j].questionInformation.length; k++) {
          if(k==0 && validationFirst(data[j].questionInformation[k])){
            continue;
          }
          let validator = validation(data[j].questionInformation[k]);
          if (validator) {
            toast.error("All field are required in Purple Team milestone");
            return false;
          }
        }
      }
      if (
        data[j].title == "Yellow Team Milestone" &&
        data[j].questionInformation.length > 0
      ) {
        for (let k = 0; k < data[j].questionInformation.length; k++) {
          if(k==0 && validationFirst(data[j].questionInformation[k])){
            continue;
          }
          let validator = validation(data[j].questionInformation[k]);
          if (validator) {
            toast.error("All field are required in Yellow Team milestone");
            return false;
          }
        }
      }
    }

    if (blueTeam[0]?.name == "") {
      blueTeam.shift();
    }

    if (redTeam[0]?.name == "") {
      redTeam.shift();
    }
    if (purpleTeam[0]?.name == "") {
      purpleTeam.shift();
    }

    if (yellowTeam[0]?.name == "") {
      yellowTeam.shift();
    }

    setSysTools({
      red_team: redTeam,
      blue_team: blueTeam,
      yellow_team: yellowTeam,
      purple_team: purpleTeam,
    });

    // setMile(JSON.stringify({
    // red_team:redTeam,
    // blue_team:blueTeam,
    // yellow_team:yellowTeam,
    // purple_team:purpleTeam
    // }))

    // setMile({
    // red_team: redTeam,
    // blue_team: blueTeam,
    // yellow_team: yellowTeam,
    // purple_team: purpleTeam
    // })
    setMileData(redTeam, blueTeam, yellowTeam, purpleTeam);

    const milestoneObj = JSON.stringify({
      red_team: redTeam,
      blue_team: blueTeam,
      yellow_team: yellowTeam,
      purple_team: purpleTeam,
    });

    return await handleApi(
      milestoneObj,
      blueWalkthrough,
      redWalkthrough,
      purpleWalkthrough,
      yellowWalkthrough
    );
  };

  const breadcrumbs = [
    { name: "Dashboard", link: "/" },
    { name: "Scenario", link: "/users" },
    { name: "Create ", link: "/createscenarios" },
  ];

  const teamChangeHandler = (value) => {
    const updatedData = data.map((team) => ({
      ...team,
      active: team.color === value,
    }));
    setData(updatedData);
  };

  const addMoreFlagButtonHandler = () => {
    const activeTeam = data?.find((team) => team.active);
    const index = activeTeam?.questionInformation.length;

    if (activeTeam) {
      const newQuestionInformation = {
        name: "",
        description: "",
        hint: "",
        score: 1,
        index: index,
      };

      const updatedData = data.map((team) => {
        if (team.active) {
          return {
            ...team,
            questionInformation: [
              newQuestionInformation,
              ...team.questionInformation,
            ],
          };
        } else {
          return team;
        }
      });

      setData(updatedData);
    }
  };

  const updateFieldHandler = (index, field, value) => {
    const updatedData = [...data];
    updatedData.forEach((team) => {
      if (team.active) {
        team.questionInformation[index][field] = value;
      }
    });
    setData(updatedData);
  };

  const onSubmit = async () => {
    return await handleData();
  };

  const onDeleteQuestionHandler = (questionIndex) => {
    const updatedData = data.map((team) => {
      if (team.active) {
        if (team.questionInformation.length === 1) {
          return {
            ...team,
            questionInformation: [
              {
                name: "",
                description: "",
                hint: "",
                score: 1,
              },
            ],
          };
        } else {
          return {
            ...team,
            questionInformation: team.questionInformation.filter(
              (_, index) => index !== questionIndex
            ),
          };
        }
      } else {
        return team;
      }
    });

    setData(updatedData);
  };

  const onFileUpload = (files) => {
    const activeTeam = data.find((team) => team.active);

    if (activeTeam) {
      const updatedData = data.map((team) => {
        if (team.active) {
          return {
            ...team,
            walkthrough: [...team?.walkthrough, ...files],
          };
        } else {
          return team;
        }
      });

      setData(updatedData);
    }
  };

  const deleteFileHandler = (fileIndex) => {
    const activeTeamIndex = data.findIndex((team) => team.active);

    if (activeTeamIndex !== -1) {
      const updatedData = [...data];
      const activeTeam = updatedData[activeTeamIndex];

      if (fileIndex >= 0 && fileIndex < activeTeam.walkthrough.length) {
        activeTeam.walkthrough = activeTeam.walkthrough.filter(
          (_, index) => index !== fileIndex
        );

        updatedData[activeTeamIndex] = activeTeam;
        setData(updatedData);
      }
    }
  };
  // const isAddMoreFlagButtonDisabled = () => {
  //   const activeTeam = data.find((team) => team.active);
  //   if (activeTeam) {
  //     return !activeTeam.questionInformation.some(
  //       (name) =>
  //         name.name &&
  //         name.description &&
  //         name.hint &&
  //         name.score !== undefined
  //     );
  //   }

  //   return true;
  // };

  const isAddMoreFlagButtonDisabled = () => {
    const activeTeam = data?.find((team) => team?.active);
    console.log("activeTeam", activeTeam);
    if (activeTeam.questionInformation.length != 0) {
      const firstQuestion = activeTeam?.questionInformation[0];

      const keysToCheck = Object?.keys(firstQuestion)?.filter(
        (key) => key !== "hint"
      );

      return !keysToCheck?.every(
        (key) => firstQuestion[key] !== undefined && firstQuestion[key] !== ""
      );
    }

    return true;
  };

  const toggleButton = () => {
    setToggle(0);
  };
  return (
    <>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <Stack px={2} py={4}>
        <Box onClick={toggleButton} sx={{ cursor: "pointer" }}>
          <Icons.leftArrow />
        </Box>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography component="h1" variant="h1">
            Create
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <FullScreenDialog
              onSubmit={onSubmit}
              scenarioId={null}
              datas="Save & Continue"
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Stack
          p={2}
          sx={{
            borderRadius: "16px",
            backgroundColor: "#16181F",
            width: "772px",
          }}
        >
          <Box
            gap={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "start",
            }}
          >
            <Teams team={data} cta={teamChangeHandler} />
            <Stack gap={1} sx={{ width: "100%" }}>
              <Typography
                variant="h4"
                sx={{
                  color: "#9C9EA3 !important",
                  fontWeight: "500",
                  order: 0,
                }}
              >
                Milestone Walkthrough
              </Typography>
              <ScenarioWalkthrough
                walkthrough={
                  data.find((team) => team.active)?.walkthrough || []
                }
                onFileUpload={onFileUpload}
                deleteFileHandler={deleteFileHandler}
              />
              <Box my={3} sx={{ border: "1px solid #282C38" }}></Box>
              <Button
                variant="contained"
                style={{
                  width: "fit-content",
                  fontSize: "12px !important",
                  fontWeight: "600 !important",
                  lineHeight: "16px !important",
                  borderRadius: "4px",
                  border: "none",
                  padding: "2px 16px",
                  cursor: isAddMoreFlagButtonDisabled()
                    ? "not-allowed"
                    : "pointer",
                  color: isAddMoreFlagButtonDisabled() ? "#005252" : "#00FFFF",
                  backgroundColor: isAddMoreFlagButtonDisabled()
                    ? "#1C1F28"
                    : "#002929",
                  marginBottom: "16px",
                }}
                onClick={addMoreFlagButtonHandler}
                disabled={isAddMoreFlagButtonDisabled()}
              >
                Add More Milestone
              </Button>
              <MilestoneFlag
                team={data}
                updateFieldHandler={updateFieldHandler}
                onDeleteQuestion={onDeleteQuestionHandler}
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default MileStone;
