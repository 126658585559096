import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import CreateScenarios from "../CreateScenarios";
import ScenarioFlags from "./ScenarioFlags";

import { createScenario } from "../../APIConfig/version2Scenario";
import MileStone from "./Milestone";

const ScenarioCreation = () => {
  const [toggle, setToggle] = useState(0);
  const [form, setForm] = useState({});
  const [flag, setFlag] = useState({});
  const [mile, setMile] = useState({});
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState([]);
  const [inputs, setInputs] = useState({
    scenario_name: "",
    scenario_category_id: "",
    scenario_assigned_severity: "",
    scenario_score: "",
    scenario_time: "",
    scenario_description: "",
    scenario_members_requirement: "",
    type: "",
  });
  const [multipleFile, setMutipleFile] = React.useState([]);
  const [text, setText] = useState("");
  const [toolsAndTechnologies, setToolsAndTechnologies] = useState("");
  const [prerequisites, setPrerequisites] = useState("");
  const [file, setFile] = useState({ pdf: "", jpeg: "" });
 const [selectedValue,setSelectedValue] = useState(0)
  useEffect(() => {
    console.table("form", form.inputs);
  }, [mile, form, flag]);

  function logFormData(formData) {
    formData.forEach(function (value, key) {
    });
  }

  const handleApi = async (
    milestoneObj,
    blueWalkthrough,
    redWalkthrough,
    purpleWalkthrough,
    yellowWalkthrough
  ) => {

    const flagData = JSON.stringify(flag);
    const modifiedFormData = new FormData();
    modifiedFormData.append("name", form?.inputs?.scenario_name);
    modifiedFormData.append("type", form?.inputs?.type);
    modifiedFormData.append("category_id", form?.inputs?.scenario_category_id);
    modifiedFormData.append(
      "severity",
      form?.inputs?.scenario_assigned_severity
    );
    modifiedFormData.append("description", form?.text);
    modifiedFormData.append("objective", form?.toolsAndTechnologies);
    modifiedFormData.append("prerequisite", form?.prerequisites);
    modifiedFormData.append("thumbnail", form?.file?.jpeg);
    if (form?.inputs?.type == "FLAG") {
      modifiedFormData.append("flag_data", milestoneObj);
    } else {
      modifiedFormData.append("milestone_data", milestoneObj);
    }
    if (redWalkthrough?.length >= 1) {
      redWalkthrough.forEach((file) => {
        modifiedFormData.append("red_team_files", file);
      });
    }
    if (blueWalkthrough?.length >= 1) {
      blueWalkthrough.forEach((file) => {
        modifiedFormData.append("blue_team_files", file);
      });
    }
    if (purpleWalkthrough?.length >= 1) {
      purpleWalkthrough.forEach((file) => {
        modifiedFormData.append("purple_team_files", file);
      });
    }
    if (yellowWalkthrough?.length >= 1) {
      yellowWalkthrough.forEach((file) => {
        modifiedFormData.append("yellow_team_files", file);
      });
    }

    const value = await createScenario(modifiedFormData);
    logFormData(modifiedFormData);
    return value;
  };
  const setMileData = (redTeam, blueTeam, yellowTeam, purpleTeam) => {
    // console.log('val1 is here',val1);
    // console.log('val2 is here',val2);
    // console.log('val3 is here',val3);
    // console.log('val3 is here',val4);

    setMile({
      red_team: redTeam,
      blue_team: blueTeam,
      yellow_team: yellowTeam,
      purple_team: purpleTeam,
    });
  };

  const components = {
    0: (
      <CreateScenarios
        setToggle={setToggle}
        setForm={setForm}
        image={image}
        setImage={setImage}
        category={category}
        setCategory={setCategory}
        inputs={inputs}
        setInputs ={setInputs}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        multipleFile={ multipleFile}
        setMutipleFile={setMutipleFile}
        text={text}
        setText={setText}
        toolsAndTechnologies={ toolsAndTechnologies}
        setToolsAndTechnologies={setToolsAndTechnologies}
        prerequisites={prerequisites}
        setPrerequisites={setPrerequisites}
        file={file}
        setFile={setFile}
      />
    ),
    1: (
      <MileStone
        setToggle={setToggle}
        setMileData={setMileData}
        handleApi={handleApi}
        mile={mile}
      />
    ),
    2: (
      <ScenarioFlags
        setToggle={setToggle}
        setFlag={setFlag}
        handleApi={handleApi}
      />
    ),
  };

  return <Stack>{components[toggle]}</Stack>;
};

export default ScenarioCreation;
