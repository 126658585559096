import { Backdrop, Button, Stack, Typography } from "@mui/material"
import uploadImg from '../../components/assests/uploadIcon.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadCtfFile } from "../../APIConfig/CtfConfig";
import { toast } from "react-toastify";
import { useState } from "react";
import StepperHorizontal from "../../components/Stepper";
import LoaderImg from "../../components/ui/loader";

const UploadFile = () => {
    const [isActive, setIsActive] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const id = location?.state?.ctf_id;
    const [file, setFile] = useState();

    const data = {
        index: 1,
        steps: ['Fill Form', 'Upload Machine']
    }

    const fileUpload = async () => {
        ////console.log("fileData", fileData);
        try {
            setIsActive(true)
            // handleFileValidation();
            const response = await uploadCtfFile(file, id);

            toast.success("Machine created successfully");

            setFile("");

            if (response) {
                navigate('/uploadmachine/uploadending');
            }

        }
        catch (err) {
            ////console.log("err", err);
            setIsActive(false)
            const obj = err.response.data.errors;
            for (let i in obj) {
                toast.error(i.charAt(0).toUpperCase() + i.slice(1).replace(/_/g, ' ') + " - " + obj[i]);
            }
        }
    }

    const handlechangeJpeg = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            return;

        }
    }
    return (
        <Stack margin={5}>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isActive}
            >
                <LoaderImg />

            </Backdrop>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h1">Machine Details</Typography>
                <Button size="l" variant="contained" onClick={fileUpload} color="secondary" sx={{ borderRadius: '50px', border: '2px solid #0ff', borderRadius: '16px' }}>
                    Save & Continue
                </Button>
            </Stack>
            <Stack sx={{ alignItems: 'center', justifyContent: 'center', my: 5, marginTop: 2 }}>
                <StepperHorizontal data={data} />
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: 308, backgroundColor: "custom.main", width: '70%', borderRadius: '16px', marginTop: 8 }}>

                    {
                        file ? <Typography sx={{ color: '#fff', my: 4 }}>
                            {file && `${file?.name}`}
                        </Typography> :
                            <Button component="label" variant="text" sx={{ color: '#fff', backgroundColor: 'custom.main', width: '588px', my: 4 }}>
                                <Stack backgroundColo="custom.main" style={{ border: '1px dashed #12464C', height: '152px', width: '588px', justifyContent: 'center', alignItems: 'center', gap: 4, padding: 4 }}>
                                    <img src={uploadImg} width="24px" height="24px" alt="img" />
                                    <Typography variant="body" style={{ textAlign: 'center' }}>Browse and chose the Image you want to upload from your computer</Typography>
                                    <input type="file" hidden onChange={handlechangeJpeg} name="ctf_thumbnail" />
                                </Stack>
                            </Button>
                    }
                </Stack>


            </Stack>
        </Stack>
    )
}

export default UploadFile