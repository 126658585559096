import axios from "axios";

import { refreshToken } from "../APIConfig/CtfConfig";
import { startTransition } from "react";

const token = localStorage.getItem("access_token");
// const API="http://192.168.1.41:8000/"
// const API = "http://192.168.1.14:8000/";
// const API = "http://10.1.20.19:8000/";
// const API = "http://10.1.20.19:8000/";
// const API = "http://10.1.20.181:7000/";
// const API = "http://192.168.1.7:8000/";
// const API = "https://cyberrangebackend1.bhumiitech.com/";

const APIVERSION2 = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
});

// Add a request interceptor
APIVERSION2.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    if (token) {
      // useTokenHandling()
    }

    // You can modify the request configuration here if needed
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
APIVERSION2.interceptors.response.use(
  function (response) {
    // Do something with response data
    // console.log("Response Interceptor:", response);
    // You can modify the response data here if needed
    return response;
  },
  async function (error) {
    // Do something with response error
    const currentPath = window.location?.pathname;

    if (
      error?.response?.data?.exception ===
      "An error of type InvalidUser occurred: Expired Token."
    ) {
      // Wrap the data fetching in startTransition to make it low-priority
      startTransition(() => {
        refreshToken(currentPath);
      });
    }
    // if (error.response && error.response.status === 401) {
    //   // Token expired or not authorized
    //   // Redirect to the login page
    //   useNavigate("/login");
    // }
    return Promise.reject(error);
  }
);

export default APIVERSION2;
