import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography } from "@mui/material";

import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  MemberWallApi,
  UserProfileApi,
  registerUser,
} from "../../../APIConfig/CtfConfig";
import Popup from "./Popup";
import { toast } from "react-toastify";
import { UserDelete } from "../../../APIConfig/adminConfig";

export default function UserList({ mem }) {
  const [users, setUser] = React.useState("");
  const [member, setMember] = React.useState([]);
  const [reload, setReload] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const value = await MemberWallApi();
      //console.log('value is here',value.data)
      setMember(value?.data);
    };
    getData();
  }, [reload]);

  const handleChangeSign = async (sign) => {
    try {
      const response = await registerUser(sign);
      //console.log('response', response)
      toast.success("user has been added successfully!!!");
      setReload(!reload);
    } catch (error) {
      // //console.log(error);
      const obj = error.response.data.errors;
      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
            i.slice(1).replace(/_/g, " ") +
            " - " +
            obj[i]
        );
      }
      setReload(!reload);
    }
  };

  const handleClick = async (user_id) => {
    //console.log("userId", user_id);
    const token = localStorage.getItem("access_token");
    if (token) {
      const user = jwtDecode(token);
      const userDetails = await UserProfileApi(user_id);
      setUser(userDetails?.data?.data);
      //console.log("user details 1", userDetails);
      navigate("/admin/userupdate", { state: { the: user_id } });
      //console.log("user details 2", users)
    }
  };

  const heading = [
    { title: "Name", align: "center" },
    { title: "Squad-Score", align: "center" },
    { title: "Solo-Score", align: "center" },
    { title: "Role", align: "right" },
    { title: "Delete User", align: "right" },
  ];

  const handleDeleteUser = async (userId) => {
    try {
      const value = await UserDelete(userId);
      //console.log('value is here',value.data)
      toast.success("user has been deleted successfully");
      setReload(!reload);
    } catch (error) {
      const obj = error?.response?.data?.errors;
      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
            i.slice(1).replace(/_/g, " ") +
            " - " +
            obj[i]
        );
      }
    }
  };
  return (
    <Stack padding={5} gap={4}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">User Details</Typography>
        <Popup handleChangeSign={handleChangeSign} />
      </Stack>
      <Stack
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          overflow: "scroll",
        }}
      >
        <Stack sx={{ width: "100%", marginTop: 4 }}>
          <Stack
            direction="row"
            sx={{
              bgColor: "primary.other",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {heading.map((item, index) => {
              return (
                <Typography
                  key={index}
                  width="25%"
                  textAlign={item.title == "Name" ? "left" : "center"}
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                  variant="h4"
                >
                  {item.title}
                </Typography>
              );
            })}
          </Stack>

          <Stack
            sx={{
              width: "100%",
              gap: 2,
              alignItems: "center",
              mt: 2,
              overflowY: "scroll",
              height: "400px",
            }}
            className="example"
          >
            {member?.map((row, index) => (
              <Stack
                direction="row"
                key={index}
                sx={{
                  overflow: "visible",
                  backgroundColor: index % 2 == 0 ? "#1C1F28" : null,
                  width: "100%",
                  alignItems: "center",
                  height: "48px",
                  borderRadius: index % 2 == 0 ? "12px" : "0px",
                  py: 4,
                  px: 1,
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.01)",
                  },
                }}
                // key={index}
              >
                {/* profile with name */}
                <Stack
                  direction="row"
                  sx={{ gap: 1, width: "20%" }}
                  onClick={() => handleClick(row.user_id)}
                >
                  <Box
                    component="img"
                    src={row?.user_avatar}
                    alt="image"
                    sx={{ width: "24px", height: "24px" }}
                  />
                  <Typography sx={{ textAlign: "center" }}>
                    {row?.user_full_name}
                  </Typography>
                </Stack>
                {/* scenario score */}
                <Typography sx={{ textAlign: "center", width: "20%" }}>
                  {row.user_profile_detail.user_scenario_score}
                </Typography>
                {/* ctf score */}
                <Typography sx={{ textAlign: "center", width: "20%" }}>
                  {row.user_profile_detail.user_ctf_score}
                </Typography>
                <Typography sx={{ textAlign: "center", width: "20%" }}>
                  {row.user_role}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: "20%" }}
                  onClick={() => handleDeleteUser(row.user_id)}
                >
                  Delete
                </Button>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
