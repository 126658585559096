import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  Avatar,
  Grid,
  Backdrop,
  CircularProgress,
  Tab,
} from "@mui/material";
import { styled } from "@mui/system";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  downloadReport,
  endGameV2,
  whiteTeamVersion2,
} from "../../../APIConfig/version2Scenario";
import reImg from "../ActiveGame/2671.png";
import CustomModal from "../../../components/ui/CustomModal";
import { generateReportPDF } from "../../WinningWall/PlayerProfile";
import ErrorHandler from "../../../ErrorHandler";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WinningWall from "../../../components/winningWall/WinningWall";
import { toast } from "react-toastify";
import LiveScore from "./LiveScore";
import { ConstructionOutlined } from "@mui/icons-material";

const WhitePlayer = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [val, setVal] = React.useState("1");
  const handleChange = (event, newValue) => {
    setVal(newValue);
  };
  useEffect(() => {
    getValue();
  }, []);

  const getValue = async () => {
    try {
      console.log('value is here')
      setIsActive(true)
      const value = await whiteTeamVersion2();
      console.log("value",value)
      if(value.data.type ==="flag_data"){
        setVal("2")
      }
      setData(value?.data);
      
      setIsActive(false)
    } catch (error) {
      ErrorHandler(error);
    }
  };
  const value = ["Severity", "Scenario Type"];
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));
  const title = data?.name;
  const profileCard = [
    { item: data?.severity },
    { item: (data?.type=="flag_data") ? "Flag based":"Milestone based" },
    // { item: "red , blue , white " },
  ];

  const cardValue = [
    {
      teamName: "Red Team",
      color: "red",
      team: data?.participants_data?.red_team,
    },
    {
      teamName: "Blue Team",
      color: "blue",
      team: data?.participants_data?.blue_team,
    },
    {
      teamName: "Purple Team",
      color: "purple",
      team: data?.participants_data?.purple_team,
    },
    {
      teamName: "Yellow Team",
      color: "yellow",
      team: data?.participants_data?.yellow_team,
    },
  ];
  const handleClickOpen = () => {
    navigate("/scenarios/scenario_category");
  };

  const ProfileCard = ({ item, scenarioId }) => {
    const [points, setPoints] = useState(1);
    useEffect(() => {
     
    }, [points]);
    const formatCount = (value) => String(value).padStart(2, "0");
    const flagPoints = (item.milestone_approved / item.total_milestone) * 100;
    const hintPoints = (item.hint_used_count / item.total_hint) * 100;
    return (
      <Stack direction="row" gap={2} width="100%">
        <Avatar alt="Remy Sharp" src={item?.participant_avatar} />
        <Stack width="100%">
          <Typography sx={{ color: "#EAEAEB !important" }}>
            {item.participant_name}
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography sx={{ color: "#BCBEC1 !important" }}>
              Milestone Achieved:
            </Typography>
            <Typography>
              {item.milestone_approved}/{item.total_milestone}
            </Typography>
          </Stack>
          <BorderLinearProgress variant="determinate" value={flagPoints} />
          <Stack direction="row" gap={1}>
            <Typography sx={{ color: "#BCBEC1 !important" }}>
              Hint Used:
            </Typography>
            <Typography>
              {item.hint_used_count}/{item.total_hint}
            </Typography>
          </Stack>
          <BorderLinearProgress variant="determinate" value={hintPoints} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              width="30%"
              backgroundColor="#16181F"
              justifyContent="center"
              alignItems="center"
              sx={{ borderRadius: "12px", my: 1, padding: 0.5 }}
            >
              <Typography>
                {item?.total_obtained_score}/{item?.total_score}
              </Typography>
            </Stack>
            <Button
              onClick={() =>
                navigate(
                  `/ActiveGameSenario/console_page/${scenarioId}/${item.participant_id}`
                )
              }
            >
              (View Console)
            </Button>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const generateReportPDFfunction = async (actScenId, userId) => {
   
    generateReportPDF(actScenId, userId);
    navigate("/");
  };

  const endGameConsole = async () => {
    setIsActive(true);
    const value = await endGameV2(data?.active_scenario_id);
    if (value) {
      setIsActive(false);
      setIsEnd(true);
    }
  };

  

  return (
    <Stack width="90%" alignContent="center" justifyContent="center">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isActive}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CustomModal
        open={isEnd}
        sx={{ py: 5.5, maxWidth: "650px" }}
        hideCloseIcon
        disableExternalClick
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" color={"#EAEAEB !important"}>
            Your Game Ended Successfully.{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            justifyContent: "center",
            mt: "56px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/report/${data?.active_scenario_id}/${data?.started_by}`);
            }}
          >
            Download Report
          </Button>
          <Button
            onClick={() => {
              setIsEnd(false);
              navigate("/");
            }}
          >
            Close Window
          </Button>
        </Box>
      </CustomModal>

      { !data?.thumbnail_url ? (
        <Stack justifyContent="center" alignContent="center">
          <Stack alignItems="center" justifyContent="center" padding={8}>
            <img
              src={reImg}
              alt="2671.png"
              style={{ width: "269px", height: "179px" }}
            />
            <Typography
              style={{ fontSize: 15, color: "#ACACAC" }}
              sx={{ mb: 0.5 }}
              variant="h14"
            >
              There are no active running Squad games at the moment. When you
              participate in any Squad, that game will be listed here. If you
              wish to play now, you can start by clicking on the 'Play Now'
              button
            </Typography>
          </Stack>
          <Stack justifyContent="center" alignItems="center">
            <Button
              sx={{ display: "flex", fontWeight: "bold", width: "180px" }}
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
            >
              Play Now
            </Button>
          </Stack>
        </Stack>
      ) : 
      (
        <Stack
          justifyContent="center"
          alignItems="center"
          padding={5}
          backgroundColor="#16181F"
          borderRadius={2}
          width="90%"
        >
          <Stack width="100%" gap={5}>
            <Stack
              width="100%"
              borderBottom="1px solid #282c38"
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                width="100%"
                direction="row"
                gap={2}
                padding={5}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  sx={{
                    height: "224px",
                    width: "180px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                  src={data?.thumbnail_url}
                />
                <Stack gap="12px" width="100%">
                  <Typography variant="h2" sx={{ color: "#EAEAEB !important" }}>
                    {title}
                  </Typography>
                  <Stack direction="row" gap={2} width="100%">
                    <Stack gap={0.5}>
                      {value?.map((item, index) => (
                        <Typography
                          key={index}
                          variant="h4"
                          sx={{ color: "#BCBEC1 !important" }}
                        >
                          {item}
                        </Typography>
                      ))}
                    </Stack>

                    <Stack gap={0.5}>
                      {profileCard?.map((item, index) => (
                        <Typography
                          key={index}
                          variant="h4"
                          sx={{ color: "#BCBEC1 !important" }}
                        >
                          :&nbsp;&nbsp;&nbsp;{item?.item}
                        </Typography>
                      ))}
                    </Stack>
                  </Stack>
                  <Button
                   sx={{display:(data?.type=="flag_data" ? "None":"flex")}}
                    onClick={endGameConsole}
                    variant="outlined"
                    endIcon={<PowerSettingsNewIcon />}
                  >
                    End Game
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            {/* tabs details */}
            <Box
              sx={{
                width: "100%",
                typography: "body1",
              }}
            >
              <TabContext value={val} width="100%">
                <Stack
                  direction="row"
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography>Game Overview</Typography>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {
                       data.type !=="flag_data" && (
                        <Tab label="Detailed View" value="1" />
                      )
                    }
                   
                    <Tab label="Live Leaderboard" value="2" />
                  </TabList>
                </Stack>
                {
                  data.type !=="flag_data" && (
                    <TabPanel value="1">
                    <Grid
                      container
                      width="100%"
                      direction="row"
                      padding={1}
                      justifyContent="space-between"
                    >
                      {cardValue.map((item, index) => {
                        const teams = item.team;
  
                        return (
                          <Grid
                            item
                            width="49%"
                            gap={2}
                            backgroundColor="#1C1F28"
                            padding={2}
                            borderRadius={1}
                            sx={{ my: 1 }}
                            key={index}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Stack direction="row" alignItems="center" gap={1}>
                                <Typography
                                  sx={{ color: `${item.color} !important` }}
                                  variant="h3"
                                >
                                  {item.teamName}
                                </Typography>
                                {/* <Button
                            onClick={() =>
                              navigate("/ActiveGameSenario/console_page")
                            }
                          >
                            (view console)
                          </Button> */}
                              </Stack>
                              {/* <NotificationsNoneOutlinedIcon
                                sx={{ cursor: "pointer" }}
                              /> */}
                            </Stack>
                            { teams?.map((item, index) => (
                              <>
                                <ProfileCard
                                  item={item}
                                  scenarioId={data?.active_scenario_id}
                                />
                                <Stack
                                  borderBottom="1px solid #282c38"
                                  sx={{ my: 1 }}
                                ></Stack>
                              </>
                            ))}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </TabPanel>
                  )
                }
               
                <TabPanel value="2">
                  <Stack width="100%" border="1p solid white">
                    <LiveScore group={data?.active_scenario_id} />
                  </Stack>
                </TabPanel>
              </TabContext>
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default WhitePlayer;
