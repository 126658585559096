
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar";
import Demo from "../../components/navbar/Left";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../RTK/features/userDetails/userSlice";

const SenarioMachineLayout = () =>{
  
    return(
      <Stack direction="row">
      {/* <Navbar/> */}
      {/* <Demo /> */}
      <Outlet /> 
    </Stack>
    )
}

export default SenarioMachineLayout