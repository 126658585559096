import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Categories from "../../components/Categories";
import { activeGameList, getCategory } from "../../APIConfig/CtfConfig";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import Card from "../../components/Card";
import Card from "../../components/Card";
import image1 from "../../components/assests/Frame 165.png";
import { useEffect } from "react";
import { useState } from "react";
import reImg from "../ActiveGame/2671.png";
import BreadCrumbs from "../../components/navbar/BreadCrumb";




export default function ActiveGame() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);

  const handleClickOpen = () => {
    navigate("/categories/ctf_category")
  };
  const [scenariosDatas, setScenarioDatas] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const data = await activeGameList();
      setScenarioDatas(data?.data);
    };
    getData();
  }, [load]);
  const value = [
    {
      title: "hardware",
      img: "https://www.seiu1000.org/sites/main/files/main-images/camera_lense_0.jpeg",
      desp: "asdgfasdfasdf",
    },
    {
      title: "mobile",
      img: "https://www.seiu1000.org/sites/main/files/main-images/camera_lense_0.jpeg",
      desp: "asdh asdkahsdf  aisdhfajsd f asdghaisd g aisdhfiojashd fpiu ahpg",
    },
    {
      title: "cypto",
      img: "https://www.seiu1000.org/sites/main/files/main-images/camera_lense_0.jpeg",
      desp: "sdfhaks asd fiausdhf aisdhfioah dfuioaheuf",
    },
    {
      title: "web",
      img: "https://www.seiu1000.org/sites/main/files/main-images/camera_lense_0.jpeg",
      desp: "asdfasfd asdfasdfasdf asdfasdf  asdfasfdasdf",
    },
  ];
  const breadcrumbs = [
    {
      name: 'Dashboard',
      link: "/"
    },
    {
      name: 'Solo',
      link: "/categories/ctf_category"
    },
    {
      name: 'Active Game',
      link: "/activegame"
    }

  ];



  return (
    <Stack>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
    <Stack spacing={2} margin={5} display="flex" width="96%">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h1">Active Solo Games</Typography>
      </Stack>
      <Stack>
        {scenariosDatas?.length > 0 ? (
          scenariosDatas?.map((item, index) => {
            const items = {
              scenarioName: item.ctf_name,
              description: item.ctf_description,
              category: item.ctf_category_name,
              points: item.ctf_score,
              time: item.ctf_time,
              severity: item.ctf_assigned_severity,
              img: item.ctf_thumbnail,
              gameId: item.ctf_game_id



            }
            return <Card items={items} screen="activegame" setLoad={setLoad} load={load} key={index}/>;

          })
        ) : (
          <Stack
            justifyContent="center"
            alignContent="center"
            width="100%"
          >
            <Stack alignItems="center" justifyContent="center" padding={8}>
              <img
                src={reImg}
                alt="2671.png"
                style={{ width: "269px", height: "179px" }}
              />
              <Typography
                style={{ fontSize: 15, color: "#ACACAC" }}
                sx={{ mb: 0.5 }}
                variant="h14"
              >
                There are no active running Solo games at the moment. When you
                participate in any Solo, that game will be listed here. If you wish to
                play now, you can start by clicking on the 'Play Now' button
              </Typography>
            </Stack>
            <Stack justifyContent='center' alignItems='center' >
              <Button
                sx={{ display: "flex", fontWeight: "bold", width: "180px" }}
                variant="contained"
                color="secondary"
                onClick={handleClickOpen}
              >
                Play Now
              </Button>

            </Stack>
          </Stack>
        )}
      </Stack>

    </Stack>
    </Stack>
  );
}
