import axios from "axios";

import { refreshToken } from "../APIConfig/CtfConfig";
import { startTransition } from "react";

const token = localStorage.getItem("access_token")
// Custom hook to handle token expiration and navigation


 const API = axios.create({
  baseURL: process.env.REACT_APP_API_PATH
});

// Add a request interceptor
API.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if(token){
      // useTokenHandling()
    }
    
    // You can modify the request configuration here if needed
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  function (response) {
    // Do something with response data
    // console.log("Response Interceptor:", response);
    // You can modify the response data here if needed
    return response;
  },
  async function (error) {
    // Do something with response error
    const currentPath = window.location?.pathname
 
    if(error?.response?.data?.exception ==="An error of type InvalidUser occurred: Expired Token."){
       // Wrap the data fetching in startTransition to make it low-priority
    startTransition(() => {
      refreshToken(currentPath)
    });
    
    }
    // if (error.response && error.response.status === 401) {
    //   // Token expired or not authorized
    //   // Redirect to the login page
    //   useNavigate("/login");
    // }
    return Promise.reject(error);
  }
);

export default API;
