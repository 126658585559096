import React from 'react';
import BreadCrumbs from "../../../components/navbar/BreadCrumb";
import CreateCategories from "../common/createCategories";

const CreateCTFCategories = () => {
  const breadcrumbs = [
    {
      name: 'Dashboard',
      link: "/"
    },
    {
      name: 'Categories',
      link: "/admin/ctf-categories"
    }, {
      name: 'Create Category',
      link: "/admin/create-ctf-categories"
    },
  ];
  return (
    <>
      <BreadCrumbs breadcrumbs={breadcrumbs}/>
      <CreateCategories template='Create CTF' />
    </>
  );
};

export default CreateCTFCategories;