import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import LoginLayout from "../Layout/Auth/LoginLayout";
import DashboardLayout from "../Layout/Dasboard/DashboardLayout";
// import Dashboard from "../container/dashboard";
import WinningWall from "../container/WinningWall";
import PlayerProfile from "../container/WinningWall/PlayerProfile";
import Badges from "../container/Badges";
import MachineLayout from "../Layout/Machine/MachineLayout";
import MachineProfile from "../container/machine";
import UploadMachine from "../container/uploadMachine";
import UploadFile from "../container/uploadMachine/uploadFile";
import UploadEnding from "../container/uploadMachine/UploadEnding";
import LoginPage from "../Auth/login";
import { Register } from "../Auth/register";
import OtpBox from "../OTPBox/otp";
import Phonenum from "../OTPBox/Phonenum";
import jwtDecode from "jwt-decode";

import ActiveGame from "../container/ActiveGame";
import Gamedetails from "../container/dashboard/GameDetails";
import CreateScenarios from "../container/CreateScenarios";
import Popup from "../container/machine/popup";

import SenariosUploadEnding from "../container/Scenarios/ScenarioDetails/endingPage";
import ActiveGameScenario from "../container/CreateScenarios/scenarioActiveGame";
import MachineProfileSenario from "../container/Scenarios/ScenarioDetails/senarioConsole";
import SenarioMachineLayout from "../Layout/scenarioConsole";
import ActiveGameSenario from "../container/Scenarios/ActiveGame";
import UnderConstructorLayout from "../Layout/UnderConstructionLayout";
import Category from "../container/Admin/CTF/CreateCategory";
import Map from "../container/Admin/CTF/Mapping";
import CategoryScenario from "../container/Admin/Senario/CreateCategory";
import ScenarioApproval from "../container/Admin/Senario/SceanrioApproval";
import ScenarioUnApproval from "../container/Admin/Senario/ScenarioUnApproval";
import { useJwt } from "react-jwt";
import UserList from "../container/Admin/user/UserLIst";
import { UserUpdate } from "../container/Admin/user/UserUpdate";
import CtfCategory from "../container/CTF/CtfCategory";
import ScenarioCategory from "../container/Scenarios/ScenarioCategory";
import MobileView from "../components/MobileView";
import CtfUpdate from "../container/Admin/CTF/CTF-UPDATE";
import UpadateCtf from "../container/Admin/CTF/CTF-UPDATE/upadeCtf";
import NotificationPage from "../components/navbar/NotificationPage";
import UpdateScneario from "../container/Admin/Senario/senario-upadate";
import UnderConstruction from "../components/errorPages/UnderConstruction";
import ErrorPageLayout from "../Layout/ErrorPages/ErrorPageLayout";
import ServerError from "../components/errorPages/ServerError";
import CTFCategories from "../container/Admin/CTF/CTFCategories";
import CreateCTFCategories from "../container/Admin/CTF/createCTFCategories";
import ScenarioCategories from "../container/Admin/Senario/scenarioCategories";
import CreateScenarioCategories from "../container/Admin/Senario/CreateScenarioCategories";
import Users from "../container/Admin/users/Users";
import AddUser from "../container/Admin/users/AddUser";
import EditCTFCategories from "../container/Admin/CTF/EditCTFCategories";
import EditScenarioCategories from "../container/Admin/Senario/senario-upadate/EditScenarioCategories";
import UpdateUser from "../container/Admin/users/UpdateUser";
import LoaderImg from "../components/ui/loader";
import ScenarioRequests from "../container/Admin/Senario/ScenarioRequests";
import CTFRequests from "../container/Admin/CTF/CTFRequests";
import CreateCTFRequest from "../container/Admin/CTF/CreateCTFRequest";
import ForgotPassword from "../container/ForgotPassword";
import OngoingCTFChallenge from "../container/Admin/ongoingCTFChallenges";
import MakeChallengeCTF from "../container/Admin/challenge/make-challenge-CTF";
import MakeChallengeScenario from "../container/Admin/challenge/make-challenge-scenario";
import { useDispatch, useSelector } from "react-redux";
import { setPathUrl } from "../RTK/features/path";
import Dashboard from "../container/dashboard";
import ScenarioDetails from "../container/Scenarios/ScenarioDetails";
import PageNotFound from "../components/errorPages/PageNotFound";
import ScenarioCreation from "../container/ScenarioCreation";
import WhiteTeamConsole from "../container/Scenarios/ActiveGame/WhiteConsole/whiteConsole";
import ScenarioCategoryVersion2 from "../container/aNewVersion/ScenarioCategory";
import ScenarioDetailsVersion2 from "../container/aNewVersion/ScenarioDetails";
import ScenarioPage from "../container/Scenarios/ScenarioDetails/scenarioPage";
import CorporateRequests from "../container/Admin/corporate/CorporateRequests";
import Report from "../container/Report/Report";
// import Reports from "../container/Report/Report";

const Router = () => {
  const [sizeState, setSizeState] = useState("desktop");
  const location = useLocation();
  const dispatch = useDispatch();
  const pathurl = useSelector((state) => state.pathUrl.mode.currentPath);
  const currentPath = location?.pathname;

  const lowercasedUrl = currentPath.toLowerCase();
  const word = "auth";
  // Check if the lowercased URL contains the lowercased word
  const authvalue = lowercasedUrl.includes(word.toLowerCase());
  useEffect(() => {
    if (window.innerWidth <= 900) {
      setSizeState("mobile");
    } else {
      setSizeState("desktop");
    }

    if (!token) {
      if (!authvalue) {
        dispatch(setPathUrl({ currentPath }));
      }
    }
  }, []);
  const changeNavbar = () => {
    if (window.innerWidth <= 900) {
      setSizeState("mobile");
    } else {
      setSizeState("desktop");
    }
  };

  window.addEventListener("resize", changeNavbar);
  const token = localStorage.getItem("access_token");
  const { decodedToken, isExpired } = useJwt(
    localStorage.getItem("access_token")
  );
  let verified = false;
  let isLogin = false;

  if (token) {
    const user = token && jwtDecode(token);
    verified = user?.is_verified;
    isLogin = token;
  }

  return useRoutes([
    {
      path: "/auth",
      element:
        sizeState == "mobile" ? (
          <MobileView />
        ) : (
          <LoginLayout /> || <DashboardLayout />
        ),
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
      ],
    },

    {
      path: "/verify",
      element:
        sizeState == "mobile" ? (
          <MobileView />
        ) : (
          <LoginLayout /> || <DashboardLayout />
        ),
      children: [
        { path: "verification", element: <OtpBox /> },
        { path: "mobilenumber", element: <Phonenum /> },
      ],
    },

    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: (
            <React.Suspense fallback={<LoaderImg />}>
              <Dashboard />
            </React.Suspense>
          ),
        },
      ],
    },

    {
      path: "/verify",
      element:
        sizeState == "mobile" ? (
          <MobileView />
        ) : (
          <LoginLayout /> || <DashboardLayout />
        ),
      children: [
        { path: "verification", element: <OtpBox /> },
        { path: "mobilenumber", element: <Phonenum /> },
      ],
    },

    {
      path: "/badges",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <Badges />,
        },
      ],
    },
    {
      path: "/winningwall",
      element:
      
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <WinningWall />,
        },
      ],
    },
    {
      path: "/playerProfile",
      element:
      
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <PlayerProfile />,
        },
      ],
    },
    {
      path: "/scenarios",
      element: verified ? <DashboardLayout /> : <Navigate to="/auth/login" />,
      children: [
        {
          path: "scenariodetails/:userId",
          element: (
            <React.Suspense fallback={<LoaderImg />}>
              <ScenarioDetails />
            </React.Suspense>
          ),
        },
        {
          path: "scenario_category",
          element: <ScenarioCategory />,
        },
      ],
    },
    {
      path: "/categories",
      element: verified ? <DashboardLayout /> : <Navigate to="/auth/login" />,
      children: [
        {
          path: "gamedetails/:userId",
          element: <Gamedetails />,
        },
        {
          path: "ctf_category",
          element: <CtfCategory />,
        },
      ],
    },
    {
      path: "/machineprofile/:userId",
      element:
        isLogin && verified ? <MachineLayout /> : <Navigate to="/auth/login" />,
      children: [
        {
          path: "",
          element: <MachineProfile />,
        },
      ],
    },
    {
      path: "/activegame",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <ActiveGame />,
        },
      ],
    },
    {
      path: "/createscenarios",
      element:
        // isLogin && verified
        true ? <DashboardLayout /> : <Navigate to="/auth/login" />,
      children: [
        {
          path: "",
          element: <ScenarioCreation />,
          // element: <ScenarioForm />
        },
        {
          path: "endingpage",
          element: <SenariosUploadEnding />,
        },
        {
          path: "activesenario",
          element: <ActiveGameScenario />,
        },
      ],
    },

    {
      path: "/senarioconsole/:id",
      element:
        isLogin && verified ? (
          <SenarioMachineLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <MachineProfileSenario />,
        },
      ],
    },
    {
      path: "/uploadmachine",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <UploadMachine />,
        },
        {
          path: "/uploadmachine/uploadfile",
          element: <UploadFile />,
        },
        {
          path: "/uploadmachine/uploadending",
          element: <UploadEnding />,
        },
        {
          path: "popup",
          element: <Popup />,
        },
      ],
    },

    { path: "*", element: <PageNotFound /> },
    {
      path: "/UnderConstructionpage",
      element:
        isLogin && verified ? (
          <UnderConstructorLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <UnderConstruction />,
        },
      ],
    },
    {
      path: "/notification",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <NotificationPage />,
        },
      ],
    },

    {
      path: "/admin",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <Category />,
        },
        {
          path: "ctf-categories",
          element: <CTFCategories />,
        },
        {
          path: "create-ctf-categories",
          element: <CreateCTFCategories />,
        },
        {
          path: "create-ctf-categories/:categoryId",
          element: <EditCTFCategories />,
        },
        {
          path: "scenario-categories",
          element: <ScenarioCategories />,
        },
        {
          path: "create-scenario-categories",
          element: <CreateScenarioCategories />,
        },
        {
          path: "create-scenario-categories/:categoryId",
          element: <EditScenarioCategories />,
        },
        {
          path: "corporate-requests",
          element: <CorporateRequests />,
        },
        {
          path: "scenario-requests",
          element: <ScenarioRequests />,
        },
        {
          path: "ctf-requests",
          element: <CTFRequests />,
        },
        {
          path: "ctf-requests/:categoryId",
          element: <CreateCTFRequest />,
        },
        {
          path: "mapping",
          element: <Map />,
        },
        {
          path: "create_scenario_category",
          element: <CategoryScenario />,
        },
        {
          path: "scenario_approval",
          element: <ScenarioApproval />,
        },
        {
          path: "scenario_un_approval",
          element: <ScenarioUnApproval />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "add-user",
          element: <AddUser />,
        },
        {
          path: "user-get-ctf/:userId",
          element: <UpdateUser />,
        },
        {
          path: "userlist",
          element: <UserList />,
        },
        {
          path: "userupdate",
          element: <UserUpdate />,
        },
        {
          path: "ctf_update",
          element: <CtfUpdate />,
        },
        {
          path: "ctf_update_page/:userId",
          element: <UpadateCtf />,
        },
        {
          path: "UpdateScneario/:userId",
          element: <UpdateScneario />,
        },
        {
          path: "scenario_category_update",
          element: <ScenarioCategory variant="edit" />,
        },
        {
          path: "ctf_category_edit",
          element: <CtfCategory variant="edit" />,
        },
      ],
    },
    {
      path: "/error",
      element: <ErrorPageLayout />,
      children: [
        { path: "under_construction", element: <UnderConstruction /> },
        { path: "page_not_found", element: <PageNotFound /> },
        { path: "server_error", element: <ServerError /> },
      ],
    },

    {
      path: "/verify",
      element: sizeState == "mobile" ? <MobileView /> : <LoginLayout />,
      children: [
        { path: "verification", element: <OtpBox /> },
        { path: "mobilenumber", element: <Phonenum /> },
      ],
    },

    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: (
            <React.Suspense fallback={<LoaderImg />}>
              <Dashboard />
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/badges",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <Badges />,
        },
      ],
    },
    {
      path: "/winningwall",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <WinningWall />,
        },
      ],
    },
    {
      path: "/report/:corporate_id/:user_id",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <Report />,
        },
      ],
    },
    ,
    {
      path: "/scenarios",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "scenariodetails/:userId",
          element: (
            <React.Suspense fallback={<LoaderImg />}>
              <ScenarioDetails />
            </React.Suspense>
          ),
        },
        {
          path: "scenario_category",
          element: <ScenarioCategory />,
        },
      ],
    },
    {
      path: "/categories",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "gamedetails/:userId",
          element: <Gamedetails />,
        },
        {
          path: "ctf_category",
          element: <CtfCategory />,
        },
      ],
    },
    {
      path: "/machineprofile/:userId",
      element:
        isLogin && verified ? <MachineLayout /> : <Navigate to="/auth/login" />,
      children: [
        {
          path: "",
          element: <MachineProfile />,
        },
      ],
    },
    {
      path: "/activegame",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <ActiveGame />,
        },
      ],
    },
    {
      path: "/createscenarios",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <CreateScenarios />,
        },
        {
          path: "endingpage",
          element: <SenariosUploadEnding />,
        },
        {
          path: "activesenario",
          element: <ActiveGameScenario />,
        },
      ],
    },
    {
      path: "sceanrio_page",
      element:
        isLogin && verified ? (
          <SenarioMachineLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <ScenarioPage />,
        },
      ],
    },
    {
      path: "/uploadmachine",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <UploadMachine />,
        },
        {
          path: "/uploadmachine/uploadfile",
          element: <UploadFile />,
        },
        {
          path: "/uploadmachine/uploadending",
          element: <UploadEnding />,
        },
        {
          path: "popup",
          element: <Popup />,
        },
      ],
    },
    {
      path: "/ActiveGameSenario",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <ActiveGameSenario variant="scenario" />,
        },
        {
          path: "corporate",
          element: <ActiveGameSenario variant="corporate" />,
        },
      ],
    },
    {
      path: "/ActiveGameSenario/console_page/:scenarioId/:userId",
      element: <WhiteTeamConsole />,
    },
    { path: "*", element: <PageNotFound /> },
    {
      path: "/UnderConstructionpage",
      element:
        isLogin && verified ? (
          <UnderConstructorLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <UnderConstruction />,
        },
      ],
    },
    {
      path: "/notification",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <NotificationPage />,
        },
      ],
    },

    {
      path: "/admin",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "",
          element: <Category />,
        },
        {
          path: "ctf-categories",
          element: <CTFCategories />,
        },
        {
          path: "create-ctf-categories",
          element: <CreateCTFCategories />,
        },
        {
          path: "create-ctf-categories/:categoryId",
          element: <EditCTFCategories />,
        },
        {
          path: "scenario-categories",
          element: <ScenarioCategories />,
        },
        {
          path: "create-scenario-categories",
          element: <CreateScenarioCategories />,
        },
        {
          path: "create-scenario-categories/:categoryId",
          element: <EditScenarioCategories />,
        },
        {
          path: "scenario-requests",
          element: <ScenarioRequests />,
        },
        {
          path: "ctf-requests",
          element: <CTFRequests />,
        },
        {
          path: "ctf-requests/:categoryId",
          element: <CreateCTFRequest />,
        },
        {
          path: "mapping",
          element: <Map />,
        },
        {
          path: "create_scenario_category",
          element: <CategoryScenario />,
        },
        {
          path: "scenario_approval",
          element: <ScenarioApproval />,
        },
        {
          path: "scenario_un_approval",
          element: <ScenarioUnApproval />,
        },
        {
          path: "challenges",
          element: <OngoingCTFChallenge />,
        },
        {
          path: "all-scenarios",
          element: <ScenarioCategory variant="scenarios" />,
        },
        {
          path: "user-scenarios",
          element: <ScenarioCategory variant="user-scenarios" />,
        },
        {
          path: "user-add-scenarios/:userId",
          element: <ScenarioCategory variant="user-add-scenarios" />,
        },
        {
          path: "all-ctf",
          element: <CtfCategory variant="ctf" />,
        },
        {
          path: "user-add-ctf/:userId",
          element: <CtfCategory variant="user-add-ctf" />,
        },

        {
          path: "user-add-corporate/:userId",
          element: <ScenarioCategory variant="user-add-corporate" />,
        },

        ,
        {
          path: "make-challenge-CTF/:gameid",
          element: <MakeChallengeCTF />,
        },
        {
          path: "make-challenge-scenario/:gameid",
          element: <MakeChallengeScenario />,
        },

        {
          path: "users",
          element: <Users />,
        },
        {
          path: "add-user",
          element: <AddUser />,
        },
        {
          path: "update-user/:userId",
          element: <UpdateUser />,
        },
        {
          path: "userlist",
          element: <UserList />,
        },
        {
          path: "userupdate",
          element: <UserUpdate />,
        },
        {
          path: "ctf_update",
          element: <CtfUpdate />,
        },
        {
          path: "ctf_update_page/:userId",
          element: <UpadateCtf />,
        },
        {
          path: "UpdateScneario/:userId",
          element: <UpdateScneario />,
        },
        {
          path: "scenario_category_update",
          element: <ScenarioCategory variant="edit" />,
        },
        {
          path: "ctf_category_edit",
          element: <CtfCategory variant="edit" />,
        },
      ],
    },
    {
      path: "/error",
      element: <ErrorPageLayout />,
      children: [
        { path: "under_construction", element: <UnderConstruction /> },
        { path: "page_not_found", element: <PageNotFound /> },
        { path: "server_error", element: <ServerError /> },
      ],
    },
    // created the scenario part in the new version
    {
      path: "/Corporate",
      element:
        isLogin && verified ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/auth/login" />
        ),
      children: [
        {
          path: "details/:userId",
          element: (
            <React.Suspense fallback={<LoaderImg />}>
              <ScenarioDetailsVersion2 />
            </React.Suspense>
          ),
        },
        {
          path: "category",
          element: <ScenarioCategoryVersion2 />,
        },
      ],
    },
  ]);
};

export default Router;
