import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import { toast } from "react-toastify";
import uploadImg from "../../../../components/assests/uploadIcon.png";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createSenarios,
  getCategorList,
  scenarioDelete,
  scenarioSingleGet,
  senariosUpdate,
} from "../../../../APIConfig/scenarioConfig";
import TextEditor from "../../../../components/TextEditor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CheckMark } from "../../user/UserUpdate";

const UpdateScneario = () => {
  const { userId } = useParams();
  const [text, setText] = useState("");
  const [textObj, setTextObj] = useState("");
  const [textPre, setTextPre] = useState("");
  const [category, setCategory] = useState([]);
  const [multipleFile, setMutipleFile] = React.useState([]);
  const [multipleFile1, setMutipleFile1] = React.useState([]);
  const [isActive, setIsActive] = useState(false);
  const [reload, setReload] = useState(false);
  let arr = [];
  const [inputs, setInputs] = useState({
    scenario_name: "",
    scenario_category_id: "",
    scenario_assigned_severity: "",
    scenario_score: "",
    scenario_time: "",
    scenario_description: "",
    scenario_members_requirement: "",
    scenario_objectives: "",
    scenario_prerequisites: "",
  });

  useEffect(() => {
    const getValue = async () => {
      setMutipleFile([]);
      setIsActive(true);
      const value = await scenarioSingleGet(userId);
      const data = value?.data;
      //console.log('value is here', data)
      setInputs({
        scenario_name: data?.scenario_name,
        scenario_category_id: data?.scenario_category_id,
        scenario_assigned_severity: data?.scenario_assigned_severity,
        scenario_score: data?.scenario_score,
        scenario_time: data?.scenario_time,
        scenario_description: data?.scenario_description,
        scenario_for_premium_user: data?.scenario_for_premium_user,
      });
      setTextObj(data?.scenario_tools_technologies);
      setTextPre(data?.scenario_prerequisites);
      setText(data?.scenario_description);
      setFile({ jpeg: data?.scenario_thumbnail });
      setMutipleFile1(data?.scenario_documents);
    };
    getValue();
    setIsActive(false);
  }, [reload]);
  useEffect(() => {
    //console.log('multiple files', multipleFile)
  }, [multipleFile, category]);

  useEffect(() => {
    const apiCall = async () => {
      const data = await getCategorList();
      //console.log("data is here", data.data);
      setCategory(data?.data);
      //console.log('category', category)
      data?.data?.map((item) => {
        //console.log('options')
        let val = {
          value: item.scenario_category_id,
          label: item.scenario_category_name,
        };
        arr.push(val);
      });
    };
    //console.log("options", arr)
    apiCall();
  }, []);

  const changeHandler = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    //console.log(inputs);
  };

  const handleSignInChange = (e) => {
    const { name, checked } = e.target;
    //console.log('checked is here', checked)
    setInputs({
      ...inputs,
      [name]: checked,
    });
    //console.log(inputs)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });

    //console.log("inupts", inputs);
  };
  const [file, setFile] = useState({ pdf: "", jpeg: "" });

  const handlechangePdf = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      if (image.type.split("/").pop() == "pdf") {
        setFile((state) => ({
          ...state,
          [image.type.split("/").pop()]: e.target.files[0],
        }));
        setMutipleFile([...multipleFile, e.target.files[0]]);

        return;
      } else {
        toast.error("Please upload a pdf");
      }
    }
  };

  const handlechangeJpeg = (e) => {
    if (e.target.files) {
      const image = e.target.files[0];
      //console.log(image);
      if (
        image.type.split("/").pop() == "jpeg" ||
        image.type.split("/").pop() == "png"
      ) {
        setFile((state) => ({ ...state, ["jpeg"]: e.target.files[0] }));
        //console.log(file);
        setInputs((state) => ({
          ...state,
          [e.target.name]: e.target.files[0],
        }));
        return;
      } else {
        toast.error("Please upload a jpeg");
      }
    }
  };

  const handleRemoveItem = (name) => {
    //console.log('name', name)
    setMutipleFile(multipleFile.filter((item) => item.name !== name));
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="#ACACAC" href="/" variant="h3">
      Dashboard
    </Link>,

    <Link
      underline="hover"
      key="1"
      variant="h3"
      color="#ACACAC"
      href="/UpdateScneario"
    >
      Create Scenario
    </Link>,
  ];

  const handleRemove = async (item, index) => {
    setIsActive(true);
    //console.log('item', item)
    const value = await scenarioDelete({
      scenario_id: userId,
      document_url: item,
    });
    //console.log('value is here', value)

    setMutipleFile1(multipleFile1.filter((item) => item !== item));
    setReload(!reload);
    setIsActive(false);
  };

  const handleUpdate = async () => {
    try {
      setIsActive(true);
      const data = await senariosUpdate(
        userId,
        inputs,
        multipleFile,
        text,
        textObj,
        textPre
      );
      setReload(!reload);
      //console.log('data is here', data)
      setIsActive(false);
      toast.success("sceanrio has been updated!");
    } catch (error) {
      setIsActive(false);
      //console.log('error', error)
      const obj = error.response.data.errors;

      for (let i in obj) {
        toast.error(
          i.charAt(0).toUpperCase() +
            i.slice(1).replace(/_/g, " ") +
            " - " +
            obj[i]
        );
      }
    }
  };
  return (
    <Stack mt={6} gap={4} width="100%">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isActive}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack ml={5}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          color="#acacac"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Typography variant="h1" ml={5}>
        Create Scenarios{" "}
      </Typography>
      {/* top input */}
      <Stack direction="row" justifyContent="space-evenly" gap={2} width="100%">
        <Stack gap={3} width="60%" p={2}>
          <Stack gap={2} width="100%" direction="row">
            <Stack width="100%">
              <TextField
                name="scenario_name"
                id="outlined-basic"
                label="Scenario Name"
                variant="outlined"
                value={inputs.scenario_name}
                onChange={changeHandler}
              />
            </Stack>
            <Stack
              width="100%"
              sx={{
                borderRadius: "8px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.scenario_category_id}
                  label="Select Category"
                  name="scenario_category_id"
                  onChange={handleInputChange}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#acacac",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#acacac",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#acacac",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#acacac !important",
                    },
                  }}
                  style={{ backgroundColor: "#161616 !important" }}
                >
                  {category?.map((item) => {
                    return (
                      <MenuItem
                        value={item.scenario_category_id}
                        key={item.scenario_category_id}
                      >
                        {item.scenario_category_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Stack gap={2} width="100%" direction="row">
            <Stack
              width="100%"
              sx={{
                borderRadius: "8px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Severity</InputLabel>
                <Select
                  MenuProps={{
                    sx: {
                      "&& .Mui-selected": {
                        backgroundColor: "#686868",
                      },
                    },
                  }}
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#acacac",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#acacac",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#acacac",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#acacac !important",
                    },
                  }}
                  value={inputs.scenario_assigned_severity}
                  name="scenario_assigned_severity"
                  label="Severity"
                  onChange={handleInputChange}
                >
                  <MenuItem value="Very Easy">Very Easy</MenuItem>
                  <MenuItem value="Easy">Easy</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Hard">Hard</MenuItem>
                  <MenuItem value="Very Hard">Very Hard</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack width="100%">
              <TextField
                id="outlined-basic"
                label="Points"
                variant="outlined"
                value={inputs.scenario_score}
                onChange={handleInputChange}
                name="scenario_score"
              />
            </Stack>
          </Stack>
          <Stack
            gap={2}
            width="100%"
            direction="row"
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Stack width="50%">
              <TextField
                id="outlined-basic"
                label="Time Duration"
                variant="outlined"
                name="scenario_time"
                value={inputs.scenario_time}
                onChange={handleInputChange}
              />
            </Stack>
            <Stack width="50%">
              <CheckMark
                label="Premium"
                name="scenario_for_premium_user"
                onChange={handleSignInChange}
                value={inputs.scenario_for_premium_user}
              />
            </Stack>
          </Stack>
          <Stack width="100%" sx={{ mb: 3 }}>
            <Typography variant="h3">Description</Typography>
            <TextEditor setText={setText} text={text} />
          </Stack>
          <Stack width="100%" sx={{ mb: 3 }}>
            <Typography variant="h3">Scenario tools&technologies</Typography>
            <TextEditor setText={setTextObj} text={textObj} />
          </Stack>
          <Stack width="100%" sx={{ mb: 3 }}>
            <Typography variant="h3">Prerequisites</Typography>
            <TextEditor setText={setTextPre} text={textPre} />
          </Stack>
          <Stack sx={{ my: 3 }}>
            <Button
              sx={{ width: "100px" }}
              variant="contained"
              color="secondary"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Stack>
        </Stack>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          color="#acacac"
        />
        <Stack gap={2} width="30%" p={2} alignItems="center">
          <Typography variant="h2">Upload Thumbnail</Typography>
          {file?.jpeg ? (
            <Stack direction="row" gap={1}>
              <Typography sx={{ color: "#fff" }}>thumbnail</Typography>
              <DeleteIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFile((state) => ({ ...state, ["jpeg"]: "" }));
                }}
              />
            </Stack>
          ) : (
            <Button
              component="label"
              variant="text"
              sx={{
                color: "#fff",
                backgroundColor: "custom.main",
                width: "300px",
              }}
            >
              <Stack
                style={{
                  border: "1px dashed #12464C",
                  borderRadius: "8px",
                  height: "100px",
                  width: "300px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 4,
                  padding: 4,
                }}
              >
                <img src={uploadImg} width="20px" height="20px" />
                <Typography
                  variant="body"
                  style={{ textAlign: "center", fontSize: "12px" }}
                >
                  Drop your thumbnail here, or Browse Upload A. jpeg
                </Typography>

                <input
                  type="file"
                  hidden
                  onChange={handlechangeJpeg}
                  name="scenario_thumbnail"
                />
              </Stack>
            </Button>
          )}

          <div style={{ width: "100%", border: "2px dashed #B46228" }}></div>

          <Typography variant="h2" sx={{ my: 2 }}>
            Upload Walkthrough
          </Typography>

          <Button
            component="label"
            variant="text"
            sx={{
              color: "#fff",
              backgroundColor: "custom.main",
              width: "300px",
            }}
          >
            <Stack
              style={{
                border: "1px dashed #12464C",
                borderRadius: "8px",
                height: "100px",
                width: "300px",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                padding: 4,
              }}
            >
              <img src={uploadImg} width="20px" height="20px" />
              <Typography
                variant="body"
                style={{ textAlign: "center", fontSize: "12px" }}
              >
                Drop your files here, or Browse Upload A.pdf
              </Typography>
              <input
                type="file"
                hidden
                onChange={handlechangePdf}
                name="scenario_documents"
              />
            </Stack>
          </Button>
          {/* document which are already submitted */}
          <>
            {multipleFile1.map((item, index) => {
              //console.log('item is ', item.name)
              return (
                <Stack direction="row" gap={1} key={index} sx={{ my: 2 }}>
                  <Typography sx={{ color: "#fff" }}>
                    Walkthrough-{index + 1}
                  </Typography>
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemove(item, index)}
                  />
                  <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(item, "_blank")}
                  />
                </Stack>
              );
            })}
            {/* document which are selected by the user */}

            {multipleFile.map((item, index) => {
              //console.log('item is ', item.name)
              return (
                <Stack
                  direction="row"
                  gap={1}
                  key={index}
                  sx={{ marginTop: 2 }}
                >
                  <Typography sx={{ color: "#fff" }}>{item.name}</Typography>
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveItem(item.name)}
                  />
                </Stack>
              );
            })}
          </>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UpdateScneario;
