import { Divider, Pagination, Stack, Typography } from "@mui/material"
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ActionType from "./notification/actionType";
import RedirectionType from "./notification/redirectionType";
import { NotificationList } from '../../APIConfig/adminConfig';

const NotificationPage = () => {
  const location = useLocation()
  const [data, setData] = useState([])
  useEffect(() => {
    getApi()
  }, [])
  const getApi = async () => {
    const value = await NotificationList()
    setData(value?.data)
   
  };


  //console.log('data is here',data)
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = (data.length > 10) ? data.slice(startIndex, endIndex) : data


  return (
    <Stack sx={{ padding: 5 }}>
      <Typography variant="h1">Notification</Typography>
      {
        currentData?.map((item, index) => {

          let timeAgo = null;
          const timeToCheck = new Date(item?.date);
          const currentTime = new Date();
          const timeDifference = currentTime - timeToCheck;

          const secondsAgo = Math.floor(timeDifference / 1000);
          const minutesAgo = Math.floor(secondsAgo / 60);
          const hoursAgo = Math.floor(minutesAgo / 60);
          const daysAgo = Math.floor(hoursAgo / 24);

          if (secondsAgo < 60) {
            timeAgo = (`${secondsAgo} second${secondsAgo === 1 ? '' : 's'} ago`);
          } else if (minutesAgo < 60) {
            timeAgo = (`${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`);
          } else if (hoursAgo < 24) {
            timeAgo = (`${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`);
          } else {
            timeAgo = (`${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`);
          }


          // if (item.type == "action") 
          return <ActionType item={item} key={index}/>
          // if (item.type == "redirect") return <RedirectionType item={item} />
        })
      }
      <Stack sx={{ alignItems: 'center' }}>
        <Pagination
          count={Math.ceil(data?.length / itemsPerPage)}
          page={page}
          onChange={handleChange}
        />
      </Stack>
    </Stack>
  )
}

export default NotificationPage;