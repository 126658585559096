import { Button } from "@mui/material";
import React from "react";

export default function ListItem(props) {
    const { name } = props.flag;
    return (
        <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', listStyle: 'list', color: '#fff',padding:16 }}>
            {name}
            <Button color="error" variant="contained" sx={{ marginLeft: '8px' }} onClick={props.remove}>Remove</Button>
        </li>
    );
}