const ApiVersion2 = {
  scenario: {
    detail: `/corporate/scenario/create/`,
    submitInfra: "/corporate/scenario/add-infra/",
    scenarioList: "/corporate/scenario/list/",
    scenarioDetials: "/corporate/scenario/detail/",
    scenarioStart: "/corporate/scenario/start/",
    getConsole: "scenario/console",
    activegame: "/corporate/scenario/active/",
    getconsole: "/corporate/scenario/console/",
    submitmilestone: "/corporate/scenario/achieve-milestone/",
    whiteTeam: "/corporate/scenario/moderator/",
    console_team: "/corporate/scenario/moderator-console/",
    approved: "/corporate/scenario/approve-milestone/",
    unApproved: "/corporate/scenario/reject-milestone/",
    flag: "/corporate/scenario/submit-flag/",
    hint: "/corporate/scenario/show-hint/",
    ImageAndFal: "v2/scenario/instance/essentials/list/",
    endGame: "/corporate/scenario/delete/",
    white_team_player_other: "/corporate/scenario/moderator-console/detail/",
    getReportList: "corporate/scenario/show-reports/",
    downloadReport: "corporate/scenario/generate-report/",
    newDownloadReport: "corporate/scenario/generate-report/",
    getCategoryCorporate: "corporate/game/based-on-category/",
    liveScore: "/corporate/scenario/active_participants/",
    scenarioWinningWall: `/user/common/winning-wall/corporate`,
    achievers: `/corporate/scenario/achivers/`,
    report_api:'/corporate/scenario/report/'
  },
};

export default ApiVersion2;
