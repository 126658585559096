//first----import createSlice and createAsyncThunk
import { createSlice } from "@reduxjs/toolkit"
import { createAsyncThunk } from '@reduxjs/toolkit'

import Apiconfig from "../../../APIConfig/ApiConfig"
import jwtDecode from "jwt-decode"
import API from "../../../Axios/axios"

//second---initializing the state 
const initialState ={
    loading:false,
    userss:[],
    error:''
}

// third -----generates pending,fulfilled and rejected action types
export const fetchUsers = createAsyncThunk('user/fetchUsers',()=>{
    const token = localStorage.getItem("access_token");
    const user = jwtDecode(token)
    const Id = user?.user_id
    //userProfile
    const response =  API.get(`${Apiconfig.user.userProfile}${Id}/`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response;
})

//fouth---create the userSlice action and reducer
const userSlice = createSlice({
    //action type name
    name:'user',
    //initializing
    initialState,
    //reducer created for the setting up loading state
    extraReducers:builder =>{
        builder.addCase(fetchUsers.pending,state =>{
            state.loading = true
        })

        //for setting for the data
        builder.addCase(fetchUsers.fulfilled,(state,action)=>{
            state.loading =false
            state.userss = action.payload.data
            state.error =''
        })

        //for the error
        builder.addCase(fetchUsers.rejected,(state,action)=>{
            state.loading = false
            state.userss =[]
            state.error = action.error.message
        })
    }
})


export default userSlice.reducer
