import React, {useEffect, useState} from 'react';
import BreadCrumbs from '../../../components/navbar/BreadCrumb';
import {useNavigate, useParams} from 'react-router-dom';
import {getCTFCategory} from "../../../APIConfig/adminConfig";
import CreateCategories from "../common/createCategories";
import ErrorNavigator from '../../../ErrorHandler/errorNavigator';

const EditCTFCategories = (props) => {

  const [CTFCategoryData, setCTFCategoryData] = useState(null)
  const {categoryId} = useParams();
  const navigate = useNavigate()
  const breadcrumbs = [
    {
      name: 'Dashboard',
      link: '/'
    },
    {
      name: 'Categories',
      link: '/admin/ctf-categories'
    }, {
      name: 'Edit Category',
      link: `/admin/create-ctf-categories/${categoryId}`
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await getCTFCategory(categoryId);
        setCTFCategoryData(response.data);
      } catch (error) {
        console.log('error',error)
        ErrorNavigator(error,navigate)
      }
    })();
  }, [categoryId]);


  return (
    <>
      <BreadCrumbs breadcrumbs={breadcrumbs}/>
      {CTFCategoryData && <CreateCategories template='Edit CTF' data={CTFCategoryData} categoryId={categoryId}/>}
    </>
  );
};

export default EditCTFCategories;